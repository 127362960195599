import { Box, Grid, Skeleton } from '@mui/material'
import React, { Fragment } from 'react'

export const AvailableServicesSkeleton = () => {
  const renderSkeletons = () => {
    return [...Array(3)].map((_, index) => {
      return (
        <Box display="flex" alignItems="center" width="100%" my={5}>
          <Skeleton variant="circular" width={60} height={52} />
          <Box ml={2} width="100%">
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
            <Skeleton variant="text" sx={{ fontSize: '10px', width: '50%' }} />
          </Box>
        </Box>
      )
    })
  }

  return <Grid padding={10}>{renderSkeletons()}</Grid>
}

export const CompanySkeleton = () => {
  const renderSkeletons = () => {
    return (
      <Box display="flex" alignItems="center" width="100%" my={5}>
        <Box width="100%">
          {[...Array(4)].map((_, index) => {
            return (
              <>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '1rem', my: '10px', width: '100%' }}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '10px', my: '10px', width: '50%' }}
                />
              </>
            )
          })}
        </Box>
        <Box ml={2} width="100%">
          <AvailableServicesSkeleton />
        </Box>
      </Box>
    )
  }

  return <Grid padding={10}>{renderSkeletons()}</Grid>
}

export const ContactSkeleton = () => {
  const renderSkeletons = () => {
    return (
      <Box display="flex" alignItems="center" width="100%" my={5}>
        <Box width="100%">
          {[...Array(4)].map((_, index) => {
            return (
              <Fragment key={`${_}${index}`}>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '1rem', my: '10px', width: '100%' }}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '10px', my: '10px', width: '50%' }}
                />
              </Fragment>
            )
          })}
        </Box>
        <Box ml={2} width="100%">
          {[...Array(4)].map((_, index) => {
            return (
              <>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '1rem', my: '10px', width: '100%' }}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '10px', my: '10px', width: '50%' }}
                />
              </>
            )
          })}
        </Box>
      </Box>
    )
  }

  return <Grid padding={10}>{renderSkeletons()}</Grid>
}

export const ProfileSkeleton = ({ noOfLines = 3, ...props }) => {
  const renderSkeletons = () => {
    return [...Array(noOfLines)].map((_, index) => {
      return (
        <Box display="flex" alignItems="center" width={props?.width}>
          <Box ml={2} my={1} width="100%">
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
            <Skeleton variant="text" sx={{ fontSize: '10px', width: '100%' }} />
          </Box>
        </Box>
      )
    })
  }

  return <Grid padding={1}>{renderSkeletons()}</Grid>
}
