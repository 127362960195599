import { createApiInstance } from './apiClient'
import { ENV_URLS } from '../../helpers/Constants'

export const httpBase = (patch, download) =>
  createApiInstance({
    baseURL: `${ENV_URLS.URL}/${ENV_URLS.API}/`,
    headers: {},
    patch,
    download,
  })

export const externalHttpCIBase = (customerId) =>
  createApiInstance({
    baseURL: `${ENV_URLS.SERVICE_CI_URL}/${ENV_URLS.API}/`,
    headers: { CustomerId: customerId },
  })

export const externalHttpCACBase = (customerId) =>
  createApiInstance({
    baseURL: `${ENV_URLS.SERVICE_CAC_URL}/${ENV_URLS.API}/`,
    headers: { CustomerId: customerId },
  })

export const externalHttpCoreViewBase = (customerId, patch) =>
  createApiInstance({
    baseURL: `${ENV_URLS.SERVICE_COREVIEW_URL}/${ENV_URLS.API}/`,
    headers: { CustomerId: customerId },
    patch,
  })

export const externalHttpMAXCSBase = (customerId, patch) =>
  createApiInstance({
    baseURL: `${ENV_URLS.SERVICE_MAXCS_URL}/${ENV_URLS.API}/`,
    headers: { CustomerId: customerId },
    patch,
  })

export const externalHttpDIRECTROUTINGBase = (customerId, patch) =>
  createApiInstance({
    baseURL: `${ENV_URLS.SERVICE_DIRECTROUTING_URL}/${ENV_URLS.API}/`,
    headers: { CustomerId: customerId },
    patch,
  })

export const externalHttpSIPAnywhereBase = (customerId, patch) =>
  createApiInstance({
    baseURL: `${ENV_URLS.SERVICE_SIP_TRUNKING_URL}/${ENV_URLS.API}/`,
    headers: { CustomerId: customerId },
    patch,
  })
