import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  Typography,
} from '@mui/material'
import * as React from 'react'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import SkeletonTableLoader from '../../../../common/components/loaders/skeletonLoader'
import IconButton from '@mui/material/IconButton'
import { useFormik } from 'formik'
import LoadingSection from '../../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  api_getOrderAddress,
  api_getSipExistAddress,
  api_postSipMaxLocation,
} from '../../../../api/services/SIPTrunkingAPI'
import { useSelector } from 'react-redux'
import {
  serviceSliceState,
  setExistingAddress,
  setOrderService,
} from '../../../../store/slice/serviceSlice'
import {
  setCurrentCompanyDetails,
  setSelectedProducts,
} from '../../../../store/slice/commonSlice'
import TrunkConfigCard from '../../../home/services/sip-trunking/components/TrunkConfigCard'
import { orderGetAllById } from '../../../../api/orders/ordersAPI'
import {
  settingsSliceState,
  setMaxSipLocation,
  setOrderAddress,
} from '../../../../store/slice/settingsSlice'
import {
  checkPermission,
  executeWithTryCatch,
  getCompanyLevel,
  normalizeApiResponse,
} from '../../../../helpers/HelperFunctions';
import { PERMISSIONS } from '../../../../helpers/Constants'
import { useToastContext } from '../../../../App'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'

export function SIPTrunkConfig(props) {
  const { dispatch, state: commonState } = useCommonRedux()
  const { toast } = useToastContext()
  const [loader, setLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [touched, setTouched] = React.useState(true)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const state = useSelector(settingsSliceState)
  const _state = useSelector(serviceSliceState)

  const formik = useFormik({
    initialValues: {
      companyId: '',
      maxLocationAllowed: '',
    },
    initialTouched: false,
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      const params = {
        companyId: companyId,
        productId: commonState.selectedProducts?.find((o) =>
          o.name.includes('SIP')
        )?.id,
        maxLocation: formik.values.maxLocationAllowed,
      }
      api_postSipMaxLocation(params)
        .then((response) => {
          setSubmitLoader(false)
          setShowEdit(false)
          dispatch(setMaxSipLocation(formik.values.maxLocationAllowed))
          const _products = commonState.currentCompanyDetails?.products
          const index = _products?.findIndex((o) =>
            o.productCode.includes('SIPT')
          )
          if (index != -1) {
            const products = [..._products]
            products[index] = {
              ..._products[index],
              maxLocationAllowed: formik.values.maxLocationAllowed,
            }
            dispatch(setSelectedProducts(products))
            dispatch(
              setCurrentCompanyDetails({
                ...commonState.currentCompanyDetails,
                products: products,
              })
            )
          }
          props?.setHideEdit(false)
          props.refreshData(response)
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast( normalizeApiResponse(error)?.message, 'error')
        })
    },
  })

  useEffect(() => {
    getDecodeValue()
  }, [state.orderAddress])

  const getOrderAddress = async (companyId) => {
    setSubmitLoader(true)
    if (!state.orderAddress) {
      const {
        data: { Result },
      } = await api_getOrderAddress(companyId)
      dispatch(setOrderAddress(Result.Result))
      if (!_state.orderService) {
        orderGetAllById(
          { companyId: companyId, orderTypeId: 1 },
          getCompanyLevel(
            searchParams.get('company_id'),
            location.pathname.slice(1)
          )
        ).then((response) => {
          dispatch(setOrderService(response.data.result))
        })
      }
      if (!_state?.existingAddress) {
        const resp = await api_getSipExistAddress(companyId)
        dispatch(setExistingAddress(resp.data.Result))
      }
    }

    const sipOrder = commonState.selectedProducts?.find((o) =>
      o.productCode.includes('SIPT')
    )
    dispatch(setMaxSipLocation(sipOrder ? sipOrder.maxLocationAllowed : 0))
    formik.setFieldValue('maxLocationAllowed', sipOrder?.maxLocationAllowed)
    setSubmitLoader(false)
  }

  const handleError = (error) => {
    toast.showToast( normalizeApiResponse(error)?.message, 'error')
    setSubmitLoader(false)
  }

  const getDecodeValue = async () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    let companyId = ''
    if (decodeToken) {
      const decoded = jwt_decode(decodeToken)
      if (decoded) {
        companyId = searchParams.get('company_id')
        if (!companyId) {
          companyId = decoded?.company_id
        }
      }
      executeWithTryCatch(getOrderAddress, handleError, companyId)
    }
  }

  const showEditButton = (e) => {
    if (!e) {
      if (!formik.values.maxLocationAllowed) {
        formik.resetForm()
      }
    }
    setShowEdit(e)
  }
  useEffect(() => {
    formik.errors = {}
  }, [])
  useEffect(() => {
    formik.setTouched({}, touched)
  }, [touched, formik?.errors])

  // const minLocation = _state.orderService?.filter(
  //     (o) => o.productCode === 'SIPT'
  // ).concat(_state?.existingAddress)?.length || 1

  const renderOrderAddress = (address) => {
    return (
      <Grid container>
        <Typography>
          {address.Line1}
          {', '}
        </Typography>
        <Typography>{address.Line2}</Typography>
        <Grid
          item
          xs={12}
          sm={12}
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Typography>City: {address.City}</Typography>
          <Typography>Zipcode: {address.ZipCode}</Typography>
          <Typography>State: {address.State}</Typography>
          <Typography>Country: {address.Country}</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Container>
        {loader ? (
          <div
            style={{
              padding: '5px 10px 0 20px',
            }}
          >
            <SkeletonTableLoader></SkeletonTableLoader>
          </div>
        ) : (
          <>
            <Grid container>
              <Grid item md={10} sm={10} xs={10}>
                <div style={{ fontSize: '16px', fontWeight: '600' }}>
                  SIP Configuration
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="stepper-input-label-margin">
                      <label className="stepper-label-style">
                        Maximum SIP Location
                      </label>
                      <TextField
                        disabled={!showEdit}
                        id="maxLocationAllowed"
                        name="maxLocationAllowed"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values?.maxLocationAllowed || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          inputProps: {
                            max: 3,
                            min: 1,
                          },
                        }}
                      />
                      <div style={{ color: 'red', position: 'absolute' }}>
                        {formik.errors?.maxLocationAllowed &&
                          formik.errors.maxLocationAllowed}
                      </div>
                    </Box>
                  </Grid>
                  {showEdit ? (
                    <Box
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        background: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      className="stepper-input-label-margin"
                    >
                      <Button
                        disabled={!(formik.isValid && formik.dirty)}
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: '0 5px' }}
                      >
                        Update
                      </Button>
                      <Button
                        onClick={() => {
                          showEditButton(false)
                          formik.setFieldValue(
                            'maxLocationAllowed',
                            commonState.selectedProducts?.find((o) =>
                              o.name.includes('SIP')
                            )?.maxLocationAllowed
                          )
                          props?.setHideEdit(false)
                        }}
                        type="button"
                        variant="contained"
                        color="secondary"
                        size="large"
                        style={{ margin: '0 5px' }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    ''
                  )}
                </form>
              </Grid>
              <Grid item md={2} sm={2} xs={2}>
                {checkPermission(
                  [
                    PERMISSIONS.masterSettingUpdate,
                    PERMISSIONS.serviceproviderSettingUpdate,
                    PERMISSIONS.resellerSettingUpdate,
                    PERMISSIONS.customerSettingUpdate,
                  ],
                  commonState.currentCompanyDetails
                )
                  ? state.maxSipLocation > 0 &&
                    state.maxSipLocation != null && (
                      <div
                        style={{
                          padding: '0 25px 0 0',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            showEditButton(true)
                            props?.setHideEdit(true)
                          }}
                          aria-label="delete"
                          style={{
                            float: 'right',
                            borderRadius: 0,
                            fontSize: '18px',
                            marginTop: '35px',
                          }}
                        >
                          <EditIcon
                            style={{
                              fontSize: '14px',
                              verticalAlign: 'middle',
                              margin: '0 0px 0 10px',
                            }}
                          />
                          Edit
                        </IconButton>
                      </div>
                    )
                  : ''}
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                sx={{ mt: '10px' }}
                style={{ fontSize: '16px', fontWeight: '600' }}
              >
                SIP Service Address
              </Grid>
              <Grid xs={10} sm={10} md={10} sx={{ paddingLeft: '10px' }}>
                {state.orderAddress?.length > 0 ? (
                  state.orderAddress?.map((address, i) => (
                    <TrunkConfigCard
                      key={`trunkConfigCard_${i}`}
                      sx={{ mt: 4 }}
                      title={`SIP Trunking_${address.State}`}
                      content={renderOrderAddress(address)}
                    />
                  ))
                ) : (
                  <Typography variant="subtitle1">
                    No SIP Address Available
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  )
}
export default SIPTrunkConfig
