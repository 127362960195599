export default function IFrame({ content }) {
    const writeHTML = (frame) => {
        if (!frame) {
            return;
        }
        const doc = frame.contentDocument;
        doc.open();
        doc.write(content);
        doc.close();
        frame.style.border = "none";
        frame.style.width = "100%";
        frame.style.height = "100%";
    };
    return <iframe src="about:blank" ref={writeHTML} />
}