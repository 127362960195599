import React, { createContext, useContext, useEffect } from 'react'
import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import SettingsCompany from './company/SettingsCompany'
import ContactPage from './contact/Contact'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  resetSettings,
  settingsSliceState,
} from '../../store/slice/settingsSlice'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { useCommonRedux } from '../../store/middlewares/CommonRedux'
import { checkPermission } from '../../helpers/HelperFunctions'
import { PERMISSIONS, COMPANY_LEVELS } from '../../helpers/Constants'
import Configuration from './configuration/Configuration'
import MSRPSettings from './MSRP/MSRPSettings'
import SettingsNotification from './notification/SettingsNotification'

const SettingsRedux = createContext(null)
export const useSettingsRedux = () => useContext(SettingsRedux)

function Settings() {
  const { dispatch, state: commonState } = useCommonRedux()
  const settingsState = useSelector(settingsSliceState)
  const theme = useTheme()

  const StyledTitle = styled('div')(() => ({
    justifyContent: 'center',
    opacity: 1,
    left: '34px',
    padding: '0 10px',
  }))

  const [value, setValue] = React.useState('1')
  const [customerLevel, setCustomerLevel] = React.useState('')
  const handleChange = (_event, newValue) => {
    setValue(newValue)
    sessionStorage.setItem('tab', newValue)
  }
  const location = useLocation()

  useEffect(() => {
    const pathName = location.pathname.slice(1)
    setCustomerLevel(pathName)
  }, [])

  // Capture the window onload
  useEffect(() => {
    window.onload = triggerOnReload()
    return () => {
      dispatch(resetSettings())
    }
  }, [])

  //trigger reload to set navigation tab
  const triggerOnReload = () => {
    const tab = sessionStorage.getItem('tab')
    setValue(tab)
  }

  return (
    <SettingsRedux.Provider value={{ dispatch, settingsState }}>
      <Box sx={{ width: '100%' }}>
        <TabContext value={value}>
          <Box
            sx={{
              opacity: 1,
              backgroundColor: 'rgba(231, 241, 253, 1)',
              top: '62px',
              zIndex: '1000',
              display: 'flex',
            }}
          >
            <TabList
              onChange={handleChange}
              sx={{
                paddingLeft: '164px',
              }}
              aria-label="Settings Tab List"
              textColor="primary"
              variant="scrollable"
              allowScrollButtonsMobile
              indicatorColor="primary"
            >
              {checkPermission(
                [
                  PERMISSIONS.masterCompanyView,
                  PERMISSIONS.serviceproviderCompanyView,
                  PERMISSIONS.resellerCompanyView,
                  PERMISSIONS.customerCompanyView,
                  PERMISSIONS.serviceproviderProductView,
                  PERMISSIONS.resellerProductView,
                  PERMISSIONS.customerProductView,
                ],
                commonState.currentCompanyDetails
              ) ? (
                <Tab
                  label={
                    <StyledTitle>
                      <div>Company</div>
                    </StyledTitle>
                  }
                  value="1"
                />
              ) : (
                ''
              )}
              {checkPermission(
                [
                  PERMISSIONS.masterCompanyView,
                  PERMISSIONS.serviceproviderCompanyView,
                  PERMISSIONS.resellerCompanyView,
                  PERMISSIONS.customerCompanyView,
                ],
                commonState.currentCompanyDetails
              ) && [COMPANY_LEVELS.customer, COMPANY_LEVELS.reseller].includes(commonState.currentCompanyDetails?.companyLevel) ? (
                <Tab
                  label={
                    <StyledTitle>
                      <div>Contact</div>
                    </StyledTitle>
                  }
                  value="2"
                />
              ) : (
                ''
              )}
              {checkPermission(
                [
                  PERMISSIONS.masterSettingView,
                  PERMISSIONS.serviceproviderSettingView,
                  PERMISSIONS.resellerSettingView,
                  PERMISSIONS.customerSettingView,
                ],
                commonState.currentCompanyDetails
              ) && customerLevel == 'customer' ? (
                <Tab
                  label={
                    <StyledTitle>
                      <div>Configuration</div>
                    </StyledTitle>
                  }
                  value="3"
                />
              ) : (
                ''
              )}
              {checkPermission(
                [
                  PERMISSIONS.masterSettingView,
                  PERMISSIONS.serviceproviderSettingView,
                  PERMISSIONS.resellerSettingView,
                  PERMISSIONS.customerSettingView,
                ],
                commonState.currentCompanyDetails
              ) &&
                (customerLevel == 'reseller' ||
                  customerLevel == 'serviceprovider') ? (
                <Tab
                  label={
                    <StyledTitle>
                      <div>MSRP Settings</div>
                    </StyledTitle>
                  }
                  value="4"
                />
              ) : (
                ''
              )}
              {checkPermission(
                [PERMISSIONS.masterNotificationView],
                commonState.currentCompanyDetails
              ) && customerLevel == 'master' ? (
                <Tab
                  label={
                    <StyledTitle>
                      <div>Notification</div>
                    </StyledTitle>
                  }
                  value="5"
                />
              ) : (
                ''
              )}
            </TabList>
          </Box>
          {checkPermission(
            [
              PERMISSIONS.masterCompanyView,
              PERMISSIONS.serviceproviderCompanyView,
              PERMISSIONS.resellerCompanyView,
              PERMISSIONS.customerCompanyView,
              PERMISSIONS.serviceproviderProductView,
              PERMISSIONS.resellerProductView,
              PERMISSIONS.customerProductView,
            ],
            commonState.currentCompanyDetails
          ) && (
              <TabPanel value="1" style={{ padding: 0 }}>
                <SettingsCompany />
              </TabPanel>
            )}
          {checkPermission(
            [
              PERMISSIONS.masterCompanyView,
              PERMISSIONS.serviceproviderCompanyView,
              PERMISSIONS.resellerCompanyView,
              PERMISSIONS.customerCompanyView,
            ],
            commonState.currentCompanyDetails
          ) && (
              <TabPanel value="2" style={{ padding: 0 }}>
                <ContactPage />
              </TabPanel>
            )}
          {checkPermission(
            [
              PERMISSIONS.masterSettingView,
              PERMISSIONS.serviceproviderSettingView,
              PERMISSIONS.resellerSettingView,
              PERMISSIONS.customerSettingView,
            ],
            commonState.currentCompanyDetails
          ) &&
            customerLevel == 'customer' && (
              <TabPanel value="3" style={{ padding: 0 }}>
                <Configuration />
              </TabPanel>
            )}
          {checkPermission(
            [
              PERMISSIONS.masterSettingView,
              PERMISSIONS.serviceproviderSettingView,
              PERMISSIONS.resellerSettingView,
              PERMISSIONS.customerSettingView,
            ],
            commonState.currentCompanyDetails
          ) &&
            (customerLevel == 'reseller' ||
              customerLevel == 'serviceprovider') && (
              <TabPanel value="4" style={{ padding: 0 }}>
                <MSRPSettings />
              </TabPanel>
            )}
          {checkPermission(
            [PERMISSIONS.masterNotificationView],
            commonState.currentCompanyDetails
          ) &&
            customerLevel == 'master' && (
              <TabPanel value="5" style={{ padding: 0 }}>
                <SettingsNotification />
              </TabPanel>
            )}
        </TabContext>
      </Box>
    </SettingsRedux.Provider>
  )
}
export default Settings
