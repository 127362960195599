import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import jwt_decode from 'jwt-decode'
import { PERMISSIONS } from "../../helpers/Constants";
import { useCommonRedux } from "../../store/middlewares/CommonRedux";
import { checkPermission } from "../../helpers/HelperFunctions";
import { setCurrentCompanyDetails } from "../../store/slice/commonSlice";
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import ServiceCancellationApprovals from "./components/ServiceCancellationApprovals";

const menuList = [{
    label: "Service Cancellation Approvals",
    value: "1",
    permission: [PERMISSIONS.salesRepServiceCancelApproval]
}];

function SalesRep() {
    const { state: commonState } = useCommonRedux()
    const [tabValue] = useState("1");
    const theme = useTheme()

    const StyledTitle = styled('div')(() => ({
        fontSize: '18px',
        color: theme.typography.Para.color,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'center',
        letterSpacing: '0px',
        lineHeight: '26px',
        opacity: 1,
        left: '34px',
        padding: '21px',
    }));

    return <Box sx={{ width: '100%' }}>
        <TabContext value={tabValue}>
            <Box sx={{
                opacity: 1,
                backgroundColor: 'rgba(231, 241, 253, 1)',
                top: '62px',
                zIndex: '1000',
                display: 'flex',
                height: '64px',
            }}>
                <TabList
                    sx={{ paddingLeft: '164px' }}
                    aria-label="User Roles Tabs List"
                    textColor="primary"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    indicatorColor="primary"
                    TabIndicatorProps={{
                        title: 'indicator',
                        sx: {
                            backgroundColor: (theme) => theme.palette.primary.main,
                            height: '5px',
                        },
                    }}>
                    {menuList
                        .filter((menu) => checkPermission(menu.permission, commonState.currentCompanyDetails))
                        .map((menu, index) => <Tab
                            key={`${menu}${index}`}
                            label={
                                <StyledTitle>
                                    <div>{menu.label}</div>
                                </StyledTitle>
                            }
                            value={menu.value}
                        />)}
                </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: '0px' }}>
                <ServiceCancellationApprovals />
            </TabPanel>
        </TabContext>
    </Box>
}

export default SalesRep;