import { useState } from 'react'

import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Payments from './payments/Payments'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { checkPermission } from '../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../helpers/Constants'
import InvoiceList from './invoice/InvoiceList'
import { useCommonRedux } from '../../store/middlewares/CommonRedux'

function Billing() {
  const { dispatch, state: commonState } = useCommonRedux()
  const theme = useTheme()

  const StyledTitle = styled('div')(() => ({
    justifyContent: 'center',
    opacity: 1,
    left: '34px',
    padding: '0 21px',
  }))

  const [value, setValue] = useState('1')
  const [customerLevel, setCustomerLevel] = useState('')
  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <TabContext value={value}>
          <Box
            sx={{
              opacity: 1,
              backgroundColor: 'rgba(231, 241, 253, 1)',
              display: 'flex',
            }}
          >
            <TabList
              onChange={handleChange}
              sx={{
                paddingLeft: '164px',
              }}
              aria-label="Bill Tabs List"
              textColor="primary"
              variant="scrollable"
              allowScrollButtonsMobile
              indicatorColor="primary"
            >
              <Tab
                label={
                  <StyledTitle>
                    <div>Billing</div>
                  </StyledTitle>
                }
                value="1"
              />

              {checkPermission(
                [
                  PERMISSIONS.masterBillingView,
                  PERMISSIONS.serviceproviderBillingView,
                  PERMISSIONS.resellerBillingView,
                  PERMISSIONS.customerBillingView,
                ],
                commonState.currentCompanyDetails
              ) &&
              commonState.currentCompanyDetails?.isRevParent &&
              commonState.currentCompanyDetails?.isAltigenBilling ? (
                <Tab
                  label={
                    <StyledTitle>
                      <div>Payments</div>
                    </StyledTitle>
                  }
                  value="2"
                />
              ) : (
                ''
              )}
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: 0 }}>
            <InvoiceList />
          </TabPanel>
          {commonState.currentCompanyDetails?.isAltigenBilling ? (
            <TabPanel value="2" style={{ padding: 0 }}>
              <Payments />
            </TabPanel>
          ) : (
            ''
          )}
        </TabContext>
      </Box>
    </div>
  )
}

export default Billing
