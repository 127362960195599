import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from '@mui/material'
import noDatafound from '../../../assets/nodata.png'

export default function NoDataFoundCard(props) {
  return (
    <>
      <Container maxWidth="xl">
        <Card
          style={{ mt: '20px', padding: '10px', textAlign: 'center' }}
          className={props?.borderNone == true ? 'borderless-card' : null}
        >
          <CardMedia>
            <img
              src={noDatafound}
              style={{
                marginTop: '30px',
                maxWidth: '120px',
                maxHeight: '120px',
              }}
            />
          </CardMedia>
          <CardContent style={{ marginLeft: '45px' }}>
            <Typography variant="subtitle1" gutterBottom component="div">
              {props?.messages}
            </Typography>
            <Typography variant="subtitle2" gutterBottom component="div">
              {props?.subMessages}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}
