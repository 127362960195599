import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button,
  DialogActions,
  Box,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import React from 'react'
import { styled } from '@mui/system'
import DrawerHeader from '../../drawer/DrawerHeader'

const NewPosOfDialog = styled(Dialog)(() => ({
  position: 'absolute !important',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minHeight: '80vh',
  maxHeight: '80vh',
}))

const DetailsModal = ({
  title,
  content,
  maxWidth,
  isOpen,
  handleClose,
  fullScreen,
}) => {
  return (
    <Dialog
      open={isOpen}
      fullScreen={fullScreen}
      maxWidth={maxWidth || 'sm'}
      classes={{
        paper: !fullScreen ? <NewPosOfDialog /> : '',
      }}
      fullWidth
    >
      <DialogTitle>
        {/* <DrawerHeader header={title} onClick={handleClose} /> */}
        {title}
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <Tooltip title="Close">
          <IconButton onClick={handleClose}>
            <CancelIcon color="error" size="large"></CancelIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

export default DetailsModal
