import Box from '@mui/material/Box'
import { Button, Checkbox, FormControlLabel, List } from '@mui/material'
import * as React from 'react'
import { useFormik } from 'formik'
import {
  masterUserPutApi,
  masterUserRoleByIdApi,
} from '../../../api/master/masterAPI'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import LoadingSection from '../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getCompanyLevel, normalizeApiResponse } from '../../../helpers/HelperFunctions'
import { useToastContext } from '../../../App'

export function EditUserRoleDetails(props) {
  const { toast } = useToastContext();
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const formik = useFormik({
    initialValues: {
      id: props?.userId?.id,
      firstName: props?.userId?.firstName,
      lastName: props?.userId?.lastName,
      email: props?.userId?.email,
      phone: props?.userId?.phone || '',
      roleIds: [],
      companyId: '',
      aadUserId: '',
      userStatus: props?.userId?.userStatus || 1,
    },
    onSubmit: (data) => {
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      let mapRole = rolesList?.roles?.map((rol) => {
        if (rol.isAssigned == true) return rol.roleId
      })
      let filtMap = mapRole.filter((filt) => filt)
      data.roleIds = filtMap
      if (filtMap.length > 0) {
        setSubmitLoader(true)
        masterUserPutApi(
          data,
          getCompanyLevel(
            searchParams.get('company_id'),
            location.pathname.slice(1)
          )
        )
          .then((response) => {
            props.gotoDrawer('view')
            props.toggleDrawer('right', true, 'view', props.userId, true)
            setSubmitLoader(false)
            toast.showToast(response.data.message, 'success')
          })
          .catch((error) => {
            setSubmitLoader(false)
            toast.showToast( normalizeApiResponse(error)?.message, 'error')
          })
      } else {
        toast.showToast(
          'At least one role need to be assigned',
          'error'
        )
      }
    },
  })

  const [rolesList, setRolesList] = React.useState([])
  const getUserRoleById = (id) => {
    masterUserRoleByIdApi(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    ).then((response) => {
      let detailsResponse = response.data.result
      if (detailsResponse.roles) {
        const sortedData = [...detailsResponse.roles].sort((a, b) =>
          a.roleName.localeCompare(b.roleName)
        )
        detailsResponse.roles = sortedData
      }
      setRolesList(response.data.result)
    })
  }

  const handlePrivileges = (e, ind) => {
    setRolesList((prev) => {
      let newState = { ...prev }
      newState.roles[ind].isAssigned = e.target.checked
      return newState
    })
  }

  useEffect(() => {
    if (props?.userId) {
      getUserRoleById(props?.userId?.id)
    }
  }, [])
  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Box role="presentation">
        <List>
          <div style={{ padding: '0px 30px' }}>
            <form onSubmit={formik.handleSubmit}>
              <h3>
                <div style={{ display: 'flex', marginTop: '-20px' }}>
                  <div>Select Roles</div>
                </div>
              </h3>
              {rolesList?.roles?.map((rol, index) => (
                <FormControlLabel
                  key={index}
                  label={rol?.roleName}
                  control={
                    <Checkbox
                      checked={rol?.isAssigned}
                      onChange={(event) => handlePrivileges(event, index)}
                    />
                  }
                />
              ))}
              <Box
                style={{
                  position: 'fixed',
                  bottom: '10px',
                  background: '#fff',
                  display: 'flex',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Update User Role
                </Button>
                <div style={{ marginLeft: '20px' }}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      props.toggleDrawer('right', false, '', '', false)
                    }
                    disableElevation
                    size="large"
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            </form>
          </div>
        </List>
      </Box>
    </>
  )
}

export default EditUserRoleDetails