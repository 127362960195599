
import * as yup from "yup";

export const RoleFormValidation = yup.object().shape({
    roleName: yup.string()
        .required('Enter Role Name'),
    privileges: yup.array()
});

export const UserFormValidation = yup.object().shape({
    firstName: yup.string()
        .required('Enter First Name'),
    lastName: yup.string()
        .required('Enter Last Name'),
    email: yup.string()
        .email('Invalid Email.')
        .required('Enter Email Address'),
    mobile: yup.string()
        .matches(/^\d+$/, "Phone Number must be only digits")
        .max(10, "Phone Number must be at most 10 digits")
        .required("Phone Number is required"),
    roleIds: yup.array().min(1, "At least one role need to be assigned").required("required")
});

export const EditUserFormValidation = yup.object().shape({
    firstName: yup.string()
        .required('Enter First Name'),
    lastName: yup.string()
        .required('Enter Last Name'),
    email: yup.string()
        .email('Invalid Email.')
        .required('Enter Email Address'),
    phone: yup.string()
        .matches(/^\d+$/, "Phone Number must be only digits")
        .max(10, "Phone Number must be at most 10 digits")
        .required("Phone Number is required"),
    roleIds: yup.array().min(1, "At least one role need to be assigned").required("required")
});