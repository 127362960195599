import { createSlice } from '@reduxjs/toolkit'
import { max, set } from 'lodash'

//**INITIAL STATE */
export const initialState = {
  statesList: null,
  countriesList: null,
  subscribedStates: [],
  existingAddress: null,
  //services
  showWizard: 'list',
  showWizardName: null,
  orderService: null,
  selectedOrderService: null,
  sipAddressId: 0,
  existAddressList: null,
  term: 1,
  selectedServiceProducts: [],
  revProductCatalogueList: [],
  revService: {
    bundles: [],
    plans: [],
  },
  maxFaxList: [],
  //sip trunk
  sipTabValue: 0,
  isNewTrunkDialogOpen: false,
  isShowCreateSIPBtn: false,
  companyId: '',
  trunkList: null,
  hosts: [{ address: '', netMask: '', error: false }],
  sipTrunkModalName: 'Add New SIP Trunk',
  teamsBundleList: [],
  selectBundleId: null,
  summaryDetails: null,
  serviceAddress: {},
  isSuspended: false,
  isOrderSubmitted: false,
  isOpenCancellation: false,
  isShowActions: false,
  sipTrunk: {
    SipTrunkingId: 0,
    Direction: 'bidirectional',
    AuthType: 'I',
    Channels: 0,
    CallPerSecond: 5,
    InboundDistributionType: 'rr',
    InboundDialPattern: '1+10 Digits',
    Label: '',
    SipPort: '5060',
    Hosts: null,
    ServicePortProfile: 'west/east',
    CodecRtpG711u64K: true,
    CodecRtpG729a: false,
    CodecRtpG722: false,
    CodecDtmfRFC2833: true,
    CodecDtmfInband: false,
    CodecFax: 'T38Fallback',
    CompanyId: '',
    ApOrderID: null,
    OrderId: 0,
    Status: 0,
  },
  earlyTerminationFee: 0,
  statesList: null,
  countriesList: null,
  subscribedStates: [],
  existingAddress: null,
  //services
  showWizard: 'list',
  showWizardName: null,
  orderService: null,
  selectedOrderService: null,
  sipAddressId: 0,
  existAddressList: null,
  term: 1,
  //sip trunk
  sipTabValue: 0,
  isNewTrunkDialogOpen: false,
  isShowCreateSIPBtn: false,
  companyId: '',
  trunkList: null,
  hostMode: 'IP',
  hosts: [{ address: '', netMask: '', error: false }],
  sipTrunkModalName: 'Add New SIP Trunk',
  teamsBundleList: [],
  selectBundleId: null,
  summaryDetails: null,
  serviceAddress: {},
  isSuspended: false,
  isOrderSubmitted: false,
  isOpenCancellation: false,
  sipTrunk: {
    SipTrunkingId: 0,
    Direction: 'bidirectional',
    AuthType: 'I',
    Channels: 0,
    CallPerSecond: 5,
    InboundDistributionType: 'rr',
    InboundDialPattern: '1+10 Digits',
    Label: '',
    SipPort: '5060',
    Hosts: null,
    ServicePortProfile: 'west/east',
    CodecRtpG711u64K: true,
    CodecRtpG729a: false,
    CodecRtpG722: false,
    CodecDtmfRFC2833: true,
    CodecDtmfInband: false,
    CodecFax: 'T38Fallback',
    CompanyId: '',
    ApOrderID: null,
    OrderId: 0,
    Status: 0,
  },
  earlyTerminationFee: 0,
  revAddressId : 0
}

export const serviceSlice = createSlice({
  name: 'serviceSlice',
  initialState,
  reducers: {
    setStatesList: (state, { payload }) => {
      state.statesList = payload
    },
    setCountriesList: (state, { payload }) => {
      state.countriesList = payload
    },
    setExistingAddress: (state, { payload }) => {
      state.existingAddress = payload
    },
    setSubscribedStates: (state, { payload }) => {
      state.subscribedStates = payload
    },
    setShowWizard: (state, { payload }) => {
      state.showWizard = payload
    },
    setSipAddressId: (state, { payload }) => {
      state.sipAddressId = payload
    },
    setShowWizardName: (state, { payload }) => {
      state.showWizardName = payload
    },
    setOrderService: (state, { payload }) => {
      state.orderService = payload
    },
    setSelectedOrderService: (state, { payload }) => {
      state.selectedOrderService = payload
    },
    setTerm: (state, { payload }) => {
      state.term = payload
    },
    setSelectedServiceProducts: (state, { payload }) => {
      state.selectedServiceProducts = payload
    },
    setRevProductCatalogueList: (state, { payload }) => {
      state.revProductCatalogueList = payload
    },
    setRevService: (state, { payload }) => {
      state.revService = payload
    },
    setMaxFaxList: (state, { payload }) => {
      state.maxFaxList = payload
    },
    setIsShowActions: (state, { payload }) => {
      state.isShowActions = payload
    },

    //sip trunk
    setExistAddressList: (state, { payload }) => {
      state.existAddressList = payload
    },
    setSipTabValue: (state, { payload }) => {
      state.sipTabValue = payload
    },
    setNewTrunkDialogOpen: (state, { payload }) => {
      state.isNewTrunkDialogOpen = payload
    },
    setShowCreateSIPBtn: (state, { payload }) => {
      state.isShowCreateSIPBtn = payload
    },
    setIsOrderSubmitted: (state, { payload }) => {
      state.isOrderSubmitted = payload
    },
    setCompanyId: (state, { payload }) => {
      state.companyId = payload
    },
    setServiceAddress: (state, { payload }) => {
      state.serviceAddress = payload
    },
    setTrunkList: (state, { payload }) => {
      state.trunkList = payload
    },
    setSipTrunkModalName: (state, { payload }) => {
      state.sipTrunkModalName = payload
    },
    setTeamsBundleList: (state, { payload }) => {
      state.teamsBundleList = payload
    },
    setSummaryDetails: (state, { payload }) => {
      state.summaryDetails = payload
    },
    setIsSuspended: (state, { payload }) => {
      state.isSuspended = payload
    },
    setIsOpenCancellation: (state, { payload }) => {
      state.isOpenCancellation = payload
    },
    setSelectBundleId: (state, { payload }) => {
      state.selectBundleId = payload
    },
    setHostMode: (state, { payload }) => {
      state.hostMode = payload
    },
    setHosts: (state, { payload }) => {
      state.hosts = payload
    },
    setSipTrunk: (state, { payload }) => {
      state.sipTrunk = { ...state.sipTrunk, ...payload }
    },
    setEarlyTerminationFee: (state, { payload }) => {
      state.earlyTerminationFee = payload
    },
    setRevAddressId: (state, { payload }) => {
      state.revAddressId = payload
    },
    //resets
    resetSipTrunk(state) {
      state.sipTrunk = initialState.sipTrunk
    },
    resetServiceAddress(state) {
      state.serviceAddress = initialState.serviceAddress
    },
    resetHosts(state) {
      state.hosts = initialState.hosts
    },
    resetRevService(state) {
      state.revService = initialState.revService
    },
  },
})

// export state as function
export const serviceSliceState = (state) => state.serviceSlice
// export actions

export const {
  setCountriesList,
  setStatesList,
  setExistingAddress,
  setSubscribedStates,
  setSipAddressId,
  setShowWizard,
  setShowWizardName,
  setOrderService,
  setExistAddressList,
  setSelectedOrderService,
  setTerm,
  setSelectedServiceProducts,
  setRevProductCatalogueList,
  setRevService,
  setMaxFaxList,
  setIsShowActions,
  setSipTabValue,
  setNewTrunkDialogOpen,
  setShowCreateSIPBtn,
  setCompanyId,
  setServiceAddress,
  setTrunkList,
  setSipTrunkModalName,
  setIsSuspended,
  setIsOrderSubmitted,
  setIsOpenCancellation,
  setHosts,
  setTeamsBundleList,
  setSummaryDetails,
  setSelectBundleId,
  setSipTrunk,
  setEarlyTerminationFee,
  setRevAddressId,
  setHostMode,
  resetSipTrunk,
  resetServiceAddress,
  resetHosts,
  resetRevService,
} = serviceSlice.actions

export default serviceSlice.reducer
