import { DataGrid, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Grid, IconButton, useTheme } from '@mui/material'

//mui icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'

import ThemePagination from './Pagination'
import { formatDate } from '../../../helpers/HelperFunctions'

//styled Components
const GridContainer = styled(Grid)({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-evenly',
  flexWrap: 'nowrap',
  width: '100%',
  maxHeight: 500,
})

const ThemeDataGrid = styled(DataGrid)(({ theme }) => ({
  '&.MuiDataGrid-root': {
    boxShadow:
      '0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    borderRadius: '12px 12px 12px 0px 12px',
    Opacity: 1,
  },
  '&>.MuiDataGrid-main': {
    overflowY: 'auto',
    '&>.MuiDataGrid-columnHeaders': {},
    '& div div div div >.MuiDataGrid-cell': {},
    '& div div div div >.MuiDataGrid-root': {
      boxShadow:
        '0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    },
  },
  '& .MuiDataGrid-virtualScroller': {
    overflowX: 'hidden', // Hide horizontal scrollbar
  },
  '& .MuiDataGrid-columnHeaders': {
    background: '#E3E7EF',
    color: 'white',
    fontWeight: 700,
  },
  '& .MuiDataGrid-columnHeader': {
    //border: `1px solid #ffff`, // show column splitter border
    color: '#000000', //#2E384D',
  },
  '.MuiDataGrid-sortIcon': {
    color: '#2E384D',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none', // Hide the header column splitter
  },
}))

//COMPONENT
const CustomDataGrid = ({
  searchValue,
  filteredRows,
  columns,
  data,
  noDataText,
  handleEditModal,
  isRowEditable = false,
  ...props
}) => {
  //Hooks
  const theme = useTheme()
  const [rowModesModel, setRowModesModel] = useState({})
  const [rows, setRows] = useState(data)
  const [autoSizedColumns, setAutoSizedColumns] = useState(columns)

  //lifeCycle
  useEffect(() => {
    setRows(searchValue ? getDataGridRows(filteredRows) : getDataGridRows(data))
    const newColumns = columns.map((column) => ({
      ...column,
      width: calculateColumnWidth(rows, column.field, column.headerName),
    }))
    setAutoSizedColumns(newColumns)
  }, [searchValue, filteredRows, data])

  const calculateColumnWidth = (rows, field, headerText) => {
    const maxContentLength = Math.max(
      ...rows.map((row) => String(row[field] || '').length),
      headerText.length
    )
    // Adjust this value for padding and other CSS styles
    const padding = 20
    return maxContentLength * 10 + padding // Adjust multiplier as needed
  }

  //Adding Actions to column
  const columnsWithActions = autoSizedColumns
    ? [
        ...autoSizedColumns,
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 0.5,
          renderCell: ({ row, value }) => {
            const { id } = row
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label="Cancel"
                  className="textPrimary"
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />,
              ]
            }

            return (
              row.Status == 4 && (
                <IconButton
                  color="secondary"
                  onClick={() => {
                    isRowEditable ? makeRowEditable(id) : handleEditModal(row)
                  }}
                  size="small"
                >
                  <EditOutlinedIcon color="secondary" fontSize="14px" />
                </IconButton>
              )
            )
          },
        },
      ]
    : []

  //make row editable
  const makeRowEditable = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  //save edit
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  //cancel edit
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }

  //handle edit modes change
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const getDataGridRows = (data) => {
    if (data) {
      return data?.map((items, index) => ({
        ...items,
        createdDateTime: formatDate(new Date(items.createdDateTime)),
        key: index + 1,
      }))
    } else return []
  }

  //**RENDER
  return (
    <Grid sx={{ maxHeight: 500, width: '100%' }}>
      <ThemeDataGrid
        {...props}
        columns={props?.isAction ? columnsWithActions : columns}
        rows={rows}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        scrollbarSize={1}
        localeText={{ noRowsLabel: `Unable to discover ${noDataText}` }}
        hideFooterSelectedRowCount
        editRowsModel={rowModesModel}
        onEditRowsModelChange={handleRowModesModelChange}
        disableColumnMenu
        autoHeight
        sortingOrder={['asc', 'desc']}
        initialState={{
          pagination: {
            pageSize: 5,
          },
        }}
        components={{
          Pagination: ThemePagination,
        }}
      />
    </Grid>
  )
}

export default CustomDataGrid
