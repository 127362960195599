import { useState, useCallback } from 'react'

const useToast = () => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')
  const [autoHideDuration, setAutoHideDuration] = useState(3000)
  const [variant, setVariant] = useState('filled')
  const [position, setPosition] = useState({
    vertical: 'bottom',
    horizontal: 'center',
  })

  const showToast = useCallback((msg, severity = 'success', options = {}) => {
    setMessage(msg)
    setSeverity(severity)
    setAutoHideDuration(options.autoHideDuration || 4000)
    setVariant(options.variant || 'filled')
    setPosition(options.position || { vertical: 'top', horizontal: 'right' })
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return {
    open,
    message,
    severity,
    autoHideDuration,
    variant,
    position,
    showToast,
    handleClose,
  }
}

export default useToast
