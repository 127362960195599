import React from 'react'
import { Stack, Switch, FormControlLabel, Typography } from '@mui/material'

// ThemeSwitch component
export const ThemeSwitch = ({
  checked,
  handleToggle,
  textLeft,
  textRight,
  isDisable = false,
  isRequired = false,
  color = 'primary',
  ...props
}) => {
  const label = { inputProps: { 'aria-label': 'theme-switch' } }
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      {textLeft && <Typography>{textLeft}</Typography>}
      <Switch
        {...label}
        {...props}
        checked={checked}
        onChange={handleToggle}
        disabled={isDisable}
        required={isRequired}
        color={color}
      />
      {textRight && <Typography>{textRight}</Typography>}
    </Stack>
  )
}

// ThemeSwitchLabel component
export const ThemeSwitchLabel = ({
  label,
  checked,
  handleToggle,
  labelPlacement = 'start',
  ...props
}) => {
  return (
    <FormControlLabel
      control={<Switch {...props} checked={checked} onChange={handleToggle} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}
