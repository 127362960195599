import * as React from 'react';
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { motion } from "framer-motion";
import altigenLogo from "../../../assets/altigen-logo.png";

const LoadingSection = (props) => {
    return (
        <Box sx={{
            position: 'fixed',
            top: 0, left: 0, zIndex: 99999999999,
            width: '100vw', height: '100vh',
            background: 'rgb(211 211 211 / 48%)',
            transition: 'opacity 0.2s',
        }}>
            <div>
                <LinearProgress sx={{ width: '100%' }} />
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '90vh',
            }}>
                <CircularProgress style={{
                    width: '50px',
                    height: '50px',
                    color: ' #006dff'
                }} />
            </div>

        </Box>
    );
};

export default LoadingSection;


//New Altigen Loader
export const AltigenLogoFlipLoader = () => {
    return (
        <motion.div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 1000, //max z-index
                width: "100vw",
                height: "100vh",
                background: "rgb(211 211 211 / 48%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <motion.img
                src={altigenLogo}
                alt="Altigen Logo"
                style={{
                    width: "100px",
                    height: "100px",
                    transformStyle: "preserve-3d",
                }}
                initial={{ opacity: 1, rotateY: 0 }}
                animate={{ opacity: 1, rotateY: 180 }}
                exit={{ opacity: 1, rotateY: 0 }}
                transition={{ duration: 1, ease: "easeInOut", repeat: Infinity, repeatType: "reverse" }}
            />
        </motion.div>
    );
};