import { Button, Typography } from '@mui/material'
import { Stack } from '@mui/material'
import React from 'react'
import notfound from '../../../../assets/404page.svg'
import { history } from '../../router/HistoryRouter'

function NotFound() {
  return (
    <Stack justifyContent={'center'} alignItems="center" height="100vh">
      <img src={notfound} height={'400'} width={'500'} />

      <Typography>Sorry, the page you visited does not exist.</Typography>
      <Button onClick={() => history.replace('/login')}>Retry</Button>
    </Stack>
  )
}

export default NotFound
