import * as Yup from 'yup'

export const BillingFormValidation = Yup.object().shape({
  firstName: Yup.string().required('*First Name is required'),
  lastName: Yup.string().required('* Last Name is required'),
  cardNumber: Yup.string()
    .matches(/^\d{12,16}$/, 'Card Number should be between 12 and 16 digits')
    .required('* Card Number is required'),
  expirationMonth: Yup.string()
    .required('Month required')
    .matches(/^(0?[1-9]|1[0-2])$/, 'Invalid month')
    .test(
      'future-month',
      'Expiry Month cannot be in the past',
      function (value) {
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth() + 1
        const expirationYear = this.parent.expirationYear
        if (
          parseInt(expirationYear, 10) === currentYear &&
          parseInt(value, 10) < currentMonth
        ) {
          return false
        }
        return true
      }
    ),

  expirationYear: Yup.string()
    .min(4, 'Year should be 4 digits')
    .max(4, 'Year should be 4 digits')
    .required('Year required')
    .test('future-year', 'Expiry Year cannot be in the past', (value) => {
      const currentYear = new Date().getFullYear()
      return parseInt(value, 10) >= currentYear
    }),
  cvv: Yup.string()
    .matches(/^\d{3,4}$/, 'CVV should be 3 or 4 digits')
    .required('CVV required'),
})
