import { useEffect } from 'react';

// mui
import { Container, Grid, Box, Radio, FormControlLabel, RadioGroup, FormControl } from '@mui/material';

// css
import './billingtype.css'
import '../steppers.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  formDetailsState,
  setBillinType,
  setRevParent,
} from '../../../../../store/slice/stepperSlice'

function BillingType({ config }) {
  const dispatch = useDispatch()
  const state = useSelector(formDetailsState)
  const { billingType } = useSelector((states) => states.stepperSlice)
  useEffect(() => {
    // if(state.companyLevel == COMPANY_LEVELS.reseller && state.isAltigenBilling) {
    //  if(!billingType?.resellerBillingType){
    //    dispatch(setBillinType({ name: 'billingType', value:2 }))
    //    dispatch(setResellerBillinType({ name: 'resellerBillingType', value: 1 }))
    //  }
    //   // billingType.resellerBillingType = 1;
    // }
  }, [])
  const handleCheckChange = (e) => {
    // if(state.companyLevel == COMPANY_LEVELS.reseller){
    //   dispatch(setBillinType({ name: e.target.name, value:2 }))
    //   dispatch(setResellerBillinType({ name: e.target.name, value: e.target.value }))
    // }else{
    if (e.target.value == 1) {
      dispatch(setRevParent(true))
    } else {
      dispatch(setRevParent(false))
    }
    // dispatch(setResellerBillinType({ name: e.target.name, value: 1 }))
    dispatch(setBillinType({ name: e.target.name, value: e.target.value }))
    // }
  }

  return (
    <Container>
      <Grid container>
        {/* part 1 */}
        <Grid item md={12} sm={12} xs={12}>
          <Box className="billingType-mainContainer">
            <Box className="billingtype-main-headingcontainer">
              <h3>Billing Type</h3>
            </Box>

            <Box className="billingtype-form-container1">
              <Box className="billingtype-form-container2 stepper-box-shadow-style stepper-border-style">
                <Box className="billingtype-form-input1">
                  <Box className="billingtype-form-input1-3">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={billingType.billingType}
                        onChange={handleCheckChange}
                        name="billingType"
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={
                            <>
                              Altigen will bill you
                              <span
                                style={{
                                  display: 'inline-flex',
                                  verticalAlign: 'middle',
                                  color: '#4682b4',
                                }}
                              >
                                {/*<HelpIcon />*/}
                              </span>
                            </>
                          }
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={
                            <>
                              Altigen will bill your Resellers/Direct Customers
                              <span
                                style={{
                                  display: 'inline-flex',
                                  verticalAlign: 'middle',
                                  color: '#4682b4',
                                }}
                              >
                                {/*<HelpIcon />*/}
                              </span>
                            </>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default BillingType
