import React, { useState } from 'react'
// mui
import { Grid, Box, Container } from '@mui/material'
import MasterContact from './components/MasterContact'
import MasterBillings from './components/MasterBilling'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'
import { COMPANY_LEVELS } from '../../../helpers/Constants'

export default function ContactPage(props) {
  const { dispatch, state: commonState } = useCommonRedux()
  const [isBilling, setIsBilling] = useState(false)

  const handleShowBillingAddress = (value) => setIsBilling(value)
  return (
    <div className="Dash_main_container">
      <Container maxWidth="xl">
        <Box>
          <Grid container columnSpacing={6} rowSpacing={4}>
            {[COMPANY_LEVELS.customer, COMPANY_LEVELS.reseller].includes(commonState.currentCompanyDetails?.companyLevel) &&
              <Grid item md={6} sm={12} xs={12}>
                <MasterContact setShowBilling={handleShowBillingAddress} />
              </Grid>
            }
            {commonState.currentCompanyDetails?.companyLevel ===
              COMPANY_LEVELS.customer &&
              commonState.currentCompanyDetails?.directCustomer === false ? (
              <></>
            ) : (
              <Grid item md={6} sm={12} xs={12}>
                <MasterBillings isShowBillingAddress={isBilling} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  )
}
