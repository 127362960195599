import { configureStore } from '@reduxjs/toolkit'

import stepperReducer from './slice/stepperSlice'
import homeReducer from './slice/homeSlice'
import serviceReducer from './slice/serviceSlice'
import settingsReducer from './slice/settingsSlice'
import commonReducer from './slice/commonSlice'
import userManagementReducer from './slice/userManagementSlice'

export const store = configureStore({
  reducer: {
    stepperSlice: stepperReducer,
    homeSlice: homeReducer,
    serviceSlice: serviceReducer,
    settingsSlice: settingsReducer,
    commonSlice: commonReducer,
    userManagementSlice: userManagementReducer,
  },
})
