import SearchLayout from '../components/custom/Search'
import { Grid, Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { styled } from '@mui/system'
import ThemeButton from '../components/button/Button'
import { useState } from 'react'
import { useHomeRedux } from '../../pages/home/Home'
import {
  setAlignment,
  setToggleCustomer,
  setToggleReseller,
} from '../../store/slice/homeSlice'

const FlexContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  padding: '10px',
})

const SpaceBetweenContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '10px',
})

const FlexEndContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
})

const SpacingWrapper = styled(Grid)({
  width: '100%',
  padding: 20,
})

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  backgroundColor: '#F7F8FA',
  borderRadius: '4px',
  position: 'relative',
  marginRight: '10px',
  height: '40px',
  padding: '8px',
})

const StyledToggleButton = styled(ToggleButton)({
  border: 'none',
  height: '22px',
  justifyContent: 'space-between',
  textTransform: 'uppercase',
  '&.Mui-selected': {
    borderRadius: '4px',
    boxShadow:
      '0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    color: '#172B4E',
  },
  fontWeight: '600',
  color: '#4E535A',
})

const DashboardLayout = ({
  name,
  searchValue,
  setSearchValue,
  buttonStartIcon,
  children,
  isShowSearch = true,
  isButtonShow = true,
  handleButtonClick,
}) => {
  const { dispatch, state } = useHomeRedux()

  //toggle
  const handleToggle = (e, newAlignment) => {
    dispatch(setAlignment(newAlignment))
    if (newAlignment === 'customer') {
      dispatch(setToggleReseller(false))
      dispatch(setToggleCustomer(true))
    } else {
      dispatch(setToggleReseller(true))
      dispatch(setToggleCustomer(false))
    }
  }

  return (
    <SpacingWrapper container spacing={6}>
      {isShowSearch ? (
        <SpaceBetweenContainer>
          <SearchLayout role={name} />
          {isButtonShow && (
            <div>
              {location.pathname?.slice(1) === 'serviceprovider' && (
                <StyledToggleButtonGroup
                  value={state.alignment}
                  exclusive
                  variant="outlined"
                  onChange={handleToggle}
                >
                  <StyledToggleButton value="reseller" size="medium">
                    Reseller
                  </StyledToggleButton>
                  <StyledToggleButton value="customer">
                    Customer
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              )}
              <ThemeButton
                variant="contained"
                text={`Invite ${name}`}
                onClick={(e) => handleButtonClick(e, name)}
                startIcon={buttonStartIcon}
              />
            </div>
          )}
        </SpaceBetweenContainer>
      ) : (
        <FlexEndContainer>
          {isButtonShow && (
            <ThemeButton
              variant="contained"
              text={`Invite ${name}`}
              onClick={(e) => handleButtonClick(e, name)}
              startIcon={buttonStartIcon}
            />
          )}
        </FlexEndContainer>
      )}
      <FlexContainer sx={{ flexDirection: 'column' }}>
        {/* <BreadcrumbsLayout /> */}
        {children}
      </FlexContainer>
    </SpacingWrapper>
  )
}

export default DashboardLayout
