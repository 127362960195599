// redux hooks
import { useSelector, useDispatch } from 'react-redux'
// redux states and actions
import {
  formDetailsState,
  setAccountDetails,
  setbillingInfo,
  StepForward,
} from '../../../../../store/slice/stepperSlice'
import nextbtn from '../../../../../assets/icons/Next Button.svg'
import '../steppers.css'

import { Box, Container, Grid } from '@mui/material'

import './Accountdetails.css'
import { Form, Formik } from 'formik'
import { accountDetailsYup } from '../../../../../validations/SignupValidation'
import ReusableTextfield from '../../../../../common/components/textField/textField'

function AccountDetails(props) {
  const dispatch = useDispatch()
  const { accountDetail, step, billing } = useSelector(formDetailsState)

  const handleNumberInputChange = (event, field, setFieldValue) => {
    const inputValue = event.target.value
    const numericValue = inputValue.replace(/\D/g, '') // Remove non-numeric characters

    // Update the form field value with the numeric value
    setFieldValue(field, numericValue)
  }
  return (
    <Container>
      <Formik
        initialValues={{ ...accountDetail }}
        validationSchema={accountDetailsYup}
        onSubmit={(values) => {
          dispatch(StepForward())
          dispatch(setAccountDetails(values))
          dispatch(
            setbillingInfo({
              ...billing,
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              phoneExtension: values.extNumber,
            })
          )
        }}
        enableReinitialize={true}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item md={24} sm={24} xs={24}>
                <Box className="stepper-containers">
                  <h3>Account Details</h3>
                  <Box className="stepper-border-style stepper-main-contianer1">
                    <Box className="stepper-input-label-margin">
                      <ReusableTextfield
                        id="Email"
                        name="email"
                        disabled
                        required
                        size="small"
                      />
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <ReusableTextfield
                        size="small"
                        name="firstName"
                        id="First Name"
                        required
                      />
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <ReusableTextfield
                        size="small"
                        name="lastName"
                        id="Last Name"
                        required
                      />
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <Grid container spacing={2}>
                        <Grid item md={10} sm={10} xs={12}>
                          <ReusableTextfield
                            size="small"
                            name="phone"
                            id="Phone Number"
                            required
                            InputProps={{
                              inputProps: {
                                minLength: 10,
                                maxLength: 10,
                              },
                            }}
                            value={values.phone}
                            onChange={(e) =>
                              handleNumberInputChange(e, 'phone', setFieldValue)
                            }
                          />
                        </Grid>
                        <Grid item md={2} sm={2} xs={12}>
                          <ReusableTextfield
                            size="small"
                            name="extNumber"
                            id="EXT"
                            InputProps={{
                              inputProps: {
                                minLength: 10,
                                maxLength: 20,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={{ pl: '10px' }}>
                    <Grid container>
                      <Grid item md={2}>
                        <img
                          src={nextbtn}
                          className="btn-hover-effect"
                          height="92px"
                          onClick={handleSubmit}
                          alt="next button"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default AccountDetails
