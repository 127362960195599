import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Box,
  IconButton,
  Tooltip,
  Grid,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import React, { useEffect } from 'react'
import { styled } from '@mui/system'

const NewPosOfDialog = styled(Dialog)(() => ({
  position: 'absolute !important',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minHeight: '80vh',
  maxHeight: '80vh',
}))

const ResetPasswordModal = ({
  title,
  content,
  children,
  hiddenConform,
  isOpen,
  handleClose,
  handleConfirm,
  hideActionsBtn,
  topValue,
  btnOk,
  btnCancel,
}) => {
  const [confirmBtn, setConfirmBtn] = React.useState('Confirm')
  const [cancelBtn, setCancelBtn] = React.useState('Cancel')
  useEffect(() => {
    if (btnCancel) {
      setCancelBtn(btnCancel)
    }
    if (btnOk) {
      setConfirmBtn(btnOk)
    }
  }, [])
  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      style={{ top: topValue ? topValue : '20% !important' }}
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <Tooltip title="Close">
          <IconButton onClick={handleClose}>
            <CancelIcon color="error" size="large"></CancelIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <DialogContent>
        <Grid>{content}</Grid>
      </DialogContent>
      {hideActionsBtn ? (
        ''
      ) : (
        <DialogActions>
          <Button color="secondary" onClick={handleClose} variant="contained">
            {cancelBtn}
          </Button>
          {hiddenConform ? (
            ''
          ) : (
            <Button color="error" onClick={handleConfirm} variant="contained">
              {confirmBtn}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>

    // <Dialog open={isOpen} onClose={handleClose}>
    //     <DialogTitle>{title}</DialogTitle>
    //     <DialogContent>
    //         <DialogContentText>{subtitle}</DialogContentText>
    //         <Divider />
    //         {children}
    //     </DialogContent>
    //     <DialogActions>
    //         <Button onClick={handleClose} color="error">
    //             Cancel
    //         </Button>
    //         <Button onClick={handleConfirm} color="primary">
    //             Agree
    //         </Button>
    //     </DialogActions>
    // </Dialog>
  )
}

export default ResetPasswordModal
