import React, { createContext, useContext, useEffect } from 'react'
import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import RoleTable from './roles/roleTable'
import UsersTable from './users/usertable'
import { useSelector } from 'react-redux'
import { userManagementSliceState } from '../../store/slice/userManagementSlice'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import { checkPermission } from '../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../helpers/Constants'
import { useCommonRedux } from '../../store/middlewares/CommonRedux'

const UserManagementReduxContext = createContext(null)
export const useUserManagementRedux = () =>
  useContext(UserManagementReduxContext)

function UserManagement(props) {
  const { dispatch, state: commonState } = useCommonRedux()
  const state = useSelector(userManagementSliceState)
  const [value, setValue] = React.useState('1')
  const theme = useTheme()

  const StyledTitle = styled('div')(() => ({
    justifyContent: 'center',
    opacity: 1,
    left: '34px',
    padding: '0 21px',
  }))

  const handleChange = (_event, newValue) => {
    setValue(newValue)
    sessionStorage.setItem('tab', newValue)
  }

  const userPermission = [
    PERMISSIONS.masterUserView,
    PERMISSIONS.serviceproviderUserView,
    PERMISSIONS.resellerUserView,
    PERMISSIONS.customerUserView,
  ]
  const rolePermission = [
    PERMISSIONS.masterRoleView,
    PERMISSIONS.serviceproviderRoleView,
    PERMISSIONS.resellerRoleView,
    PERMISSIONS.customerRoleView,
  ]
  useEffect(() => {
    checkPermission(userPermission, commonState.currentCompanyDetails)
      ? setValue('1')
      : checkPermission(rolePermission, commonState.currentCompanyDetails)
      ? setValue('2')
      : ''
  }, [])

  // Capture the window onload
  useEffect(() => {
    window.onload = triggerOnReload()
  }, [])

  const sideMenu = [
    {
      label: 'Users',
      value: '1',
      permission: userPermission,
    },
    {
      label: 'Roles',
      value: '2',
      permission: rolePermission,
    },
  ]

  //trigger reload to set navigation tab
  const triggerOnReload = () => {
    const navTab = sessionStorage.getItem('tab')
    setValue(navTab || value)
  }

  return (
    <UserManagementReduxContext.Provider value={{ dispatch, state }}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              opacity: 1,
              backgroundColor: 'rgba(231, 241, 253, 1)',
              display: 'flex',
            }}
          >
            <TabList
              onChange={handleChange}
              sx={{
                paddingLeft: '164px',
              }}
              aria-label="User Roles Tabs List"
              textColor="primary"
              variant="scrollable"
              allowScrollButtonsMobile
              indicatorColor="primary"
              TabIndicatorProps={{
                title: 'indicator',
                sx: {
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
              }}
            >
              {sideMenu.map((mapDat, index) => {
                if (
                  checkPermission(
                    mapDat.permission,
                    commonState.currentCompanyDetails
                  )
                ) {
                  return (
                    <Tab
                      key={`${mapDat}${index}`}
                      label={
                        <StyledTitle>
                          <div>{mapDat.label}</div>
                        </StyledTitle>
                      }
                      value={mapDat.value}
                    />
                  )
                }
              })}
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: '0px' }}>
            {checkPermission(
              userPermission,
              commonState.currentCompanyDetails
            ) ? (
              <UsersTable sx={{ padding: 0 }}></UsersTable>
            ) : (
              ''
            )}
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '0px' }}>
            {checkPermission(
              rolePermission,
              commonState.currentCompanyDetails
            ) ? (
              <RoleTable sx={{ padding: 0 }}></RoleTable>
            ) : (
              ''
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </UserManagementReduxContext.Provider>
  )
}
export default UserManagement
