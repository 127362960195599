import {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Box } from '@mui/material'
// search params
import { useLocation, useNavigate } from 'react-router-dom'

// jwt
import jwt_decode from 'jwt-decode'

import {
  getAllServiceProvider,
  getAltigenCustomers,
  getAllResellers,
  getAllcustomers,
  getspdetailsApproval,
  getresellerApproval,
  getcustomerApproval,
  getCompanyProfile,
} from '../../api/get/getApi'
import Services from './services/Services'
// url params
import { useSearchParams } from 'react-router-dom'

// company status
import Approvalcompany from './approval/Approval'
import ServiceProvider from '../../pages/home/serviceprovider/ServiceProvider'
import Master from '../../pages/home/master/Master'
import Customer from '../../pages/home/customer/Customer'
import { useSelector } from 'react-redux'
import {
  homeSliceState,
  setAltigenCustomers,
  setCompanyLevelApproval,
  setCustomersList,
  setCustomerTrigger,
  setDirectCustomersList,
  setIsToggleCustomer,
  setIsToggleReseller,
  setIsToggleServiceProvider,
  setResellersList,
  setResellerTrigger,
  setServiceProvidersList,
  setServiceProviderTrigger,
} from '../../store/slice/homeSlice'
import SkeletonTableLoader from '../../common/components/loaders/skeletonLoader'
import NewCompany from '../../common/components/custom/new-features/NewCompany'
import {
  checkPermission,
  decodeToken,
  gettingCompanyId,
  getToken,
  normalizeApiResponse,
} from '../../helpers/HelperFunctions'
import {
  COMPANY_LEVELS,
  COMPANY_STATUS,
  PERMISSIONS,
} from '../../helpers/Constants'
import { useCommonRedux } from '../../store/middlewares/CommonRedux'
import {
  setCurrentCompanyDetails,
  setCurrentCompanyLevel,
  setCustomerId,
  setIsDirectCustomer,
  setIsMaster,
  setResellerId,
  setServiceProviderId,
} from '../../store/slice/commonSlice'
import { useToastContext } from '../../App'

//CONTEXT
const HomeReduxContext = createContext(null)
export const useHomeRedux = () => useContext(HomeReduxContext)

//COMPONENT
function Home() {
  const { dispatch, state: commonState } = useCommonRedux()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchparams] = useSearchParams()
  const { toast } = useToastContext();

  //states
  const state = useSelector(homeSliceState)
  const [tabValue, setTabValue] = useState('1')
  const [currentRole, setCurrentRole] = useState('')
  //boolean
  const [loading, setloading] = useState(false)
  const [showServices, setShowServices] = useState(false)
  const [isCustomerApproval, setIsCustomerApproval] = useState(false)
  const [showMaster, setShowMaster] = useState(false)
  const [showServiceProvider, setshowServiceProvider] = useState(false)
  const [showCustomer, setshowCustomer] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  //global constant variables
  const getLocation = location.pathname
  const config = { headers: { Authorization: `Bearer ${localToken}` } }
  var localToken = getToken()

  // Capture the window onload
  useEffect(() => {
    window.onload = triggerOnReload()

    if (localToken) {
      const decodedToken = jwt_decode(localToken)
      dispatch(
        setIsMaster(decodedToken.company_level === COMPANY_LEVELS.master)
      )
    }

    RoutingWithSwitch(getLocation)
    if (getLocation.slice(1));
    const path = getLocation.slice(1)
    const tab = sessionStorage.getItem('tab')
    if (path == 'master') {
      setTabValue('1')
      setShowMaster(true)
    } else if (path == 'serviceprovider') {
      setTabValue(tab || '2')
    } else if (path == 'reseller') {
      setTabValue('3')
    } else if (path == 'customer') {
      setTabValue('4')
    }

    if (
      checkPermission(
        [PERMISSIONS.serviceproviderCompanyView],
        commonState.currentCompanyDetails,
        'serviceprovider'
      )
    ) {
      if (state.serviceProviderTrigger != '') {
        AllServiceProviderFunction(config.headers)
      }
    }

    const controller = new AbortController()
    return () => {
      controller.abort()
    }
  }, [getLocation])

  useEffect(() => {
    if (state.resellerTrigger != '') {
      const isServiceProviderid = searchparams.get('company_id')
      if (
        checkPermission(
          [PERMISSIONS.resellerCompanyView],
          commonState.currentCompanyDetails,
          'reseller'
        )
      ) {
        if (isServiceProviderid == null) {
          const token = getToken()
          const decodedToken = jwt_decode(token)

          showAllResellerFunction(decodedToken.company_id, config.headers)
        } else {
          showAllResellerFunction(isServiceProviderid, config.headers)
        }
      }
    }
  }, [state.resellerTrigger])

  useEffect(() => {
    if (state.customerTrigger != '') {
      const isServiceProviderid = searchparams.get('company_id')
      if (isServiceProviderid == null) {
        const token = getToken()
        const decodedToken = decodeToken(token)
        AltigenCustomersFunction(config.headers)
        getDirectCustomers(decodedToken.company_id, config.headers)
      }
      !state.customersList && getCustomers(isServiceProviderid, config.headers)
    }
  }, [state.customerTrigger])

  //trigger reload to set tab
  const triggerOnReload = useCallback(() => {
    const tab = sessionStorage.getItem('tab')
    setTabValue(tab || tabValue)
  }, [tabValue])

  const clearShowCompanies = (name) => {
    switch (name) {
      case 'master':
        setShowServices(false)
        setshowCustomer(false)
        setshowServiceProvider(false)
        dispatch(setIsToggleReseller(false))
        dispatch(setIsToggleCustomer(false))
        break
      case 'serviceprovider':
        setshowServiceProvider(true)
        setShowMaster(false)
        setshowCustomer(false)
        setShowServices(false)
        dispatch(setIsToggleServiceProvider(false))
        dispatch(setIsToggleCustomer(false))
        break
      case 'customer':
        setshowCustomer(true)
        setshowServiceProvider(false)
        setShowMaster(false)
        setShowServices(false)
        dispatch(setIsToggleServiceProvider(false))
        dispatch(setIsToggleReseller(false))
        break
      case 'services':
        setShowServices(true)
        setshowServiceProvider(false)
        setShowMaster(false)
        setshowCustomer(false)
        dispatch(setIsToggleServiceProvider(false))
        dispatch(setIsToggleReseller(false))
        dispatch(setIsToggleCustomer(false))
        break

      default:
        break
    }
  }

  const masterValidation = () => {
    if (localToken) {
      const decodedToken = jwt_decode(localToken)
      if (decodedToken.company_level === COMPANY_LEVELS.master) {
        clearShowCompanies('master')
        setShowMaster(true)
        if (
          checkPermission(
            [PERMISSIONS.customerCompanyView],
            commonState.currentCompanyDetails,
            'customer'
          )
        )
          !state.altigenCustomers && AltigenCustomersFunction(config.headers)

        !showMaster &&
          !Object.keys(commonState.currentCompanyDetails)?.length &&
          getMasterDetails()
      } else {
        navigate(-1)
      }
    } else {
      navigate('/login')
    }
  }

  const serviceProviderValidation = () => {
    if (localToken) {
      const decodedToken = decodeToken(localToken)
      const isServiceProviderid = searchparams.get('company_id')
      clearShowCompanies('serviceprovider')
      dispatch(setServiceProviderTrigger('triggered'))
      if (
        decodedToken.company_level === COMPANY_LEVELS.master &&
        isServiceProviderid != null &&
        decodedToken.company_status == COMPANY_STATUS.Active
      ) {
        if (
          checkPermission(
            [PERMISSIONS.serviceproviderCompanyView],
            commonState.currentCompanyDetails,
            'serviceprovider'
          )
        ) {
          !showServiceProvider &&
            !Object.keys(commonState.currentCompanyDetails)?.length &&
            getServiceProviderDetails()
        }
      } else if (
        decodedToken.company_level === COMPANY_LEVELS.serviceprovider &&
        decodedToken.company_status == COMPANY_STATUS.Active
      ) {
        setshowServiceProvider(true)
        if (
          checkPermission(
            [PERMISSIONS.resellerCompanyView],
            commonState.currentCompanyDetails,
            'reseller'
          )
        ) {
          !state.resellersList &&
            showAllResellerFunction(decodedToken.company_id, config.headers)
        }
        if (
          checkPermission(
            [PERMISSIONS.customerCompanyView],
            commonState.currentCompanyDetails,
            'customer'
          )
        ) {
          getDirectCustomers(decodedToken.company_id, config.headers)
        }
        if (
          checkPermission(
            [PERMISSIONS.serviceproviderCompanyView],
            commonState.currentCompanyDetails,
            'serviceprovider'
          )
        ) {
          getServiceProviderDetails()
        }
      } else {
        navigate(-1)
      }
    } else {
      navigate('./login')
    }
  }

  const resellerValidation = () => {
    if (localToken) {
      const decodedToken = jwt_decode(localToken)
      const isResellerid = searchparams.get('company_id')
      clearShowCompanies('customer')
      if (
        checkPermission(
          [PERMISSIONS.customerCompanyView],
          commonState.currentCompanyDetails,
          'customer'
        )
      ) {
        if (
          (decodedToken.company_level === COMPANY_LEVELS.master ||
            decodedToken.company_level === COMPANY_LEVELS.serviceprovider) &&
          isResellerid != null &&
          decodedToken.company_status == COMPANY_STATUS.Active
        ) {
          getCustomers(isResellerid, config.headers)
        } else if (
          decodedToken.company_level === COMPANY_LEVELS.reseller &&
          decodedToken.company_status == COMPANY_STATUS.Active
        ) {
          getDirectCustomers(decodedToken.company_id, config.headers)
        } else {
          navigate(-1)
        }
      }
    } else {
      navigate('./login')
    }
  }

  const customerValidation = () => {
    if (localToken) {
      clearShowCompanies('services')
      if (
        !checkPermission(
          [PERMISSIONS.customerCompanyView],
          commonState.currentCompanyDetails,
          'customer'
        )
      )
        navigate(-1)
    } else {
      navigate('/login')
    }
  }

  // service provider list function
  const AllServiceProviderFunction = async (headers) => {
    setloading(true)
    try {
      let res = await getAllServiceProvider()
      dispatch(setServiceProviderTrigger(''))
      if (res) {
        setloading(false)
        dispatch(setServiceProvidersList(res.data.result))
      }
    } catch (error) {
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  // reseller list function
  const showAllResellerFunction = async (isServiceProviderid, headers) => {
    setloading(true)
    try {
      await getAllResellers(isServiceProviderid, headers).then((res) => {
        setloading(false)
        dispatch(setResellerTrigger(''))
        dispatch(setResellersList(res.data.result))
      })
    } catch (error) {
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  // customer list function
  const AltigenCustomersFunction = async (headers) => {
    /* setting the state value as altigen customer because this is the same function for 
        direct masterLogin,service provider customer and for all the resellers customers */
    setloading(true)
    try {
      let res = await getAltigenCustomers(headers)
      if (res) {
        setloading(false)
        dispatch(setCustomerTrigger(''))
        dispatch(setAltigenCustomers(res.data.result))
      }
    } catch (error) {
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  // direct customer function
  const getDirectCustomers = async (decodedTokencompany_id, headers) => {
    setloading(true)
    try {
      await getAllcustomers(decodedTokencompany_id, headers).then((res) => {
        setloading(false)
        dispatch(setCustomerTrigger(''))
        dispatch(setDirectCustomersList(res.data.result))
      })
    } catch (error) {
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  // get customers for service provider if company level is 0

  const getCustomers = async (isServiceProviderid, headers) => {
    const decodedToken = decodeToken(localToken)
    setloading(true)
    try {
      await getAllcustomers(
        isServiceProviderid == null
          ? decodedToken.company_id
          : isServiceProviderid,
        headers
      ).then((res) => {
        setloading(false)
        dispatch(setCustomerTrigger(''))
        if (commonState.isDirectCustomer) {
          dispatch(setDirectCustomersList(res.data.result))
        } else {
          dispatch(setCustomersList(res.data.result))
        }
      })
    } catch (error) {
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  const RoutingWithSwitch = (arg) => {
    switch (arg) {
      case '/master':
        return masterValidation()

      case '/serviceprovider':
        return serviceProviderValidation()

      case '/reseller':
        return resellerValidation()

      case '/customer':
        return customerValidation()
    }
  }

  useEffect(() => {
    setfulldata({})
    if (
      state.isToggleServiceProvider ||
      state.isToggleReseller ||
      state.isToggleCustomer
    ) {
      if (state.companyLevelApproval == COMPANY_LEVELS.serviceprovider) {
        getSPcompanydetails()
      } else if (state.companyLevelApproval == COMPANY_LEVELS.reseller) {
        getresellercompanydetails()
      } else if (state.companyLevelApproval == COMPANY_LEVELS.customer) {
        getcustomercompanydetails()
      }
    }
    if (showServiceProvider && !state.isToggleCustomer) {
      if (
        commonState.currentCompanyDetails?.companyLevel ==
        COMPANY_LEVELS.reseller
      ) {
        clearShowCompanies('customer')
      } else setshowCustomer(false)
    }
  }, [
    state.isToggleServiceProvider,
    state.isToggleReseller,
    state.isToggleCustomer,
  ])

  // company status
  const [companystatus, setcompanystatus] = useState('')

  const [accountdetailsdata, setaccountdetailsdata] = useState({})
  const [companydetails, setcompanydetails] = useState({})
  const [billingaddress, setbillingaddress] = useState({})
  const [billingcontact, setbillingcontact] = useState({})
  const [billingtype, setbillingtype] = useState({})
  const [creditcard, setcreditcard] = useState({})
  const [logourl, setlogourl] = useState({})
  const [fulldata, setfulldata] = useState({})
  const [paymentmethod, setpaymentmethod] = useState({})

  const getSPcompanydetails = async () => {
    const data = await getspdetailsApproval(
      commonState.serviceProviderId,
      config
    )
    setfulldata(data.data.result)
    setpaymentmethod(data.data.result.paymentMethod)
    setcompanystatus(data.data.result.companyStatus)
    setaccountdetailsdata(data.data.result.accountDetail)
    setcompanydetails(data.data.result.companyDetail)
    setbillingaddress(data.data.result.billingAddress)
    setbillingcontact(data.data.result.billingContact)
    setbillingtype(data.data.result.billingType)
    setcreditcard(data.data.result.paymentMethod.creditCard)
    setlogourl(data.data.result.appearance)
  }

  const getresellercompanydetails = async () => {
    const data = await getresellerApproval(commonState.resellerId, config)
    setfulldata(data.data.result)
    setpaymentmethod(data.data.result.paymentMethod)
    setcompanystatus(data.data.result.companyStatus)
    setaccountdetailsdata(data.data.result.accountDetail)
    setcompanydetails(data.data.result.companyDetail)
    setbillingaddress(data.data.result.billingAddress)
    setbillingcontact(data.data.result.billingContact)
    setbillingtype(data.data.result.billingType)
    setcreditcard(data.data.result.paymentMethod.creditCard)
    setlogourl(data.data.result.appearance)
  }

  const getcustomercompanydetails = async () => {
    const data = await getcustomerApproval(commonState.customerId, config)
    setfulldata(data.data.result)
    setpaymentmethod(data.data.result.paymentMethod)
    setcompanystatus(data.data.result.companyStatus)
    setaccountdetailsdata(data.data.result.accountDetail)
    setcompanydetails(data.data.result.companyDetail)
    setbillingaddress(data.data.result.billingAddress)
    setbillingcontact(data.data.result.billingContact)
    setbillingtype(data.data.result.billingType)
    setcreditcard(data.data.result.paymentMethod.creditCard)
    setlogourl(data.data.result.appearance)
  }

  const role = {
    customer: 'customer',
    reseller: 'reseller',
    serviceprovider: 'serviceprovider',
  }

  const getMasterDetails = async () => {
    try {
      let res = await getCompanyProfile('master')
      dispatch(setCurrentCompanyDetails(res?.data?.result))
      dispatch(setCurrentCompanyLevel('MASTER'))
    } catch (error) {
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  const getServiceProviderDetails = async () => {
    try {
      let res = await getCompanyProfile('serviceprovider', gettingCompanyId())
      dispatch(setCurrentCompanyDetails(res?.data?.result))
      dispatch(setCurrentCompanyLevel('SERVICE_PROVIDER'))
    } catch (error) {
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  //Add new Company (toggle)
  const handleShowCompany = (e, name) => {
    setCurrentRole(name)
    setOpenDrawer(true)
  }

  // handle company row click
  const handleRowClick = (row, company, isDirectCustomer = false) => {
    dispatch(setCompanyLevelApproval(row?.companyLevel))
    switch (company) {
      case 'serviceprovider':
        setloading(true)
        if (row?.companyStatus === COMPANY_STATUS.ApprovalPending) {
          dispatch(setServiceProviderId(row?.id))
          dispatch(setIsToggleServiceProvider(true))
          clearShowCompanies('master')
          setloading(false)
        } else {
          clearShowCompanies('serviceprovider')
          dispatch(setCurrentCompanyDetails(row))
          dispatch(setServiceProviderTrigger('triggered'))
          navigate(`/serviceprovider?company_id=${row.id}`)
          setloading(false)
        }
        break
      case 'reseller':
        setloading(true)
        if (row?.companyStatus === COMPANY_STATUS.ApprovalPending) {
          dispatch(setResellerId(row?.id))
          dispatch(setIsToggleReseller(true))
          clearShowCompanies('serviceprovider')
          setIsCustomerApproval(false)
          setloading(false)
        } else {
          clearShowCompanies('customer')
          dispatch(setServiceProviderTrigger(''))
          navigate(`/reseller?company_id=${row.id}`)
          setloading(false)
        }
        break
      case 'customer':
        setloading(true)
        dispatch(setIsDirectCustomer(isDirectCustomer))
        if (row?.companyStatus === COMPANY_STATUS.ApprovalPending) {
          dispatch(setCustomerId(row?.id))
          dispatch(setIsToggleCustomer(true))
          clearShowCompanies('customer')
          setIsCustomerApproval(true)
          setloading(false)
        } else {
          clearShowCompanies('services')
          navigate(`/customer?company_id=${row.id}`)
          setloading(false)
        }
        break

      default:
        break
    }
  }

  const renderDashboardPages = () => {
    return (
      <Fragment>
        {showMaster &&
          checkPermission(
            [PERMISSIONS.serviceproviderCompanyView],
            commonState.currentCompanyDetails,
            'serviceprovider',
            'master'
          ) && (
            <>
              {state.isToggleServiceProvider ? (
                <Approvalcompany
                  level="serviceprovider"
                  upperLevel="master"
                  permissions={PERMISSIONS.serviceproviderCompanyUpdate}
                  fulldata={fulldata}
                  paymentMethod={paymentmethod}
                  role={role.serviceprovider}
                  spcompanyid={commonState.serviceProviderId}
                  accountdetailsdata={accountdetailsdata}
                  logourl={logourl}
                  creditcard={creditcard}
                  billingtype={billingtype}
                  billingcontact={billingcontact}
                  companydetails={companydetails}
                  companystatus={companystatus}
                  billingaddress={billingaddress}
                />
              ) : (
                //List of ServiceProviders (Master)
                <Master
                  onRowClick={(row) => handleRowClick(row, 'serviceprovider')}
                  handleShowCompany={handleShowCompany}
                />
              )}
            </>
          )}

        {showServiceProvider &&
          checkPermission(
            [PERMISSIONS.resellerCompanyView],
            commonState.currentCompanyDetails,
            'reseller',
            'serviceprovider'
          ) && (
            <>
              {state.isToggleReseller ? (
                <Approvalcompany
                  role={role.reseller}
                  level="reseller"
                  upperLevel="serviceprovider"
                  permissions={PERMISSIONS.resellerCompanyUpdate}
                  fulldata={fulldata}
                  paymentMethod={paymentmethod}
                  spcompanyid={commonState.resellerId}
                  accountdetailsdata={accountdetailsdata}
                  logourl={logourl}
                  creditcard={creditcard}
                  billingtype={billingtype}
                  billingcontact={billingcontact}
                  companydetails={companydetails}
                  companystatus={companystatus}
                  billingaddress={billingaddress}
                  showServiceProvider={setshowServiceProvider}
                />
              ) : (
                //List of Resellers and Direct Customers (Service Provider)
                <ServiceProvider
                  onRowClick={(row, name) => handleRowClick(row, name)}
                  handleShowCompany={handleShowCompany}
                />
              )}
            </>
          )}

        {showCustomer &&
          checkPermission(
            [PERMISSIONS.customerCompanyView],
            commonState.currentCompanyDetails,
            'customer'
          ) && (
            <>
              {state.isToggleCustomer ? (
                <Approvalcompany
                  level="customer"
                  permissions={PERMISSIONS.customerCompanyUpdate}
                  role={role.customer}
                  fulldata={fulldata}
                  paymentMethod={paymentmethod}
                  spcompanyid={commonState.customerId}
                  accountdetailsdata={accountdetailsdata}
                  logourl={logourl}
                  creditcard={creditcard}
                  billingtype={billingtype}
                  billingcontact={billingcontact}
                  companydetails={companydetails}
                  companystatus={companystatus}
                  billingaddress={billingaddress}
                  showServiceProvider={setshowServiceProvider}
                  isCustomerApproval={isCustomerApproval}
                  isDirectCustomer={state.isDirectCustomer}
                />
              ) : (
                //List of Customers Under Reseller and Direct Customers  (customer)
                !showServiceProvider && (
                  <Customer
                    onRowClick={(row, name) => handleRowClick(row, 'customer')}
                    handleShowCompany={handleShowCompany}
                  />
                )
              )}
            </>
          )}

        {/* List of Serives available for customer */}
        {showServices && <Services />}
      </Fragment>
    )
  }

  return (
    <HomeReduxContext.Provider value={{ dispatch, state }}>
      <Box sx={{ width: '100%' }}>
        {loading ? (
          <SkeletonTableLoader></SkeletonTableLoader>
        ) : (
          renderDashboardPages()
        )}
        <NewCompany
          open={openDrawer}
          setOpen={setOpenDrawer}
          role={currentRole}
        />
      </Box>
    </HomeReduxContext.Provider>
  )
}

export default Home
