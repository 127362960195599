import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
//
// export default function SkeletonLoader(props) {
//     // const [array, setArray] = React.useState();
//     const array = [];
//     const value = props?.value || 1;
//     for (let i = 0; i <  value; i++) {
//         array.push(i);
//     }
//     return (
//         <>
//             {array.map((dat) => {
//                 return (
//                     <Box>
//                         <Skeleton animation="wave" variant="rectangular" height={50} />
//                         <Skeleton animation="wave" />
//                         <Skeleton  animation={false} />
//                     </Box>
//                 );
//             })}
//         </>
//     );
// }

export default function SkeletonTableLoader(props) {
  // const [array, setArray] = React.useState();
  const array = []
  const value = props?.value || 8
  for (let i = 0; i < value; i++) {
    array.push(i)
  }
  return (
    <>
      {array.map((dat) => {
        return (
          <Box key={dat}>
            <Skeleton key={dat + 'ske'} animation="wave" />
          </Box>
        )
      })}
    </>
  )
}
