import Notificationbutton from './notification/Notification'
import ProfileMenu from './ProfileMenu'
import { Box, useTheme } from '@mui/material'
import HelpMenu from './HelpMenu'
import LevelToggler from '../../custom/level-toggler/LevelToggler'
import SettingsMenu from './SettingsMenu'
import Home from '../../../../pages/home/Home'
// import Header from './Header.jsx'

const NavBar = () => {
  const theme = useTheme()

  return (
    <div>
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          boxShadow:
            '0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          opacity: 1,
          color: 'white',
          position: 'sticky',
          top: '0px',
          zIndex: '1000',
          display: 'flex',
          justifyContent: 'flex-end',
          height: '62px',
          paddingLeft: '100px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '50px',
            alignItems: 'center',
          }}
        >
          {<LevelToggler />}
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box
          sx={{
            display: 'flex',
            height: '50px',
            alignItems: 'center',
            marginRight: '20px',
          }}
        >
          <Notificationbutton />
          {/* <SettingsMenu /> */}
          <HelpMenu />
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              paddingLeft: '5px',
              paddingRight: '10px',
            }}
          >
            <ProfileMenu />
          </div>
        </Box>
      </Box>
      {/* <div>
        <Box component="main" sx={{ paddingLeft: '42px' }}>
          <Home />
        </Box>
        {/* <Header /> 
      </div> */}
    </div>
  )
}

export default NavBar
