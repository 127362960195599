import React from 'react'

// css
import './login.css'

// components
import { Grid, Box } from '@mui/material'
import Commonlogin from '../../../common/components/auth/commonLogin'
import { useState } from 'react'
import ForgotPassword from '../forgot-password/ForgotPassword'
import Carousel from '../../../common/components/carousel/Carousel'

// url params
import { useLocation, useNavigate } from 'react-router-dom'
import ResetPassword from '../reset-password/ResetPassword'
import { useEffect } from 'react'

export default function Login(props) {
  const location = useLocation()
  const getLocation = location.pathname
  const navigate = useNavigate()
  const getURL = window.location.href

  const [changeLocation, setChangeLocation] = useState(true)
  useEffect(() => {
    // findsubdomain();
    if (getLocation == '/reset_password') {
      setChangeLocation(true)
    } else {
      setChangeLocation(false)
    }
  }, [getLocation])

  const [showforgotpasswordPage, setshowforgotpasswordPage] = useState(false)

  const changeforgetPassword = (params) => {
    setshowforgotpasswordPage(params)
  }
  const changeresetPassword = (parms) => {
    setChangeLocation(false)
  }

  return (
    <>
      <Grid container spacing={2}>
        {/* first half */}
        <Grid item md={7} sm={6}>
          <Box
            sx={{
              borderRadius: '20px',
              margin: '15px',
              backgroundColor: '#0984E3',
              height: '96vh',
              width: '80%',
            }}
          >
            <Carousel />
          </Box>
        </Grid>
        {/* second half */}
        {changeLocation ? (
          <Grid item md={5} sm={6} xs={12}>
            <ResetPassword changePage={changeresetPassword} />
          </Grid>
        ) : (
          <Grid item md={5} sm={6} xs={12}>
            {showforgotpasswordPage ? (
              <ForgotPassword changePage={changeforgetPassword} />
            ) : (
              <Commonlogin changePage={changeforgetPassword} />
            )}
          </Grid>
        )}
      </Grid>
    </>
  )
}
