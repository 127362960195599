import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { fetch, store } from '../../../api/utils/httpUtil';
import { useEffect, useState } from 'react';
import { isValidService, normalizeApiResponse } from '../../../helpers/HelperFunctions';
import IFrame from '../../../common/components/iframe/IFrame';
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader';
import { Grid, CircularProgress } from '@mui/material';
import Logo from '../../../assets/Altigen_logo_with_text.png'
import "./TermsAndConditions.css"
import ThemeButton from '../../../common/components/button/Button';
import DoneIcon from '@mui/icons-material/Done';
import { useToastContext } from '../../../App';

export default function TermsAndConditions() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const service = searchParams.get('service');
    const companyId = searchParams.get('companyId');
    const [content, setContent] = useState();
    const [contentLoading, setContentLoading] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [profileOnboardingId, setProfileOnboardingId] = useState(false);
    const [accepting, setAccepting] = useState(false);
    const { toast } = useToastContext()
    const navigate = useNavigate();

    const loadTermsAndConditions = async () => {
        try {
            setContentLoading(true);
            const res = await fetch("customer/TermsAndConditions", { service, companyId })
            setProfileOnboardingId(res.data.result.profileOnBoardingId)
            setContent(res.data.result.content);
            setContentLoaded(true);
        } catch (err) {
            setContent(err?.response?.data?.message || err?.message)
        } finally {
            setContentLoading(false)
        }
    }

    const acceptTermsAndConditions = async () => {
        try {
            setAccepting(true);
            await store("customer/TermsAndConditions/Accept", { companyId, profileOnboardingId });
            toast.showToast('Accepted Successfully');
            navigate('/login');
        } catch (error) {
            toast.showToast(
                normalizeApiResponse(error)?.message,
                'error'
            )
        } finally {
            setAccepting(false);
        }
    }

    useEffect(() => {
        loadTermsAndConditions();
    }, []);

    if (!service || !companyId || !isValidService(service)) return <Navigate to="/login" replace />
    return <Grid container direction="column" spacing={2} paddingX={10} height="100vh">
        <Grid item>
            <div className="logo_container">
                <img
                    src={Logo}
                    width="250px"
                    style={{ objectFit: 'cover' }}
                ></img>
            </div>
        </Grid>
        <Grid item xs={9}>
            {contentLoading ? <SkeletonTableLoader /> : content && <IFrame content={content} />}
        </Grid>
        <Grid item>
            <Grid item width="fit-content" style={{ float: "right" }}>
                <ThemeButton
                    text={'Accept'}
                    variant="contained"
                    size="medium"
                    style={{ height: '32px' }}
                    startIcon={<DoneIcon />}
                    disabled={!contentLoaded || contentLoading || accepting}
                    onClick={acceptTermsAndConditions}
                    endIcon={accepting && <CircularProgress
                        size={20}
                        sx={{ color: '#fff' }} />}
                ></ThemeButton>
            </Grid>
        </Grid>
    </Grid>
}