import React from 'react'
import { Drawer, Grid, IconButton, Tooltip, Typography } from '@mui/material'
//import CancelIcon from '@mui/icons-material/Cancel'
import {
  setCustomersList,
  setDirectCustomersList,
  setResellersList,
  setServiceProvidersList,
} from '../../../../store/slice/homeSlice'

import DrawerHeader from '../../drawer/DrawerHeader'
import { useHomeRedux } from '../../../../pages/home/Home'
import NewDomain from './NewDomain'

const NewCompany = (props) => {
  const { dispatch, state } = useHomeRedux()
  const role = props?.role?.toLowerCase()

  const updateAddedCompany = (name, newCompany) => {
    const _newCompany = {
      id: newCompany.companyId,
      name: newCompany.companyName,
      createdDateTime: new Date().toDateString(),
      ...newCompany,
    }

    switch (name) {
      case 'serviceprovider':
        const serviceProvidersList = [
          _newCompany,
          ...state.serviceProvidersList,
        ]
        dispatch(setServiceProvidersList(serviceProvidersList))
        break
      case 'reseller':
        const resellersList = [_newCompany, ...state.resellersList]
        dispatch(setResellersList(resellersList))
        break
      case 'customer':
        const customers = [_newCompany, ...state.customersList]
        dispatch(setCustomersList(customers))
        break
      case 'directcustomer':
        const directCustomers = [_newCompany, ...state.directCustomersList]
        dispatch(setDirectCustomersList(directCustomers))
        break

      default:
        break
    }
  }

  return (
    <Grid>
      <Drawer
        BackdropProps={{ invisible: true }}
        PaperProps={{
          sx: { width: '25%' },
        }}
        anchor={'right'}
        open={props?.open}
      >
        <DrawerHeader
          header={
            role == 'serviceprovider'
              ? 'Add New Service Provider'
              : role == 'reseller'
                ? 'Add New Reseller'
                : ['customer', 'directcustomer'].includes(role)
                  ? 'Add New Customer'
                  : null
          }
          onClick={() => props?.setOpen(false)}
        />
        <NewDomain
          parentid={props?.spcompanyid}
          currentRole={role}
          toggleDrawer={props?.setOpen}
          updateAddedCompany={updateAddedCompany}
          config={null}
        />
      </Drawer>
    </Grid>
  )
}

export default NewCompany
