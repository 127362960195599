import React from 'react'
import { Box, Typography, Card, Avatar, Grid } from '@mui/material'
import { styled } from '@mui/system'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import loginImage_1 from '../../../assets/login_img1.png'
import loginImage_2 from '../../../assets/login_img2.png'
import loginImage_3 from '../../../assets/login_img3.png'

import sipLogo from '../../../assets/sip-logo.png'
import maxucLogo from '../../../assets/maxuc-logo.png'
import drLogo from '../../../assets/dr-logo.png'

const backgrounds = [
  { imageUrl: loginImage_1 },
  { imageUrl: loginImage_2 },
  { imageUrl: loginImage_3 },
]

const Background = styled(Box)(({ imageUrl }) => ({
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  height: '92vh',
  justifyContent: 'center',
}))

const CardStyled = styled(Card)({
  margin: '5px',
  width: '395px',
  height: '80px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px',
  borderRadius: '16.5px',
  border: '2px solid rgba(157, 203, 238, 1)',
  boxShadow:
    '0px 1px 3px rgba(50, 104, 185, 0.2), 0px 2px 4px rgba(50, 104, 185, 0.26), 0px 0px 2px rgba(50, 104, 185, 0.14)',
  opacity: 1,
  backgroundColor: 'rgba(250, 250, 250, 1)',
})

const cardsData = [
  {
    id: 1,
    title: 'Microsoft Teams Solutions',
    subtitle: 'Dashboard and tool panel.',
  },
  {
    id: 2,
    title: 'MaxCloud UC',
    subtitle: 'Hosted UCaaS/CCaaS',
  },
  { id: 3, title: 'Telco SIP Services', subtitle: 'SIP Trunking Services' },
]

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  return (
    <div style={{ height: 'inherit' }}>
      <Slider {...settings}>
        {backgrounds.map((bg, index) => (
          <Background key={index} imageUrl={bg.imageUrl}>
            {/*Render Cards Infor for first background ONLY*/}
            <div
              style={{
                position: 'relative',
                margin: '15px',
                padding: '120px 212px',
              }}
            >
              {index === 0 &&
                cardsData.map((card, index) => (
                  <CardStyled key={index}>
                    <Grid container>
                      <Grid item>
                        <Avatar
                          sx={
                            card.id === 3
                              ? {
                                  borderRadius: 0,
                                  width: 70,
                                  height: 65,
                                  margin: '0 15px 0 0',
                                }
                              : {
                                  borderRadius: 0,
                                  width: 65,
                                  height: 65,
                                  margin: '0 15px 0 0',
                                }
                          }
                          src={
                            card.id === 1
                              ? sipLogo
                              : card.id === 2
                              ? maxucLogo
                              : drLogo
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6">{card.title}</Typography>
                        <Typography variant="body2">{card.subtitle}</Typography>
                      </Grid>
                    </Grid>
                  </CardStyled>
                ))}
            </div>
          </Background>
        ))}
      </Slider>
      <Box sx={{ position: 'relative', top: -200, marginLeft: '20px' }}>
        <Typography
          variant="LoginCarouselTitle"
          component="h1"
          gutterBottom
          noWrap
        >
          Single Vendor, Integrated Solutions.
        </Typography>
        <Typography
          variant="LoginCarouselSubtitle"
          component="h4"
          gutterBottom
          noWrap
        >
          Choose between Microsoft Teams Solutions or MaxCloud Hosted
          UCaaS/CCaaS.
        </Typography>
      </Box>
    </div>
  )
}

export default Carousel
