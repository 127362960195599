// material ui
import {
  Grid,
  Box,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
  Button,
} from '@mui/material'

// css
import './payment.css'

import visalogo from '../../../../../assets/VISA-Logo.png'
import mastercardlogo from '../../../../../assets/Mastercard-Logo.png'
import americanexpresslogo from '../../../../../assets/American-Express.png'
import nextbtn from '../../../../../assets/icons/Next Button.svg'
import backbtn from '../../../../../assets/icons/Back Button.svg'
import '../steppers.css'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import { Form, Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import {
  formDetailsState,
  setBankAccount,
  setCreditCardAction,
} from '../../../../../store/slice/stepperSlice'
import {
  bankAccountValidationYup,
  creditCardYup,
} from '../../../../../validations/SignupValidation'
import ReusableTextfield from '../../../../../common/components/textField/textField'

import {
  setPaymentType,
  StepBackward,
  StepForward,
} from '../../../../../store/slice/stepperSlice'
import { COMPANY_LEVELS } from '../../../../../helpers/Constants'

function StepperPayment({ companydraft }) {
  const dispatch = useDispatch(useDispatch)
  const paymenttype = (e) => {
    dispatch(setPaymentType(e.target.value))
  }
  const state = useSelector(formDetailsState)

  return (
    <Container>
      <Grid container>
        <FormControl>
          <div style={{ marginLeft: '10px' }}>
            <h3>Payment method</h3>
          </div>
          {/* <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={state.paymentType}
            defaultValue="card"
            style={{ marginTop: '-15px' }}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="card"
              control={<Radio onChange={paymenttype} />}
              label="Credit Card or Debit Card"
            />
            <FormControlLabel
              value='bank'
              control={<Radio onClick={paymenttype} />}
              label='ACH'
            />
          </RadioGroup> */}
        </FormControl>

        <Grid item md={24} sm={24} xs={24} style={{ marginTop: '-30px' }}>
          <Box className="stepper-title-containers">
            {state.paymentType == 'card' ? (
              <Formik
                initialValues={{ ...state.creditCard }}
                validationSchema={creditCardYup}
                onSubmit={(values) => {
                  dispatch(setCreditCardAction(values))
                  dispatch(StepForward())
                }}
                enableReinitialize={true}
              >
                {({ handleSubmit, values, errors, initialValues, dirty }) => (
                  <Form>
                    <Box className="stepper-border-style stepper-main-contianer1 stepper-box-shadow-style">
                      <Box className="stepper-payemnt-container-1">
                        <Box className="stepper-payemnt-contianer-2">
                          <span>Debit or credit card</span>
                          <span>
                            <img
                              src={visalogo}
                              alt="visa"
                              width="50px"
                              height="auto"
                            />
                            &emsp;
                            <img
                              src={mastercardlogo}
                              alt="master"
                              width="50px"
                              height="auto"
                            />
                            &emsp;
                            <img
                              src={americanexpresslogo}
                              alt="ame"
                              width="50px"
                              height="auto"
                            />
                          </span>
                        </Box>
                      </Box>

                      <Box
                        sx={{ m: '0px 20px 0px 20px' }}
                        className="reseller-billing-text-title"
                      >
                        <Grid container columnSpacing={2}>
                          <Grid item md={6} sm={6} xs={6}>
                            <ReusableTextfield
                              name="firstName"
                              id="First Name"
                              size="small"
                              required
                            />
                          </Grid>
                          <Grid item md={6} sm={6} xs={6}>
                            <ReusableTextfield
                              name="lastName"
                              id="Last Name"
                              size="small"
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box
                        sx={{ m: '0px 20px 0px 20px' }}
                        className="reseller-billing-text-title"
                      >
                        <ReusableTextfield
                          name="cardNumber"
                          id="Card Number"
                          size="small"
                          inputProps={{ minLength: 16, maxLength: 16 }}
                          type="number"
                          required
                        />
                      </Box>
                      <Box
                        sx={{ m: '0px 20px 20px 20px' }}
                        className="reseller-billing-text-title"
                      >
                        <Grid container spacing={2}>
                          <Grid item md={4} sm={4} xs={12}>
                            <ReusableTextfield
                              placeholder={'MM'}
                              inputProps={{
                                min: 1,
                                max: 12,
                              }}
                              name="expriationMonth"
                              id="Expiry Month"
                              size="small"
                              type="number"
                              required
                            />
                          </Grid>
                          <Grid item md={4} sm={4} xs={12}>
                            <ReusableTextfield
                              placeholder={'YYYY'}
                              inputProps={{
                                minLength: 4,
                                maxLength: 4,
                              }}
                              name="expirationYear"
                              id="Expiry Year"
                              size="small"
                              type="number"
                              required
                            />
                          </Grid>
                          <Grid item md={4} sm={4} xs={12}>
                            <ReusableTextfield
                              name="cvv"
                              id="cvv"
                              size="small"
                              type="number"
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Grid container justifyContent="space-between">
                      <Grid item md={6} sm={8} xs={8}>
                        <Stack direction="row" spacing={2}>
                          <img
                            src={backbtn}
                            className="btn-hover-effect"
                            height="92px"
                            onClick={() => {
                              dispatch(StepBackward())
                              dispatch(setCreditCardAction(values))
                            }}
                            alt="next button"
                          />
                          <img
                            src={nextbtn}
                            className="btn-hover-effect"
                            height="92px"
                            onClick={handleSubmit}
                            alt="next button"
                          />
                        </Stack>
                      </Grid>
                      <Grid item lg={4} md={8} sm={8} xs={8}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="right"
                          height="92px"
                          spacing={2}
                        >
                          <Button
                            variant="contained"
                            disabled={
                              (!dirty ||
                                (values.firstName === '' &&
                                  initialValues.firstName !== '') ||
                                (values.lastName === '' &&
                                  initialValues.lastName !== '') ||
                                (values.cardNumber === '' &&
                                  initialValues.cardNumber !== '') ||
                                (values.expriationMonth === '' &&
                                  initialValues.expriationMonth !== '') ||
                                (values.expirationYear === '' &&
                                  initialValues.expirationYear !== '') ||
                                (values.cvv === '' &&
                                  initialValues.cvv !== '')) &&
                              values.cardNumber?.length != 16
                            }
                            onClick={() => {
                              companydraft(true, values)
                              dispatch(setCreditCardAction(values))
                            }}
                          >
                            {' '}
                            <SaveAsIcon /> &nbsp; save for later
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            ) : (
              <Box>
                {/* ACH */}
                <Formik
                  initialValues={{ ...state.bankAccount }}
                  validationSchema={bankAccountValidationYup}
                  onSubmit={(values) => {
                    dispatch(setBankAccount(values))
                    dispatch(StepForward())
                  }}
                  enableReinitialize={true}
                >
                  {({
                    handleSubmit,
                    values,
                    initialValues,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    dirty,
                  }) => (
                    <Form>
                      <Box
                        sx={{
                          minHeight: '400px',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          flexDirection: 'column',
                        }}
                      >
                        <ReusableTextfield
                          name="accountName"
                          id="Account Name"
                          size="small"
                          required
                        />
                        <ReusableTextfield
                          name="accountNumber"
                          id="Account Number"
                          size="small"
                          required
                        />
                        <ReusableTextfield
                          name="routingNumber"
                          id="Routing Number"
                          size="small"
                          required
                        />
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl
                            fullWidth
                            error={errors.accountType && touched.accountType}
                          >
                            <label className={'stepper-label'}>
                              Account Type &nbsp;{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Select
                              sx={{ height: '40px' }}
                              name="accountType"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              value={values?.accountType}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="checking">checking</MenuItem>
                              <MenuItem value="saving">saving</MenuItem>
                            </Select>
                            <FormHelperText>
                              {errors.accountType &&
                                touched.accountType &&
                                errors.accountType}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                        <ReusableTextfield
                          name="bankName"
                          id="Bank Name"
                          size="small"
                          required
                        />
                        <ReusableTextfield
                          name="checkNumber"
                          id="Cheque Number"
                          size="small"
                          required
                        />
                      </Box>
                      <Grid container justifyContent="space-between">
                        <Grid item md={4} sm={8} xs={8}>
                          <Stack direction="row" spacing={2}>
                            <img
                              src={backbtn}
                              className="btn-hover-effect"
                              height="92px"
                              onClick={() => {
                                dispatch(setBankAccount(values))
                                // if (
                                //   state.companyLevel == COMPANY_LEVELS.serviceprovider &&
                                //   state.isRevParent
                                // ) {
                                //   dispatch(StepBackward())
                                // } else if (
                                //   (state.companyLevel == COMPANY_LEVELS.reseller ||
                                //     state.companyLevel == COMPANY_LEVELS.customer) &&
                                //   (state.isRevParent ||
                                //     state.altigenDirectCustomer)
                                // ) {
                                //   dispatch(StepBackward())
                                // } else {
                                //   dispatch(StepBackward())
                                // }
                                dispatch(StepBackward())
                              }}
                              alt="next button"
                            />
                            <img
                              src={nextbtn}
                              className="btn-hover-effect"
                              height="92px"
                              onClick={handleSubmit}
                              alt="next button"
                            />
                          </Stack>
                        </Grid>
                        <Grid item lg={4} md={8} sm={8} xs={8}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="right"
                            height="92px"
                            spacing={2}
                          >
                            <Button
                              variant="contained"
                              disabled={
                                !dirty ||
                                (values.accountName === '' &&
                                  initialValues.accountName !== '') ||
                                (values.accountNumber === '' &&
                                  initialValues.accountNumber !== '') ||
                                (values.routingNumber === '' &&
                                  initialValues.routingNumber !== '') ||
                                (values.accountType === '' &&
                                  initialValues.accountType !== '') ||
                                (values.bankName === '' &&
                                  initialValues.bankName !== '') ||
                                (values.checkNumber === '' &&
                                  initialValues.checkNumber !== '')
                              }
                              onClick={() => {
                                dispatch(setBankAccount(values))
                                companydraft(true, values)
                              }}
                            >
                              {' '}
                              <SaveAsIcon /> &nbsp; save for later
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default StepperPayment
