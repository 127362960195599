import { useEffect, useState } from 'react';
// mui
import { Grid, Box, Container, Paper, IconButton, Tooltip, Button } from '@mui/material';
import jwt_decode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom';
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import { masterNotificationTemplateGetListApi } from '../../../api/master/masterAPI';
import NotificationList from './components/NotificationList'
import { Add as AddIcon } from '@mui/icons-material'
import NotificationCreate from './components/NotificationCreate'
import ForwardIcon from '@mui/icons-material/Forward'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  setNotificationsList,
  settingsSliceState,
} from '../../../store/slice/settingsSlice'
import {
  checkPermission,
  getCompanyLevel,
  normalizeApiResponse,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'
import ThemeButton from '../../../common/components/button/Button'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux';

function SettingsNotification(props) {
  const { dispatch, state: commonState } = useCommonRedux();
  const { toast } = useToastContext();
  const [loader, setLoader] = useState(false)
  const [hideEdit, setHideEdit] = useState(false)
  const [update, setUpdate] = useState(false)
  const [rowsListFiltered, setRowsListFiltered] = useState([])
  const [detailsData, setDetailsData] = useState()
  const [searchParams] = useSearchParams()
  const state = useSelector(settingsSliceState)

  const refreshData = (message) => {
    if (message) {
      toast.showToast(message?.data?.message, 'success')
    }
  }
  const getList = (id) => {
    if (!state.notificationsList) {
      setLoader(true)
      masterNotificationTemplateGetListApi(
        id,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          setLoader(false)
          response.data.result.forEach((dat, i) => {
            if (dat.sentDateTime) {
              let stillUtcSent = moment.utc(dat.sentDateTime).toDate()
              const sentDate = moment(stillUtcSent)
                .local()
                .format('YYYY-MM-DDTHH:mm:ss')
              dat.sentDateTime = sentDate
            }
            if (dat.scheduledDateTime) {
              let stillUtcScheduled = moment.utc(dat.scheduledDateTime).toDate()
              const scheduledDate = moment(stillUtcScheduled)
                .local()
                .format('YYYY-MM-DDTHH:mm:ss')
              dat.scheduledDateTime = scheduledDate
            }
          })
          // const sortedData = [...response.data.result].sort((a, b) => a.firstName.localeCompare(b.firstName));
          dispatch(setNotificationsList(response.data.result))
          setRowsListFiltered(response.data.result)
        })
        .catch((error) => {
          setLoader(false)
          toast.showToast(normalizeApiResponse(error)?.message, 'error')
        })
    }
  }
  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getList(companyId)
    }
  }

  const handleRowList = (value) => dispatch(setNotificationsList(value))

  useEffect(() => {
    getDecodeValue()
  }, [])
  return (
    <div className="Dash_main_container">
      <Container maxWidth="xl">
        <Box>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item md={12} sm={12} xs={12}>
              <Paper
                elevation={6}
                style={{ minHeight: '500px' }}
                className="paper1"
              >
                {loader ? (
                  <SkeletonTableLoader></SkeletonTableLoader>
                ) : (
                  <>
                    {checkPermission(
                      [PERMISSIONS.masterNotificationUpdate],
                      commonState.currentCompanyDetails
                    ) && hideEdit ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h2 style={{ margin: '10px 110px' }}>
                            {detailsData
                              ? ' Notification Details'
                              : 'Create Notification'}
                          </h2>
                          <IconButton
                            aria-label="back"
                            color="secondary"
                            size="large"
                            style={{
                              marginLeft: '2px',
                              marginTop: '5px',
                              float: 'right',
                              borderRadius: 0,
                            }}
                            onClick={() => {
                              setHideEdit(false)
                              setDetailsData(null)
                              getDecodeValue()
                            }}
                          >
                            <Button
                              type="button"
                              variant="text"
                              startIcon={
                                <ForwardIcon
                                  style={{ transform: 'rotate(180deg)' }}
                                />
                              }
                            >
                              Go to List
                            </Button>
                          </IconButton>
                        </div>
                        <NotificationCreate
                          detailsData={detailsData}
                          getDecodeValue={getDecodeValue}
                          setHideEdit={setHideEdit}
                        ></NotificationCreate>
                      </>
                    ) : (
                      <>
                        <Box
                          style={{ display: 'flex', justifyContent: 'right' }}
                        >
                          {checkPermission(
                            [PERMISSIONS.masterNotificationCreate],
                            commonState.currentCompanyDetails
                          ) ? (
                            <Tooltip title="Create notification">
                              <ThemeButton
                                onClick={() => {
                                  setDetailsData(null)
                                  setHideEdit(true)
                                }}
                                variant="outlined"
                                sx={{
                                  p: '10px',
                                  mb: '10px',
                                  mr: '10px',
                                  height: '32px',
                                }}
                                startIcon={<AddIcon />}
                                text="Create Notification"
                              ></ThemeButton>
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </Box>
                        {checkPermission(
                          [PERMISSIONS.masterNotificationView],
                          commonState.currentCompanyDetails
                        ) ? (
                          <NotificationList
                            setDetailsData={setDetailsData}
                            rowsList={state.notificationsList || []}
                            setRowsList={handleRowList}
                            rowsListFiltered={rowsListFiltered}
                            loader={loader}
                            setHideEdit={setHideEdit}
                            getDecodeValue={getDecodeValue}
                            refreshData={refreshData}
                          ></NotificationList>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default SettingsNotification
