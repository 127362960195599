import { useState, useEffect } from 'react'
import {
  Container,
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
  Button,
} from '@mui/material'

import nextbtn from '../../../../../assets/icons/Next Button.svg'
import backbtn from '../../../../../assets/icons/Back Button.svg'
import '../steppers.css'
import SaveAsIcon from '@mui/icons-material/SaveAs'
// css
import './billing.css'

import { getStates, getCountries } from '../../../../../api/get/getApi'
import ReusableTextfield from '../../../../../common/components/textField/textField'
import { Form, Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import {
  formDetailsState,
  resetBillingAddress,
  resetBillingInfo,
  setbillingInfo,
  StepBackward,
  StepForward,
} from '../../../../../store/slice/stepperSlice'
import { billingDetailsYup } from '../../../../../validations/SignupValidation'
import { styled } from '@mui/system'
import { SpaceBetweenBox } from '../../../../../common/styles/styled-components/StyledContainers'
import {
  BoldLabelText,
  TitleText,
} from '../../../../../common/styles/styled-components/StyledTypography'
import { ThemeSwitchLabel } from '../../../../../common/components/mui/Switch'
import {
  sanitizeExceptAlphabets,
  SanitizeSpecialCharacters,
} from '../../../../../helpers/HelperFunctions'

const StyledLabel = styled('label')(({ theme }) => ({
  color: theme.typography.Label.color,
  fontSize: theme.typography.Label.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'left',
  fontStyle: 'normal',
  letterSpacing: '-0.1px',
  marginBottom: '4px',
  marginTop: '4px',
}))

function ResellerBilling({ companydraft }) {
  // main state
  const state = useSelector(formDetailsState)
  const { billing } = useSelector(formDetailsState)
  const dispatch = useDispatch()
  const [isUseAccountDetail, setIsUseAccountDetail] = useState(true)
  const [isUseCompanyDetail, setIsUseCompanyDetail] = useState(true)

  useEffect(() => {
    getcountrylist()
  }, [])

  const [countrylist, setcountrylist] = useState([])
  const [statelist, setstatelist] = useState([])

  const getcountrylist = async () => {
    const data = await getCountries()
    setcountrylist(data.data.result)
    if (!billing?.country) {
      billing.country = 'USA'
      dispatch(setbillingInfo(billing))
      getStatebyCountryChange('USA')
    } else {
      getStatebyCountryChange('USA')
    }
  }

  const getStatesbyCountryCode = async () => {
    const data = await getStates(state.billing.country)
    setstatelist(data.data.result)
  }
  const getStatebyCountryChange = async (code) => {
    const data = await getStates(code)
    setstatelist(data.data.result)
  }
  const handleNumberInputChange = (event, field, setFieldValue) => {
    const inputValue = event.target.value
    const numericValue = inputValue.replace(/\D/g, '') // Remove non-numeric characters

    // Update the form field value with the numeric value
    setFieldValue(field, numericValue)
  }

  return (
    <Container>
      <Formik
        initialValues={{
          email: billing.email,
          firstName: billing.firstName,
          lastName: billing.lastName,
          mobile: billing.mobile,
          fax: billing.fax,
          phone: billing.phone,
          phoneExtension: billing.phoneExtension,
          companyName: billing.companyName,
          country: billing.country,
          state: billing.state,
          city: billing.city,
          zip: billing.zip,
          line1: billing.line1,
          line2: billing.line2,
        }}
        validationSchema={billingDetailsYup}
        onSubmit={(values) => {
          dispatch(StepForward())
          if (values) {
            dispatch(setbillingInfo(values))
          }
        }}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          initialValues,
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          resetForm,
          errors,
          touched,
          dirty,
        }) => (
          <Form>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Box sx={{ m: '30px 15px 15px 15px' }}>
                  <SpaceBetweenBox sx={{ gap: 2, pr: 2, alignItems: 'center' }}>
                    <BoldLabelText>Billing Info</BoldLabelText>
                    <ThemeSwitchLabel
                      checked={isUseAccountDetail}
                      label="Use Account Details"
                      handleToggle={(e, checked) => {
                        setIsUseAccountDetail(checked)
                        if (checked) {
                          resetForm({
                            values: {
                              email: state.accountDetail.email,
                              firstName: state.accountDetail.firstName,
                              lastName: state.accountDetail.lastName,
                              phone: state.accountDetail.phone,
                              phoneExtension: state.accountDetail.extNumber,
                            },
                          })
                          dispatch(
                            setbillingInfo({
                              ...billing,
                              email: state.accountDetail.email,
                              firstName: state.accountDetail.firstName,
                              lastName: state.accountDetail.lastName,
                              phone: state.accountDetail.phone,
                              phoneExtension: state.accountDetail.extNumber,
                            })
                          )
                        } else {
                          dispatch(resetBillingInfo())
                        }
                      }}
                    />
                  </SpaceBetweenBox>

                  <Box className="reseller-billing-container stepper-box-shadow-style">
                    <Box sx={{ m: '0px 20px 0px 20px' }}>
                      <ReusableTextfield
                        name="email"
                        size="small"
                        id="Email"
                        required
                      />
                    </Box>

                    <Box
                      sx={{ m: '0px 20px 0px 20px' }}
                      className="reseller-billing-text-title"
                    >
                      <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Grid container columnSpacing={2}>
                            <Grid item md={6} sm={6} xs={6}>
                              <ReusableTextfield
                                name="firstName"
                                size="small"
                                id="First Name"
                                required
                              />
                            </Grid>
                            <Grid item md={6} sm={6} xs={6}>
                              <ReusableTextfield
                                name="lastName"
                                size="small"
                                id="Last Name"
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid container columnSpacing={2}>
                            <Grid item md={6} sm={6} xs={6}>
                              <ReusableTextfield
                                name="mobile"
                                size="small"
                                id="Mobile"
                                InputProps={{
                                  inputProps: {
                                    minLength: 10,
                                    maxLength: 10,
                                  },
                                }}
                                value={values.mobile}
                                onChange={(e) =>
                                  handleNumberInputChange(
                                    e,
                                    'mobile',
                                    setFieldValue
                                  )
                                }
                              />
                            </Grid>
                            <Grid item md={6} sm={6} xs={6}>
                              <ReusableTextfield
                                name="fax"
                                size="small"
                                id="Fax"
                                InputProps={{
                                  inputProps: {
                                    minLength: 10,
                                    maxLength: 10,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{ m: '0px 20px 20px 20px' }}
                      className="reseller-billing-text-title"
                    >
                      <Grid container spacing={2}>
                        <Grid item md={10} sm={10} xs={10}>
                          <ReusableTextfield
                            name="phone"
                            size="small"
                            id="Phone Number"
                            InputProps={{
                              inputProps: {
                                minLength: 10,
                                maxLength: 10,
                              },
                            }}
                            value={values.phone}
                            onChange={(e) =>
                              handleNumberInputChange(e, 'phone', setFieldValue)
                            }
                            type="number"
                            required
                          />
                        </Grid>
                        <Grid item md={2} sm={2} xs={2}>
                          <ReusableTextfield
                            name="phoneExtension"
                            size="small"
                            id="EXT"
                            InputProps={{
                              inputProps: {
                                minLength: 20,
                                maxLength: 20,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>

                {/* billing address */}

                <Box sx={{ m: '30px 15px 15px 15px' }}>
                  <SpaceBetweenBox sx={{ gap: 2, pr: 2, alignItems: 'center' }}>
                    <BoldLabelText>Billing Address</BoldLabelText>
                    <ThemeSwitchLabel
                      checked={isUseCompanyDetail}
                      label="Use Company Address"
                      handleToggle={(e, checked) => {
                        setIsUseCompanyDetail(checked)
                        if (checked) {
                          resetForm({
                            values: {
                              city: state.companyDetail.city,
                              state: state.companyDetail.state,
                              country: state.companyDetail.country,
                              companyName: state.companyDetail.companyName,
                              line1: state.companyDetail.streetNumber,
                              line2: state.companyDetail.street,
                              zip: state.companyDetail.zip,
                            },
                          })
                          dispatch(
                            setbillingInfo({
                              ...billing,
                              city: state.companyDetail.city,
                              state: state.companyDetail.state,
                              country: state.companyDetail.country,
                              companyName: state.companyDetail.companyName,
                              line1: state.companyDetail.streetNumber,
                              line2: state.companyDetail.street,
                              zip: state.companyDetail.zip,
                            })
                          )
                        } else {
                          dispatch(resetBillingAddress())
                        }
                      }}
                    />
                  </SpaceBetweenBox>
                  <Box className="reseller-billing-address-container stepper-box-shadow-style">
                    <Box sx={{ mb: '20px' }}>
                      <Box
                        sx={{ m: '0px 20px 0px 20px' }}
                        className="reseller-billing-text-title"
                      >
                        <ReusableTextfield
                          name="companyName"
                          id="Billing Company Name"
                          size="small"
                          required
                        />
                      </Box>
                      <Box
                        sx={{ m: '0px 20px 0px 20px' }}
                        className="reseller-billing-text-title"
                      >
                        <Grid container spacing={2}>
                          <Grid item md={6} sm={6} xs={6}>
                            <ReusableTextfield
                              name="line1"
                              id="Address Line 1"
                              required
                              size="small"
                              onChange={(e) => {
                                setFieldValue(
                                  'line1',
                                  SanitizeSpecialCharacters(e.target.value)
                                )
                              }}
                            />
                          </Grid>
                          <Grid item md={6} sm={6} xs={6}>
                            <ReusableTextfield
                              name="line2"
                              id="Address Line 2 (Optional)"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box
                        sx={{ m: '0px 20px 0px 20px' }}
                        className="reseller-billing-text-title"
                      >
                        <Grid container spacing={2}>
                          <Grid item md={6} sm={6} xs={6}>
                            <ReusableTextfield
                              name="city"
                              size="small"
                              id="City"
                              required
                              onChange={(e) => {
                                setFieldValue(
                                  'city',
                                  sanitizeExceptAlphabets(e.target.value)
                                )
                              }}
                            />
                          </Grid>
                          <Grid item md={6} sm={6} xs={6}>
                            <FormControl
                              id="state"
                              fullWidth
                              error={touched.state && errors.state}
                              size="small"
                            >
                              <StyledLabel>
                                <label>
                                  State &nbsp;{' '}
                                  <span style={{ color: 'red' }}>*</span>
                                </label>
                              </StyledLabel>
                              <Select
                                error={false}
                                id="state"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                {statelist.map((data) => (
                                  <MenuItem value={data.code}>
                                    {data.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors.state && touched.state && errors.state}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        sx={{ m: '20px 20px 0px 20px' }}
                        className="reseller-billing-text-title"
                      >
                        <Grid container spacing={2}>
                          <Grid item md={8} sm={8} xs={8}>
                            <FormControl
                              id="country"
                              fullWidth
                              error={errors.country && touched.country}
                              size="small"
                            >
                              <StyledLabel>
                                <label>
                                  Country &nbsp;{' '}
                                  <span style={{ color: 'red' }}>*</span>
                                </label>
                              </StyledLabel>
                              <Select
                                required
                                defaultValue={'' || null}
                                id="country"
                                value={values.country}
                                name="country"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                {countrylist.map((data) => (
                                  <MenuItem
                                    value={data.code}
                                    onClick={() =>
                                      getStatebyCountryChange(data.code)
                                    }
                                  >
                                    {data.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors.country &&
                                  touched.country &&
                                  errors.country}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item md={4} sm={4} xs={4}>
                            <ReusableTextfield
                              name="zip"
                              size="small"
                              id="Zip"
                              type="number"
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ pl: '24px' }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems={'center'}
              >
                <Grid item md={8} sm={8} xs={8}>
                  <Stack direction="row" spacing={2}>
                    <img
                      src={backbtn}
                      className="btn-hover-effect"
                      height="92px"
                      onClick={() => {
                        dispatch(setbillingInfo(values))
                        dispatch(StepBackward())
                      }}
                      alt="next button"
                    />
                    <img
                      src={nextbtn}
                      className="btn-hover-effect"
                      height="92px"
                      onClick={handleSubmit}
                      alt="next button"
                    />
                  </Stack>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={
                      !dirty ||
                      (values.email === '' && initialValues.email !== '') ||
                      (values.firstName === '' &&
                        initialValues.firstName !== '') ||
                      (values.lastName === '' &&
                        initialValues.lastName !== '') ||
                      (values.mobile === '' && initialValues.mobile !== '') ||
                      (values.fax === '' && initialValues.fax !== '') ||
                      (values.phone === '' && initialValues.phone !== '') ||
                      (values.phoneExtension === '' &&
                        initialValues.phoneExtension !== '') ||
                      (values.companyName === '' &&
                        initialValues.companyName !== '') ||
                      (values.country === '' && initialValues.country !== '') ||
                      (values.state === '' && initialValues.state !== '') ||
                      (values.city === '' && initialValues.city !== '') ||
                      (values.zip === '' && initialValues.zip !== '') ||
                      (values.line1 === '' && initialValues.line1 !== '')
                    }
                    onClick={() => {
                      dispatch(setbillingInfo(values))
                      companydraft(true, values)
                    }}
                  >
                    <SaveAsIcon /> &nbsp; save for later
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default ResellerBilling
