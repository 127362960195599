import { createSlice } from '@reduxjs/toolkit'

//**INITIAL STATE */
const initialState = {
  usersList: null,
  rolesList: null,
}

export const userManagementSlice = createSlice({
  name: 'userManagementSlice',
  initialState,
  reducers: {
    setUsersList: (state, action) => {
      state.usersList = action.payload
    },
    setRolesList: (state, action) => {
      state.rolesList = action.payload
    },
  },
})

// export state as function
export const userManagementSliceState = (state) => state.userManagementSlice
// export actions

export const {
  setRolesList,
  setUsersList,
} = userManagementSlice.actions

export default userManagementSlice.reducer
