import { useState } from 'react'

// router
import { useSearchParams } from 'react-router-dom'

// css
import { styled } from '@mui/material/styles'

// logo
import Logo from '../../../assets/Altigen_logo_with_text.png'

// Icons
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import {
  Grid,
  Button,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'

import { Formik, Form, useFormik } from 'formik'
import ReusableTextfield from '../../../common/components/textField/textField'
import * as Yup from 'yup'

// api
import { resetPasswordApi } from '../../../api/post/postApi'
import { useToastContext } from '../../../App'
import { normalizeApiResponse } from '../../../helpers/HelperFunctions'

const StyledTitle = styled('span')(({ theme }) => ({
  color: theme.typography.Title.color,
  fontSize: theme.typography.Title.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  textAlign: 'left',
  lineHeight: '26px',
  fontStyle: 'normal',
  letterSpacing: '0px',
}))

const StyledSubTitle = styled('span')(({ theme }) => ({
  color: theme.typography.SubTitle.color,
  fontSize: theme.typography.SubTitle.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: 'left',
  lineHeight: '22px',
  fontStyle: 'normal',
  letterSpacing: '0px',
}))

const StyledPara = styled('span')(({ theme }) => ({
  color: theme.typography.Para.color,
  fontSize: theme.typography.Para.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: 'center',
  lineHeight: '22px',
  fontStyle: 'normal',
  letterSpacing: '0px',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

const initialValues = {
  newpassword: '',
  confirmpassword: '',
}

const FormValidation = Yup.object().shape({
  newpassword: Yup.string()
    .required('New password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Lowercase, One Number and One Special Case Character'
    ),
  confirmpassword: Yup.string()
    .required('Confirm password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Lowercase, One Number and One Special Case Character'
    )
    .test(
      'should-match-new-password',
      'Confirm password should match the new password',
      function (value) {
        return value == this.parent.newpassword
      }
    ),
})

function ResetPassword(props) {
  const { toast } = useToastContext()
  const [searchParams, setSearchparams] = useSearchParams()
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: FormValidation,
    onSubmit: (values) => {
      reset_password_function(values)
    },
  })

  const [showPassword, setshowPassword] = useState(false)
  const [showconfirmPassword, setshowconfirmPassword] = useState(false)
  const [loading, isLoading] = useState(false)

  //   const [pwdmessage, setPwdmessage] = useState('')
  //   const [progress, setProgress] = useState('')

  //   // Function to handle pasword length message
  //   const handlePasswordLength = (newPassword) => {
  //     // Create an object to track password strength checks
  //     //alert('hi')
  //   }

  //   // Function to get the color for the progress
  //   const getActiveColor = (type) => {
  //     if (type === 'Strong') return '#3fBB60'
  //     if (type === 'Medium') return '#FE804D'
  //     return '#FF0054'
  //   }

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  }
  const handleClickShowconfirmPassword = () => {
    setshowconfirmPassword(!showconfirmPassword)
  }

  const reset_password_function = (values) => {
    const geturlToken = searchParams.get('token')
    const Resetpwd = values.confirmpassword
    proceedResetfunction(geturlToken, Resetpwd)
  }

  const proceedResetfunction = async (geturlToken, Resetpwd) => {
    const data = {
      password: Resetpwd,
      token: geturlToken,
    }
    await resetPasswordApi(data)
      .then((response) => {
        const data = `${response.data.message} Try sign in`
        toast.showToast(data)
        setTimeout(() => {
          props.changePage(false)
        }, 3000)
      })
      .catch((error) => {
        toast.showToast(normalizeApiResponse(error)?.message, 'error')
      })
  }

  console.log('new', formik.errors.newpassword)
  console.log('confirm', formik.errors.confirmpassword)
  return (
    <div className="resetpassword_main_container">
      <div className="resetpassword_container">
        <form onSubmit={formik.handleSubmit}>
          <div className="textfield_container">
            <Grid container rowSpacing={{ lg: 3, md: 3, sm: 2, xs: 1 }}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="logo_container">
                  <img
                    src={Logo}
                    width="250px"
                    style={{ objectFit: 'cover' }}
                  ></img>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                  }}
                >
                  <StyledTitle>
                    <span>Reset Password</span>
                  </StyledTitle>
                  {/* //new text field */}
                  <TextField
                    name="newpassword"
                    value={formik.values.newpassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.newpassword)}
                    helperText={formik.errors.newpassword}
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <div
                    className="progress-bg"
                    style={{
                      width: progress,
                      backgroundColor: getActiveColor(pwdmessage),
                    }}
                  ></div>
                  {formik.values.newpassword.length !== 0 ? (
                    // Display password strength message when a password is entered
                    <p
                      className="pwdmessage"
                      style={{ color: getActiveColor(pwdmessage) }}
                    >
                      Your password is {pwdmessage}
                    </p>
                  ) : null} */}
                </Box>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="confirmpassword"
                  value={formik.values.confirmpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.confirmpassword)}
                  helperText={formik.errors.confirmpassword}
                  variant="outlined"
                  fullWidth
                  size="small"
                  label="Confirm Password"
                  type={showconfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowconfirmPassword}
                          edge="end"
                        >
                          {showconfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: '4px',
                      width: '110px',
                    }}
                  >
                    Submit &nbsp;{' '}
                    <CircularProgress
                      size="20px"
                      sx={{
                        color: '#fff',
                        display: loading ? 'block' : 'none',
                      }}
                    />
                  </Button>
                  <StyledPara>
                    <span onClick={() => props.changePage(false)}>
                      Already have an account? <b> Sign In</b>
                    </span>
                  </StyledPara>
                </Box>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
