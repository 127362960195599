import { Box, Grid, styled } from '@mui/material'

export const BorderBox = styled(Box)(({ theme }) => ({
  padding: '15px',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '5px',
  margin: '10px',
}))

export const StyledTableHeader = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.info.main}`,
  // background: theme.palette.grey[100],
  // color: theme.palette.primary.main,
  padding: '10px',
  borderRadius: '5px',
  alignItems: 'center',
  fontWeight: 'bold',
  textAlign: 'center',
}))

export const FlexBox = styled(Box)(({ theme, justify }) => ({
  display: 'flex',
  justifyContent: justify || 'start',
  alignItems: 'center',
}))

export const SpaceBetweenBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px',
}))

export const DisclaimerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'justify',
  backgroundColor: theme.palette.disclaimer.main,
  padding: '10px',
  color: theme.palette.disclaimer.text,
  margin: '8px 0',
  borderRadius: '6px',
}))
export const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'justify',
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '10px',
  color: theme.palette.disclaimer.text,
  margin: '8px 0',
  borderRadius: '6px',
}))
