import { Box, Grid, Container, Switch } from '@mui/material'
import * as React from 'react'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useLocation, useSearchParams } from 'react-router-dom'
import SkeletonTableLoader from '../../../../common/components/loaders/skeletonLoader'
import { masterConfigurationTabPostApi } from '../../../../api/master/masterAPI'
import {
  checkPermission,
  getCompanyLevel,
  normalizeApiResponse,
} from '../../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../../helpers/Constants'
import LoadingSection from '../../../../common/components/loaders/loader'
import { useToastContext } from '../../../../App'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'

export function MSRP(props) {
  const { toast } = useToastContext();
  const { dispatch, state: commonState } = useCommonRedux();
  const [loader, setLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [touched, setTouched] = React.useState(true)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const formik = useFormik({
    initialValues: {
      companyId: '',
      ShowMSRPToCustomer: props?.configData?.showMSRPToCustomer,
    },
    initialTouched: false,
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      masterConfigurationTabPostApi(
        data,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          setSubmitLoader(false)
          setShowEdit(false)
          props?.setHideEdit(false)
          props.refreshData(response)
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast( normalizeApiResponse(error)?.message, 'error')
        })
    },
  })

  const handleOnChangeConfiguration = (e) => {
    const data = formik.values
    setSubmitLoader(true)
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    let companyId = searchParams.get('company_id')
    if (!companyId) {
      companyId = decoded?.company_id
    }
    data.companyId = companyId
    data.ShowMSRPToCustomer = e
    masterConfigurationTabPostApi(
      data,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setSubmitLoader(false)
        setShowEdit(false)
        props?.setHideEdit(false)
        // props.refreshData(response);
      })
      .catch((error) => {
        setSubmitLoader(false)
        toast.showToast( normalizeApiResponse(error)?.message, 'error')
      })
  }

  const showEditButton = (e) => {
    if (e) {
      if (props?.configData?.ShowMSRPToCustomer) {
        formik.setFieldValue(
          'ShowMSRPToCustomer',
          props?.configData?.showMSRPToCustomer
        )
      }
    } else {
      formik.resetForm()
    }
    setShowEdit(e)
  }
  useEffect(() => {
    formik.errors = {}
  }, [])
  useEffect(() => {
    formik.setTouched({}, touched)
  }, [touched, formik?.errors])

  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Container>
        {loader ? (
          <div
            style={{
              padding: '5px 10px 0 20px',
            }}
          >
            <SkeletonTableLoader></SkeletonTableLoader>
          </div>
        ) : (
          <Grid container item>
            <Grid item md={10} sm={10} xs={10}>
              <form onSubmit={formik.handleSubmit}>
                <Grid item md={12} sm={12} xs={12}>
                  <Box className="stepper-input-label-margin">
                    <label className="stepper-label-style">
                      {location.pathname.slice(1) == 'serviceprovider'
                        ? 'Show MSRP To Direct Customer'
                        : 'Show MSRP To Customer'}
                    </label>
                    {checkPermission(
                      [
                        PERMISSIONS.masterSettingUpdate,
                        PERMISSIONS.serviceproviderSettingUpdate,
                        PERMISSIONS.resellerSettingUpdate,
                        PERMISSIONS.customerSettingUpdate,
                      ],
                      commonState.currentCompanyDetails
                    ) ? (
                      <Switch
                        // disabled={!showEdit}
                        checked={formik?.values?.ShowMSRPToCustomer}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'ShowMSRPToCustomer',
                            e.target.checked
                          )
                          handleOnChangeConfiguration(e.target.checked)
                        }}
                        value={formik?.values?.ShowMSRPToCustomer}
                      />
                    ) : (
                      ''
                    )}
                    {formik?.values?.ShowMSRPToCustomer
                      ? 'Show pricing'
                      : 'Hide pricing'}
                  </Box>
                </Grid>
              </form>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  )
}

export default MSRP