import React, { useEffect } from 'react'

// mui
import {
  Box,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  ListItem,
  List,
  ListItemText,
  Typography,
  Modal,
  Card,
  CardContent,
  DialogTitle,
  Badge,
  Zoom,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

// icons
import NotificationsIcon from '@mui/icons-material/Notifications'
import CircleIcon from '@mui/icons-material/Circle'

// external css
import './notificationbutton.css'
import {
  masterUserNotificationGetApi,
  masterUserNotificationGetByIdApi,
} from '../../../../../api/master/masterAPI'
import jwt_decode from 'jwt-decode'
import { useLocation, useSearchParams } from 'react-router-dom'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import CancelIcon from '@mui/icons-material/Cancel'
import { useDispatch, useSelector } from 'react-redux'
import {
  setNotifications,
  settingsSliceState,
} from '../../../../../store/slice/settingsSlice'
import {
  getCompanyLevel,
  normalizeApiResponse,
  removeDuplicates,
} from '../../../../../helpers/HelperFunctions'
import { useCommonRedux } from '../../../../../store/middlewares/CommonRedux'
import { useToastContext } from '../../../../../App'
// styles

const useStyles = makeStyles((theme) => ({
  title2: {
    fontSize: '2.0rem',
    fontWeight: '900',

    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem!important',
    },
  },
  notification_text_container: {
    fontSize: '14px',
    paddingLeft: '10px',

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
}))

export default function Notificationbutton() {
  const { dispatch, state: commonState } = useCommonRedux()
  const { toast } = useToastContext()
  const [anchorEl, setAnchorEl] = React.useState()
  const [openModal, setOpenModal] = React.useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpenModal(false)
  }

  const [details, setDetails] = React.useState('')
  const [loader, setLoader] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const settingState = useSelector(settingsSliceState)
  const getList = (id) => {
    if (
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      ) !== 'master'
    ) {
      masterUserNotificationGetApi(
        id,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          dispatch(setNotifications(removeDuplicates(response.data.result)))
        })
        .catch((error) => {
          setLoader(false)
          toast.showToast(normalizeApiResponse(error)?.message, 'error')
        })
    } else {
      dispatch(setNotifications([]))
    }
  }

  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    if (decodeToken) {
      const decoded = jwt_decode(decodeToken)
      if (decoded) {
        let companyId = searchParams.get('company_id')
        if (!companyId) {
          companyId = decoded?.company_id
        }
        getList(companyId)
      }
    }
  }
  const view = (item) => {
    handleClose()
    setDetails(item)
    setOpenModal(true)
    if (!item.isViewed) {
      masterUserNotificationGetByIdApi(
        item.id,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          const newData = [...settingState.notifications]
          newData.forEach((dat, ind) => {
            if (dat.id == item.id) {
              const modifiedObject = { ...dat, isViewed: true }
              newData[ind] = Object.assign({}, modifiedObject)
            }
          })
          dispatch(setNotifications(newData))
        })
        .catch((error) => {
          toast.showToast(normalizeApiResponse(error)?.message, 'error')
          setLoader(false)
        })
    }
  }

  useEffect(() => {
    getDecodeValue()
  }, [])

  const style = {
    width: 600,
    position: 'absolute',
    top: '5%',
    left: '35%',
    margin: '0 auto',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  }

  const classes = useStyles()

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Tooltip title="Notifications" TransitionComponent={Zoom}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{
              ml: 2,
              display: 'inline-flex',
              textAlign: 'left',
              color: '#2E384D',
              marginTop: '14px',
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge
              badgeContent={
                (settingState?.notifications || [])?.filter(
                  (dat) => !dat.isViewed
                )?.length
              }
              color="error"
            >
              <NotificationsIcon sx={{ color: '#2E384D' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
      >
        <Paper elevation={20}>
          <div className="notificationbtn_container">
            <div className="notificationbtn_title_container">
              <Box
                component="span"
                className={classes.title2}
                sx={{ pl: '20px' }}
              >
                Notifications
              </Box>
            </div>
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                padding: '0 5px',
              }}
              disablePadding
            >
              <nav aria-label="main mailbox folders">
                {removeDuplicates(settingState.notifications || []).map(
                  (item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        style={
                          item.isViewed ? {} : { backgroundColor: '#aaf4aa70' }
                        }
                        onClick={() => {
                          view(item)
                        }}
                      >
                        <ListItem alignItems="flex-start">
                          {item.isViewed ? (
                            <div className="success-notification-blank"></div>
                          ) : (
                            <CircleIcon
                              className="success-notification"
                              color="success"
                              sx={{ fontSize: '20px' }}
                            />
                          )}
                          <ListItemText
                            className={'text-cut'}
                            primary={item.subject}
                            secondary={
                              <React.Fragment>
                                <div
                                  style={{
                                    height: '35px',
                                    display: 'block',
                                    textOverflow: 'ellipsis',
                                    wordWrap: 'break-word',
                                    overflow: 'hidden',
                                    lineHeight: '20px',
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                ></div>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </MenuItem>
                    )
                  }
                )}
                {settingState.notifications?.length == 0 ? (
                  <div style={{ margin: '15px auto', textAlign: 'center' }}>
                    <CircleNotificationsIcon
                      sx={{ fontSize: '40px', color: 'green' }}
                    />
                    <div style={{ fontSize: '20px', color: 'grey' }}>
                      No Notification Here
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </nav>
            </List>
          </div>
        </Paper>
      </Menu>
      <Modal open={openModal}>
        <Box sx={style}>
          <Card>
            <DialogTitle>{details.subject} </DialogTitle>
            <Box position="absolute" top={0} right={0}>
              <Tooltip title="Close">
                <IconButton onClick={handleClose}>
                  <CancelIcon color="error" size="large"></CancelIcon>
                </IconButton>
              </Tooltip>
            </Box>
            <CardContent>
              <Typography
                style={{ padding: '0 10px', wordWrap: 'break-word' }}
                color="text.secondary"
              >
                <div
                  className="appendBody"
                  dangerouslySetInnerHTML={{ __html: details.body }}
                ></div>
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </React.Fragment>
  )
}
