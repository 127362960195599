import { destroy, fetch, patch, store } from '../utils/httpUtil'

export const paymentGetAllApi = (id, locationRoute) =>
  fetch(`${locationRoute}/PaymentAccount/${id}`)
export const paymentCardPostApi = (data, locationRoute) =>
  store(`${locationRoute}/PaymentAccount/card`, data)
export const paymentCardPatchApi = (data, obj, locationRoute) =>
  patch(
    `${locationRoute}/PaymentAccount/card/${obj.id}?companyId=${obj.companyId}`,
    data
  )
export const paymentBankPostApi = (data, locationRoute) =>
  store(`${locationRoute}/PaymentAccount/bank`, data)
export const paymentBankPatchApi = (data, obj, locationRoute) =>
  patch(
    `${locationRoute}/PaymentAccount/bank/${obj.id}?companyId=${obj.companyId}`,
    data
  )
export const paymentDeleteApi = (id, locationRoute) =>
  destroy(`${locationRoute}/PaymentAccount/${id}`)
