import {
  Avatar,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import CustomDataGrid from '../../../common/components/dataGrid/CustomDataGrid'
import { Add as AddIcon } from '@mui/icons-material'
import DashboardLayout from '../../../common/layouts/DashboardLayout'
import { styled } from '@mui/system'

import {
  setFilteredRows,
  setColumnFilters,
  setMenuItems,
  setServiceProvidersList,
} from '../../../store/slice/homeSlice'
import StatusChip from '../../../common/components/mui/Chips'
import { ServiceProviderColumn } from '../../../common/components/dataGrid/columns/HomeColumns'
import { getAllServiceProvider } from '../../../api/get/getApi'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { setAllProducts } from '../../../store/slice/commonSlice'
import {
  cleanArray,
  formatDate,
  formatDateAsFullMonth,
} from '../../../helpers/HelperFunctions'
import { useHomeRedux } from '../Home'
import { COMPANY_STATUS, COMPANY_STATUS_OBJ } from '../../../helpers/Constants'
import { useToastContext } from '../../../App'

const FlexContainer = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-evenly',
  alignItems: 'center',
})
const AnchorLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}))

const Master = (props) => {
  // hooks
  const { dispatch, state } = useHomeRedux()
  const { toast } = useToastContext()

  const [anchorEl, setAnchorEl] = useState({
    status: null,
    regDate: null,
    primaryContact: null,
  })
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState({
    status: false,
    regDate: false,
    primaryContact: false,
  })

  //lifeCycles
  useEffect(() => {
    dispatch(setAllProducts(null))
    state.searchValue?.serviceprovider && getFilteredRows()
    if (!state.serviceProvidersList) fetchServiceProvidersList()
  }, [state.searchValue?.serviceprovider, state?.serviceProvidersList])

  const fetchServiceProvidersList = async () => {
    const response = await getAllServiceProvider()
    const serviceProvidersList = response.data.result?.map((item) => ({
      ...item,
      createdDateTime: formatDate(new Date(item?.createdDateTime)),
    }))
    dispatch(setServiceProvidersList(serviceProvidersList))
  }

  const handleCopy = async (url) => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: 'clipboard-write',
      })

      if (
        permissionStatus.state === 'granted' ||
        permissionStatus.state === 'prompt'
      ) {
        await navigator.clipboard.writeText(url)
        toast.showToast('Text copied to clipboard successfully!')
      } else {
        toast.showToast('Clipboard write permission denied', 'error')
      }
    } catch (err) {
      toast.showToast(`Failed to copy text: ${url}`, 'error')
    }
  }

  const renderCompanyName = (row, value) => {
    return [COMPANY_STATUS.Active, COMPANY_STATUS.ApprovalPending].includes(row?.companyStatus) ? (
      <Typography variant="tablePrimary">
        <Link
          underline="none"
          color="primary"
          onClick={() => props?.onRowClick(row)}
        >
          {value}
        </Link>
        <Tooltip title="Copy Login Url">
          <IconButton onClick={() => handleCopy(row?.loginUrl)}>
            <ContentCopyIcon sx={{ fontSize: 14 }} />
          </IconButton>
        </Tooltip>
      </Typography>
    ) : (
      <Typography variant="tablePrimary">
        {value}&nbsp;
        <Tooltip title="Copy Signup Url">
          <IconButton onClick={() => handleCopy(row?.signupUrl)}>
            <ContentCopyIcon sx={{ fontSize: 14 }} />
          </IconButton>
        </Tooltip>
      </Typography>
    )
  }

  //render company
  const renderCompany = (params) => {
    const { row, value } = params
    return (
      <FlexContainer sx={{ gap: '1rem', justifyContent: 'center' }}>
        <Avatar alt="logo" src={row?.logoUrl} sx={{ width: 24, height: 24 }} />
        {renderCompanyName(row, value)}
      </FlexContainer>
    )
  }

  //Render Company Status
  const renderCompanyStatus = (statusId) => {
    const status = Object.keys(COMPANY_STATUS).find(
      (key) => COMPANY_STATUS[key] === statusId
    )

    return (
      <StatusChip
        label={statusId === COMPANY_STATUS.ApprovalPending ? 'Approval Pending' : status}
        variant="outlined"
        color={COMPANY_STATUS_OBJ[statusId].color}
        isSharp
      />
    )
  }

  //Filter in dataGrid
  const getFilteredRows = () => {
    const searchValue = state.searchValue?.serviceprovider?.toLowerCase()
    const filteredRows = state?.serviceProvidersList.filter(
      (row) =>
        row.name?.toLowerCase().includes(searchValue) ||
        row.resellerCount?.toString().includes(searchValue) ||
        row.customerCount?.toString().includes(searchValue) ||
        row.directCustomerCount?.toString().includes(searchValue) ||
        row.primaryContactEmail?.toLowerCase().includes(searchValue) ||
        formatDate(new Date(row.createdDateTime))?.includes(searchValue)
    )
    dispatch(setFilteredRows(filteredRows))
  }

  //close
  const handleClose = () => {
    setAnchorEl({ status: null, primaryContact: null, regDate: null })
    setOpen({ status: false, primaryContact: false, regDate: false })
  }

  //click
  const handleClick = (event, field) => {
    event.stopPropagation()
    if (field == 'companyStatus') {
      setAnchorEl((prev) => ({ ...prev, status: event.currentTarget }))
      setOpen((prev) => ({ ...prev, status: true }))
      const isColumnsFiltered = Object.values(state.columnFilters).some(
        (o) => o
      )
      const tempCompanyStatus = isColumnsFiltered
        ? state.menuItems
        : COMPANY_STATUS_OBJ.map((status) => ({
          ...status,
          checked: false,
        }))
      dispatch(setMenuItems(tempCompanyStatus))
    }
    if (field == 'primaryContactEmail') {
      setAnchorEl((prev) => ({ ...prev, primaryContact: event.currentTarget }))
      setOpen((prev) => ({ ...prev, primaryContact: true }))
      const isColumnsFiltered = Object.values(state.columnFilters).some(
        (o) => o
      )
      const tempPrimaryContacts = isColumnsFiltered
        ? state.menuItems
        : state?.serviceProvidersList.map((pc) => ({
          primaryContactEmail: pc.primaryContactEmail,
          checked: false,
        }))
      const uniquePrimaryContacts = Array.from(
        new Set(tempPrimaryContacts.map((obj) => obj.primaryContactEmail))
      ).map((pc) => {
        return tempPrimaryContacts.find((obj) => obj.primaryContactEmail === pc)
      })
      dispatch(setMenuItems(cleanArray(uniquePrimaryContacts)))
    }
    if (field == 'createdDateTime') {
      setAnchorEl((prev) => ({ ...prev, regDate: event.currentTarget }))
      setOpen((prev) => ({ ...prev, regDate: true }))
      const datesList = state?.serviceProvidersList?.map(
        (date) => date.createdDateTime
      )
      const uniqueDates = [
        ...new Set(datesList.map((dateTime) => dateTime.split('T')[0])),
      ]
      const dates = uniqueDates?.map((date) =>
        formatDateAsFullMonth(new Date(date))
      )
      dispatch(setMenuItems(dates))
    }
  }

  //filters
  const getSelectedFilters = (event, name, key, params) => {
    const { checked } = event.target
    if (checked != undefined) {
      const filters = [...state.menuItems]
      filters[key] = {
        ...filters[key],
        checked: checked,
      }
      const filteredItems = filters.filter((item) => item.checked)
      let filteredRows

      if (name === 'status') {
        filteredRows = state?.serviceProvidersList?.filter((row) =>
          filteredItems.some((item) => item.value == row.companyStatus)
        )
      }
      if (name === 'primaryContact') {
        filteredRows = state?.serviceProvidersList?.filter(
          (row) => params.primaryContactEmail == row.primaryContactEmail
        )
      }

      dispatch(setMenuItems(filters)) //set updated filters
      if (filteredItems.length) {
        dispatch(setColumnFilters({ ...state.columnFilters, status: true }))
        setRows(filteredRows)
      } else {
        dispatch(setColumnFilters({ ...state.columnFilters, status: false }))
        setRows(state?.serviceProvidersList)
      }
    }
  }

  const serviceProviderColumnProps = {
    renderFilter: () => { },
    renderCompany: renderCompany,
    renderCompanyStatus: renderCompanyStatus,
  }

  return (
    <>
      <Grid container>
        <DashboardLayout
          name="Service Provider"
          buttonStartIcon={<AddIcon />}
          // isButtonShow={false}
          handleButtonClick={(e) =>
            props?.handleShowCompany(e, 'serviceprovider')
          }
          children={
            <CustomDataGrid
              columns={ServiceProviderColumn(serviceProviderColumnProps)}
              data={state.serviceProvidersList || []}
              searchValue={state.searchValue?.serviceprovider}
              filteredRows={state.filteredRows}
              noDataText="Service Providers"
              loading={!state.serviceProvidersList}
            // onRowClick={({ row, value }) => {
            //   [companyStatus.Active, companyStatus.Approval].includes(row?.companyStatus) && props?.onRowClick(row)
            // }}
            />
          }
        />
      </Grid>
    </>
  )
}

export default Master
