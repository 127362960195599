import * as yup from 'yup'

export const ServiceCancellationValidationSchema = yup.object({
  dealerName: yup.string(),
  dealerID: yup.string(),
  customerName: yup.string(),
  dateOfServiceCancellation: yup.date(),
  isAcquired: yup.boolean().required("Please select 'Yes' or 'No'"),
  isGoingOutOfBusiness: yup.boolean().required("Please select 'Yes' or 'No'"),
  hasProofOfBusinessClosure: yup.boolean().when('isGoingOutOfBusiness', {
    is: true,
    then: yup.boolean().required("Please select 'Yes' or 'No'"),
    otherwise: yup.boolean().notRequired(),
  }),
  isSwitchingToCompetitor: yup
    .boolean()
    .required("Please select 'Yes' or 'No'"),
  isPriceReason: yup.boolean(),
  isFunctionalityReason: yup.boolean(),
  isTechnicalProblemsReason: yup.boolean(),
  acquiringCompanyProduct: yup.string().when('isAcquired', {
    is: true,
    then: yup.string().required('Acquiring Company Product is required'),
    otherwise: yup.string().notRequired(),
  }),
  proofDocumentation: yup.string().when('hasProofOfBusinessClosure', {
    is: true,
    then: yup.string().required('Proof is required'),
    otherwise: yup.string().notRequired(),
  }),
  proofDocumentation: yup.string().when('hasProofOfBusinessClosure', {
    is: true,
    then: yup.string().required('Proof is required'),
    otherwise: yup.string().notRequired(),
  }),
  competitorName: yup.string().when('isSwitchingToCompetitor', {
    is: true,
    then: yup.string().required('Competitor Name is required'),
    otherwise: yup.string().notRequired(),
  }),
  missingFunctionality: yup.string().when('isFunctionalityReason', {
    is: true,
    then: yup.string().required('Missing Functionality is required'),
    otherwise: yup.string().notRequired(),
  }),
  technicalProblems: yup.string().when('isTechnicalProblemsReason', {
    is: true,
    then: yup.string().required('Technical Problems is required'),
    otherwise: yup.string().notRequired(),
  }),
  customerSignature: yup.string().required('Customer Signature is required'),
})

// Define the validation schema for each host object
const hostSchema = yup.object().shape({
  address: yup.string().required('Address is required'),
  netMask: yup.string().required('NetMask is required'),
  error: yup.boolean().required('Error flag is required'),
})

export const serviceAddressSchema = yup.object().shape({
  sipAddressName: yup.string().required('Service Address Name is required'),
  address1: yup.string().required('Address Line 1 is required'),
  address2: yup.string().notRequired(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup
    .string()
    .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits')
    .required('Zip code is required'),
  country: yup.string().required('Country is required'),
  hosts: yup.array().of(hostSchema).min(1, 'At least one host is required'),
})

export const NumberFeatureCallerNameFormValidation = yup.object().shape({
  CallerName: yup
    .string()
    .required('Caller Name is required')
    .min(1, 'minlength 1')
    .max(15, 'Must be exactly 10 digits'),
})

export const NumberFeaturePSTNNumberFormValidation = yup.object().shape({
  PSTNForwarding: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
})
export const NumberFeatureBackupNumberFormValidation = yup.object().shape({
  PSTNBackup: yup
    .string()
    .required('PSTNBackup number is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
})

const US_PATTERN =
  /^(?:\+?(\w+))?[-.●]?\(?(\w{3})\)?[-.●]?(\w{3})[-.●]?(\w{4})$/i
const MobileNum = /^[1]?(\+[\d]{1,5}|0)?[1-9]\d{9}$/
export const DIDNUmberPortingFormValidation = yup.object().shape({
  BillingTel: yup
    .string()
    .required('Enter Billing Telephone Number')
    .matches(MobileNum, 'Please Enter Valid Number'),
  // LECMainBTN: yup.string()
  //     .required('Enter LECMainBTN').matches(US_PATTERN, 'Please Enter Valid Number').matches(/^[0-9]+$/, "Must be only digits"),
  FullPort: yup.boolean(),
  // NewLECBTN: yup.string().when('FullPort' ,{
  //     is: false,
  //     then: yup.string().required("Enter NewLECBTN").matches(/^[0-9]+$/, "Must be only digits").test('LECMainBTN', 'Number should not be same', function(value){
  //         return this.parent.LECMainBTN !== value
  //     })
  // }),
  CarrierName: yup.string().required('Enter Carrier Name'),
})

export const TollFreeNUmberPortingFormValidation = yup.object().shape({
  BillingTel: yup
    .string()
    .required('Enter Billing Telephone Number')
    .matches(MobileNum, 'Please Enter Valid Number'),
  CarrierName: yup.string().required('Enter Carrier Name'),
  // BillingAccountNumber: yup.string()
  //     .required('Enter Billing Account Number'),
})

export const NumberE911FormValidation = yup.object().shape({
  StreetAddress1: yup.string().required('Enter Street Number'),
  StreetAddress2: yup.string().required('Enter Street Address'),
  City: yup.string().required('Enter City'),
  State: yup.string().required('Select State'),
  PostalCode: yup.string().required('Enter Postal Code'),
});

export const NumberDynamicE911FormValidation = yup.object().shape({
  StreetAddress1: yup.string().required('Enter Street Number'),
  StreetAddress2: yup.string().required('Enter Street Address'),
  City: yup.string().required('Enter City'),
  State: yup.string().required('Select State'),
  PostalCode: yup.string().required('Enter Postal Code'),
  HeadOffice: yup.string().required('Enter Head Office or Distribution Center'),
  CallerName: yup.string().required('Enter Caller Name'),
})

export const StepOneFormValidation = yup.object().shape({
  RestrictionPlanId: yup.string(),
  RevProductId: yup.string().required('Select TeamsBundle Plan'),
})

export const NumberFeatureEditFormValidation = yup.object().shape({
  CallerName: yup.object().nullable(),
  PSTNForwarding: yup.object().nullable(),
  PSTNBackup: yup.object().nullable(),
})
