import { Chip, useTheme } from '@mui/material'
import React from 'react'
import { hexToRGBA } from '../../../helpers/HelperFunctions'

const StatusChip = ({ label, variant, color, isSharp, size = 'small' }) => {
  return (
    <Chip
      label={label}
      variant={variant}
      size={size}
      sx={{
        backgroundColor: hexToRGBA(color, 0.1),
        border: `1px solid ${color}`,
        borderRadius: isSharp && '4px',
        color: color,
        opacity: 1,
        width: '120px', //'max-content gives the maximum width of content inside chip'
        height: '25px',
      }}
    />
  )
}

export default StatusChip
