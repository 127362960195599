import { createSlice } from '@reduxjs/toolkit'

//**INITIAL STATE */
const initialState = {
  notifications: [],
  notificationsList: null,
  profileDetails: null,
  contactDetails: null,
  billingAddress: null,
  orderAddress: null,
  maxSipLocation: 1,

  configuration: null,
  isCurrentProductsUpdated: false,
  showMSRPToCustomer: false,
}

export const settingsSlice = createSlice({
  name: 'settingsSlice ',
  initialState,
  reducers: {
    setNotifications: (state, { payload }) => {
      state.notifications = payload
    },
    setNotificationsList: (state, { payload }) => {
      state.notificationsList = payload
    },
    setProfileDetails: (state, { payload }) => {
      state.profileDetails = payload
    },
    setContactDetails: (state, { payload }) => {
      state.contactDetails = payload
    },
    setBillingAddress: (state, { payload }) => {
      state.billingAddress = payload
    },
    setOrderAddress: (state, { payload }) => {
      state.orderAddress = payload
    },
    setMaxSipLocation: (state, { payload }) => {
      state.maxSipLocation = payload
    },
    setConfiguration: (state, { payload }) => {
      state.configuration = payload
    },
    setIsCurrentProductsUpdated: (state, { payload }) => {
      state.isCurrentProductsUpdated = payload
    },
    setShowMSRPToCustomer: (state, { payload }) => {
      state.showMSRPToCustomer = payload
    },

    //reset
    resetSettings: (state) => {
      state = initialState
    },
  },
})

// export state as function
export const settingsSliceState = (state) => state.settingsSlice
// export actions

export const {
  setNotifications,
  setOrderAddress,
  setNotificationsList,
  setProfileDetails,
  setContactDetails,
  setBillingAddress,
  setMaxSipLocation,
  setConfiguration,
  setIsCurrentProductsUpdated,
  setShowMSRPToCustomer,
  resetSettings,
} = settingsSlice.actions

export default settingsSlice.reducer
