import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ENV_URLS } from './Constants'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: ENV_URLS?.APPINSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: false,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
})
appInsights.loadAppInsights()

appInsights.addTelemetryInitializer((env) => {
  env.tags = env.tags || []
  env.tags['ai.cloud.role'] = 'app-sdc-frontend'
  //custom props
  env.data = env.data || {}
  env.data['appinstance'] = 'sdc-dev'
  //   env.data['user'] = '<frontend-auth-user>'
  //   env.data['ms-userid'] = '<frontend-auth-userid>'
})

export { reactPlugin, appInsights }
