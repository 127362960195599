// import logo from "./logo.svg";
import React, { createContext, useContext } from 'react'
// routes
import { Routes, Route } from 'react-router-dom'
// components
import { store } from './store/store'
import { Provider } from 'react-redux'
import { CommonReduxProvider } from './store/middlewares/CommonRedux'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from './helpers/AppInsights'
import NotFound from './common/components/custom/page-status/NotFound'
import UnAuthorised from './common/components/custom/page-status/UnAuthorised'
import Toast from './common/components/mui/Toast'
import SideNavBar from './common/components/navBar/sideNavBar/SideNavBar'
import LoadingSection from './common/components/loaders/loader'
import { AuthGuard, NavigateTo } from './common/components/auth/AuthGuard'
import NavAndHeaderLayout from './common/layouts/NavAndHeaderLayout'
import {
  history,
  HistoryRouter,
} from './common/components/router/HistoryRouter'
import useToast from './common/hooks/useToast'
import Login from './pages/auth/login/Login'
import Signup from './pages/auth/signup/Signup'
import StepperForm from './pages/auth/signup/signup-stepper-form/StepperForm'
import TermsAndConditions from './pages/auth/terms-and-conditions/TermsAndConditions'
import "../src/common/styles/global.css"

//asset imports
export { default as AltigenLogo } from './assets/altigen-logo.png'

const ToastContext = createContext(null)
export const useToastContext = () => useContext(ToastContext)

function App() {
  const {
    open,
    message,
    severity,
    showToast,
    handleClose,
    autoHideDuration,
    variant,
    position,
  } = useToast()
  const currentLevel = location.pathname.slice(1)

  //AUTH GUARD WRAPPER
  const authGuardWrapper = (level, component) => {
    return (
      <AuthGuard companyLevel={level}>
        <React.Suspense fallback={<LoadingSection></LoadingSection>}>
          {/* <NavAndHeaderLayout>{component}</NavAndHeaderLayout> */}
          <NavAndHeaderLayout>{<SideNavBar />}</NavAndHeaderLayout>
        </React.Suspense>
      </AuthGuard>
    )
  }

  //context provider
  const contextProvider = {
    toast: {
      open,
      message,
      severity,
      showToast,
      handleClose,
      autoHideDuration,
      variant,
      position,
    },
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Provider store={store}>
        <CommonReduxProvider>
          <ToastContext.Provider value={contextProvider}>
            <HistoryRouter history={history}>
              {/*<BrowserRouter>*/}

              <Routes>
                {/* login routes */}
                <Route path="/login" element={<Login />} />

                {/* forgot password route */}
                <Route path="/reset_password" element={<Login />}></Route>

                {/* signup paths */}
                <Route path="/signup" element={<Signup />}></Route>
                <Route path="/reseller/:id/signup" element={<Signup />}></Route>
                <Route path="/customer/:id/signup" element={<Signup />}></Route>
                <Route path="/signup-stepper" element={<StepperForm />}></Route>

                <Route path=":signUpId/dasboard" element={<></>} />

                <Route path="master" element={authGuardWrapper('master')} />
                {/* <Route
                path="master/settings"
                element={authGuardWrapper('master', <SettingsNew />)}
              /> */}

                <Route
                  path="serviceprovider"
                  element={authGuardWrapper('serviceprovider')}
                />

                <Route path="reseller" element={authGuardWrapper('reseller')} />

                <Route path="customer" element={authGuardWrapper('customer')} />

                <Route path="salesrep" element={authGuardWrapper('salesrep')} />

                <Route path="Reseller-detail-page" element={<></>} />
                <Route path="Reseller-detail-page" element={<></>} />
                <Route path="*" element={<NavigateTo />} />
                <Route path="/" element={<NavigateTo />} />
                <Route path="401" element={<UnAuthorised />} />
                <Route path="404" element={<NotFound />} />
                <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              </Routes>
              {/*</BrowserRouter>*/}
            </HistoryRouter>
            <Toast
              open={open}
              message={message}
              severity={severity}
              handleClose={handleClose}
              autoHideDuration={autoHideDuration}
              variant={variant}
              position={position}
            />
          </ToastContext.Provider>
        </CommonReduxProvider>
      </Provider>
    </AppInsightsContext.Provider>
  )
}

export default App
