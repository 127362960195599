import { customDateSort, formatDate } from '../../../../helpers/HelperFunctions'

export const ServiceProviderColumn = (props) => {
  return [
    {
      field: 'key',
      headerName: 'S.No',
      align: 'center',
      headerAlign: 'center',
      flex: 0.175,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Company Name',
      align: 'left',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => props.renderCompany(params),
    },
    {
      field: 'companyStatus',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      flex: 0.3,
      width:'400px',
      renderCell: ({ row, value }) => props.renderCompanyStatus(value),
      // renderHeader: (params) => props.renderFilter(params),
    },
    {
      field: 'resellerCount',
      headerName: 'Resellers',
      align: 'center',
      headerAlign: 'center',
      flex: 0.3,

      renderCell: ({ value }) => (value == 0 ? '' : value),
    },
    {
      field: 'customerCount',
      headerName: 'Customers',
      align: 'center',
      headerAlign: 'center',
      flex: 0.3,

      renderCell: ({ value }) => (value == 0 ? '' : value),
    },
    {
      field: 'directCustomerCount',
      headerName: 'Direct Customers',
      align: 'center',
      headerAlign: 'center',
      flex: 0.3,

      renderCell: ({ value }) => (value == 0 ? '' : value),
    },
    {
      field: 'createdDateTime',
      headerName: 'Registered Date',
      align: 'center',
      headerAlign: 'center',
      type: 'date',
      flex: 0.4,
      sortComparator: (a, b) => customDateSort(a, b),
    },
    {
      field: 'primaryContactEmail',
      headerName: 'Primary Contact',
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,

      // renderHeader: (params) => props.renderFilter(params),
    },
  ]
}

export const ResellersColumn = (props) => {
  return [
    {
      field: 'key',
      headerName: 'S.No',
      align: 'center',
      headerAlign: 'center',
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Company Name',
      align: 'left',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => props.renderCompany(params),
    },
    {
      field: 'companyStatus',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      flex: 0.4,
      renderCell: ({ row, value }) => props.renderCompanyStatus(value),
      // renderHeader: (params) => props.renderFilter(params),
    },
    {
      field: 'customerCount',
      headerName: 'Customers',
      align: 'center',
      headerAlign: 'center',
      flex: 0.3,
      renderCell: ({ value }) => (value == 0 ? '' : value),
    },
    {
      field: 'createdDateTime',
      headerName: 'Registered Date',
      align: 'center',
      headerAlign: 'center',
      flex: 0.4,
      sortComparator: (a, b) => customDateSort(a, b),
    },
    {
      field: 'primaryContactEmail',
      headerName: 'Primary Contact',
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      // renderHeader: (params) => props.renderFilter(params),
    },
  ]
}

export const ReferralAgentColumn = (props) => {
  return [
    {
      field: 'key',
      headerName: 'S.No',
      align: 'center',
      headerAlign: 'center',
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Company Name',
      align: 'left',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => props.renderCompany(params),
    },
    {
      field: 'companyStatus',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      flex: 0.4,
      renderCell: ({ row, value }) => props.renderCompanyStatus(value),
      renderHeader: (params) => props.renderFilter(params),
    },
    {
      field: 'createdDateTime',
      headerName: 'Registered Date',
      align: 'center',
      headerAlign: 'center',
      flex: 0.4,
      renderHeader: (params) => props.renderFilter(params),
      sortComparator: (a, b) => customDateSort(a, b),
    },
    {
      field: 'primaryContactEmail',
      headerName: 'Primary Contact',
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderHeader: (params) => props.renderFilter(params),
    },
  ]
}

export const CustomerColumn = (props) => {
  return [
    {
      field: 'key',
      headerName: 'S.No',
      align: 'center',
      headerAlign: 'center',
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Company Name',
      align: 'left',
      headerAlign: 'center',
      flex: 1,

      renderCell: (params) => props.renderCompany(params),
    },
    {
      field: 'companyStatus',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      flex: 0.4,

      renderCell: ({ row, value }) => props.renderCompanyStatus(value),
      // renderHeader: (params) => props.renderFilter(params),
    },
    {
      field: 'createdDateTime',
      headerName: 'Registered Date',
      align: 'center',
      headerAlign: 'center',
      flex: 0.4,
      sortComparator: (a, b) => customDateSort(a, b),
    },
    {
      field: 'primaryContactEmail',
      headerName: 'Primary Contact',
      align: 'center',
      headerAlign: 'center',

      flex: 0.4,
      // renderHeader: (params) => props.renderFilter(params),
    },
  ]
}
