//Mui
import { Box, IconButton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { homeSliceState, setSearchValue } from '../../../store/slice/homeSlice'


const SearchLayout = ({ role }) => {
  const state = useSelector(homeSliceState)
  const dispatch = useDispatch()
  const company = role.replaceAll(" ", "")?.toLowerCase();

  const handleSearch = (e) => {
    const searchValue = { ...state.searchValue }
    searchValue[`${company}`] = e.target.value;
    dispatch(setSearchValue(searchValue))
  }
  return (
    <Box>
      {/* <InputBase
          sx={{ ml: 1, flex: 1 }}
          type="text"
          value={state.searchValue}
          name="filterValue"
          placeholder="Search"
          size="medium"
          onChange={(e) => dispatch(setSearchValue(e.target.value))}
          inputProps={{ 'aria-label': 'search google maps' }}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton> */}
      <FormControl sx={{ my: 2, mx: 1, width: 400 }} size='small' variant="outlined" fullWidth>
        {/* <InputLabel htmlFor="search-label" sx={{ color: 'grey' }}>Search {company}</InputLabel> */}
        <OutlinedInput
          id="search-company-details"
          value={state.searchValue[company]}
          onChange={handleSearch}
          placeholder={`Search`}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="search-icon"
                onClick={() => { }}
                onMouseDown={() => { }}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  )
}

export default SearchLayout
