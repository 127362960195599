import { store } from '../utils/httpUtil'

// accout login api Common
export const commonLogin = (data) => store(`Account/login`, data)

// account login api SSO
export const ssoLogin = (data) => store(`Account/sso`, data)

// forgot password
export const forgotPassword = (data) => store(`Account/forgotpassword`, data)

//reset password
export const resetPasswordApi = (data) => store(`Account/resetpassword`, data)

// change password
export const changePassword = (data) => store(`Account/changepassword`, data)

// check sub domain
export const checkSubdomain = (data) => store(`Account/checksubdomain`, data)

// company draft user
export const companySignup = (data) => store(`CompanyDraft/user`, data)

// upload logo stepper form
export const uploadLogo = (data) => store(`CompanyDraft/logo`, data)

// company draft
export const CompanyDraft = (data) => store(`CompanyDraft`, data)

// reject or approve company
export const companyApproval = (data) => store(`ServiceProvider/approval`, data)

// add new service provider
export const addserviceprovider = (data) => store(`ServiceProvider`, data)

// add new reseller
export const addreseller = (data) => store(`Reseller`, data)

// add new customer
export const addcustomer = (data) => store(`Customer`, data)

// reject or approve reseller company
export const approveorrejectreseller = (data) =>
  store(`Reseller/approval`, data)

// reject  or approve customer company
export const approveorrejectcustomer = (data) =>
  store(`Customer/approval`, data)

// POST Configurations
export const postConfigurationSBCPrefix = (data) =>
  store(`Customer/configuration/sbcprefix`, data)

export const postConfigurationTenantInfo = (data) =>
  store(`Customer/configuration/tenantInfo`, data)

export const postAdminCredential = (data) =>
  store(`Customer/configuration/adminCredential`, data)

export const postServiceCancellation = (data) =>
  store(`ServiceCancellation/RequestServiceCancellation`, data)
