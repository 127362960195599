import { React, useState } from 'react'
import { Grid } from '@mui/material'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from 'recharts'
import DashboardCard from '../../../../common/components/cards/dashboardCard'
import moment from 'moment'
import SkeletonTableLoader from '../../../../common/components/loaders/skeletonLoader' // SkeletonTableLoader

/**
 * A React functional component that displays analytics for service providers.
 * It shows a line chart of reseller growth over time and total counts of resellers and customers.
 *
 * @return {JSX.Element} A Grid container with a line chart and dashboard cards.
 */
const ServiceProviderAnalytics = ({ resellersList, directCustomersList }) => {
  const [loading, setLoading] = useState(false)
  const resellers = resellersList ? resellersList : []
  const directCustomers = directCustomersList ? directCustomersList : []

  const resellerMonths =
    resellers.length > 0
      ? Array.from(
          new Set(
            resellers.map((item) => moment(item.createdDateTime).format('MMM'))
          )
        )
          .sort((a, b) => moment(a, 'MMM').diff(moment(b, 'MMM')))
          .map((month) => ({
            name: month,
            count: resellers.filter(
              (item) => moment(item.createdDateTime).format('MMM') === month
            ).length,
          }))
      : []

  const directCustomerMonths =
    directCustomers.length > 0
      ? Array.from(
          new Set(
            directCustomers.map((item) =>
              moment(item.createdDateTime).format('MMM')
            )
          )
        )
          .sort((a, b) => moment(a, 'MMM').diff(moment(b, 'MMM')))
          .map((month) => ({
            name: month,
            count: directCustomers.filter(
              (item) => moment(item.createdDateTime).format('MMM') === month
            ).length,
          }))
      : []

  return loading ? (
    <SkeletonTableLoader />
  ) : (
    <Grid container>
      <Grid item xs={12} sx={{ margin: '0px 50px 5px 5px' }}>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            {resellerMonths.length > 0 && (
              <ResponsiveContainer height="100%" width="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={resellerMonths}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#8884d8"
                    label={{ position: 'top' }}
                    name="Count of Resellers"
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </Grid>
          <Grid item xs={3}>
            {directCustomerMonths.length > 0 && (
              <ResponsiveContainer height="100%" width="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={directCustomerMonths}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#ED687A"
                    name="Count of Customers"
                    label={{ position: 'top' }}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </Grid>
          <Grid item xs={3}>
            <DashboardCard
              amount={resellers.length}
              subtitle="Total Resellers"
            />
          </Grid>
          <Grid item xs={3}>
            <DashboardCard
              amount={directCustomers.length}
              subtitle="Total Customers"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ServiceProviderAnalytics
