import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import App from '../../App'
import { LineWeight, Opacity } from '@mui/icons-material'

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      //main: '#4682b4', //pale-blue
      main: '#0984E3', //new-blue
    },
    secondary: {
      //main: '#54565a',
      main: '#FFFFFF',
    },
    warning: {
      main: '#ffeb3b',
    },
    info: {
      main: '#0288d1',
    },
    error: {
      // main: '#B00020',
      main: '#ff0000', //red
      contrastText: 'white', 
    },
    success: {
      main: '#00c853',
      contrastText: 'white', 
    },
    border: {
      main: '#E9E9E9',
    },
    disclaimer: {
      main: '#A0D6FFDD',
      text: '#61BAFFDD',
    },
  },
  spacing: 4,
  typography: {
    allVariants: {
      color: '#5D5D5D', // text color
    },
    fontFamily: 'mr-eaves-xl-modern-narrow',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    caption: {
      fontFamily: 'mr-eaves-xl-modern-narrow',
      fontWeight: 100,
      fontSize: '1.125rem',
      lineHeight: 1.66,
      letterSpacing: 0.044,
    },
    LoginCarouselTitle: {
      color: '#FFFFFF',
      fontSize: '42px',
      fontWeightBold: 700,
      lineHeight: '55px',
      Opacity: 1,
    },
    LoginCarouselSubtitle: {
      color: '#FFFFFF',
      fontWeight: 600,
      fontSize: '20px',
    },
    Title: {
      color: '#404965',
      fontWeight: 700,
      fontSize: '26px',
    },
    SubTitle: {
      color: '#9FA5BA',
      fontSize: '15px',
    },
    Label: {
      color: '#232333',
      fontSize: '12px',
      Opacity: 1,
    },
    Para: {
      color: '#2E384D',
      fontSize: '13px',
    },
    dialogHeader: {
      color: '#FFFFFF',
      fontSize: '36px',
      fontWeightMedium: 600,
      paddingLeft: '15px',
    },
    tablePrimary: {
      opacity: 1,
      color: '#404965',
      fontSize: '16px',
      fontWeight: 700,
      fontStyle: 'normal',
      letterSpacing: '0.46px',
      textAlign: 'left',
    },
    subtitle1: {
      fontSize: '1vw',
      fontWeight: 100,
      color: '#6a6a6a',
    },
    subtitle2: {
      fontSize: '0.8vw',
      fontWeight: 100,
      color: '#6a6a6a',
    },
    TabsTitle: {
      fontSize: '20px',
      fontWeight: 100,
      color: '#fff',
      textTransform: 'none',
    },
    Product: {
      fontSize: '0.8vw',
      fontWeight: 300,
      paddingTop: '0.5em',
    },
    Amount: {
      fontSize: '0.8vw',
      fontWeight: 300,
      paddingTop: '0.5em',
    },
    Amount1: {
      fontSize: '2vw',
      fontWeight: 900,
      paddingTop: '0.5em',
    },
    body1: {
      color: '#212121',
    },
    body2: {
      fontWeight: 100,
      color: '##3c3c3c',
    },
    label: {
      fontSize: '14px',
      fontWeight: 100,
      color: '##3c3c3c',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '&.Mui-disabled': {
            backgroundColor: 'rgba(243,243,243,0.85)',
            borderColor: 'rgba(243,243,243,0.85)',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: '0',

          background: 'transparent',
          color: 'rgba(0, 0, 0, 0.54)',
          '&.Mui-disabled': {
            border: '1px solid transparent',
          },
          '&.Mui-selected': {
            border: 'none',
            borderBottom: '4px solid #0984E3',
            background: 'transparent',
            borderRadius: '0',
          },
          '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderLeft: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&.MuiLink-root: hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important',
        },
      },
    },
  },
})

const ThemedApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  )
}

export default ThemedApp
