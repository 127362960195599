import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import React, { useEffect } from 'react';
import { useToastContext } from '../../../../App'
import { getProfile } from '../../../../api/get/getApi'
import { useFormik } from 'formik'
import { ProfileSettingsValidationSchema } from '../../../../validations/SettingsValidation'
import { editProfileData } from '../../../../api/put/putApi'
import jwt_decode from 'jwt-decode'
import {
  setIsProfileSettingsOpen,
  setLoggedInUser,
  setProfileSettings,
  setSkeletonLoading,
} from '../../../../store/slice/commonSlice'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import { normalizeApiResponse, requiredLabel } from '../../../../helpers/HelperFunctions'
import { ProfileSkeleton } from '../../loaders/skeletons/SettingsSkeleton'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}

const ProfileSettings = () => {
  //hooks
  const { dispatch, state } = useCommonRedux()
  const { toast } = useToastContext()
  const [buttonLoader, setButtonLoader] = React.useState(false)

  let userId = ''

  //formik
  const formikStates = {
    initialValues: initialValues,
    onSubmit: (values) => handleSubmitProfileSettings(values),
    validationSchema: ProfileSettingsValidationSchema,
  }
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue,
  } = useFormik(formikStates)

  useEffect(() => {
    state.isProfileSettingsOpen && getProfileSettingsAPI()
  }, [state.isProfileSettingsOpen])

  const getProfileSettingsAPI = async () => {
    try {
      dispatch(setSkeletonLoading(true))
      const localToken = localStorage.getItem('sd_cp.jwt:tkn')
      var config = { headers: { Authorization: `Bearer ${localToken}` } }
      const decodedToken = jwt_decode(localToken)
      userId = decodedToken.user_id
      const response = await getProfile(userId, config)
      dispatch(setProfileSettings(response.data.result))
      resetForm({
        values: {
          ...response.data.result,
          phoneNumber: response.data.result?.phone,
        },
      })
      dispatch(setSkeletonLoading(false))
    } catch (error) {
      dispatch(setSkeletonLoading(false))
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  //submit
  const handleSubmitProfileSettings = async (formFieldValues) => {
    try {
      setButtonLoader(true)
      const localToken = localStorage.getItem('sd_cp.jwt:tkn')
      var config = { headers: { Authorization: `Bearer ${localToken}` } }
      const decodedToken = jwt_decode(localToken)
      userId = decodedToken.user_id
      const request = {
        email: formFieldValues.email,
        firstName: formFieldValues.firstName,
        userId: userId,
        lastName: formFieldValues.lastName,
        phone: formFieldValues.phoneNumber,
      }
      dispatch(setProfileSettings(formFieldValues))
      const response = await editProfileData(request, config)
      if (response.status === 200) {
        setButtonLoader(false)
        dispatch(setIsProfileSettingsOpen(false))
        dispatch(
          setLoggedInUser({
            ...state.loggedInUser,
            firstName: formFieldValues.firstName,
            lastName: formFieldValues.lastName,
            phone: formFieldValues.phoneNumber,
          })
        )
        toast.showToast('Profile Updated Successfully')
      }
    } catch (error) {
      dispatch(setIsProfileSettingsOpen(false))
      setButtonLoader(false)
      toast.showToast( normalizeApiResponse(error)?.message, 'error')
    }
  }

  return (
    <Dialog open={state.isProfileSettingsOpen} maxWidth="sm">
      <DialogTitle>Profile Settings</DialogTitle>

      <form onSubmit={handleSubmit}>
        <Box position="absolute" top={0} right={0}>
          <Tooltip title="Close">
            <IconButton
              onClick={() => dispatch(setIsProfileSettingsOpen(false))}
            >
              <CancelIcon color="error" size="large"></CancelIcon>
            </IconButton>
          </Tooltip>
        </Box>

        <DialogContent>
          {state.isSkeletonLoading ? (
            <ProfileSkeleton noOfLines={4} width="500px" />
          ) : (
            <Grid container rowSpacing={1} columnSpacing={0}>
              <Grid item md={12} sm={12} xs={12}>
                {requiredLabel('First Name')}
                <TextField
                  placeholder="First Name"
                  defaultValue={state.profileSettings?.firstName}
                  value={values?.firstName}
                  onChange={(e) => setFieldValue('firstName', e.target.value)}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {requiredLabel('Last Name')}

                <TextField
                  defaultValue={state.profileSettings?.lastName}
                  value={values?.lastName}
                  onChange={(e) => setFieldValue('lastName', e.target.value)}
                  placeholder="Last Name"
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {requiredLabel('Email', false)}

                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    style: {
                      height: '35px',
                    },
                  }}
                  disabled
                  placeholder="Email"
                  defaultValue={state.profileSettings?.email}
                  value={values?.email}
                  onChange={handleChange}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {requiredLabel('Phone Number')}

                <TextField
                  defaultValue={Number(state.profileSettings?.phone)}
                  placeholder="Phone Number"
                  type="text"
                  value={values.phoneNumber}
                  onChange={(e) => {
                    if (
                      /^\d*$/.test(e.target.value) &&
                      e.target.value.length <= 10
                    ) {
                      setFieldValue('phoneNumber', e.target.value)
                    }
                  }}
                  id="phoneNumber"
                  fullWidth
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber}
                  InputProps={{
                    inputProps: {
                      maxLength: 10,
                    },
                    style: {
                      height: '35px',
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="contained"
              type="submit"
              disabled={state.isSkeletonLoading}
            >
              Update Profile
              {buttonLoader ? (
                <>
                  {' '}
                  &nbsp; <CircularProgress
                    size={20}
                    sx={{ color: '#fff' }}
                  />{' '}
                </>
              ) : null}
            </Button>{' '}
            &emsp;
            <Button
              variant="outlined"
              onClick={() => dispatch(setIsProfileSettingsOpen(false))}
            >
              cancel
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ProfileSettings
