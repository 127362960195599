import { Grid } from '@mui/material'
import NavBar from '../components/navBar/header/NavBar'

const NavAndHeaderLayout = ({ children }) => {
    return (
        <Grid>
            <NavBar />
            {children}
        </Grid>
    )
}

export default NavAndHeaderLayout
