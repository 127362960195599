import Box from '@mui/material/Box'
import { Button, Grid, List, TextField } from '@mui/material'
import * as React from 'react'
import { useFormik } from 'formik'
import { masterUserPutApi } from '../../../api/master/masterAPI'
import jwt_decode from 'jwt-decode'
import LoadingSection from '../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  getCompanyLevel,
  normalizeApiResponse,
  requiredLabel,
} from '../../../helpers/HelperFunctions'
import { EditUserFormValidation } from '../../../validations/UserManagementValidation';
import { useToastContext } from '../../../App'

export function EditUserDetails(props) {
  const { toast } = useToastContext();
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [visible, setVisible] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const formik = useFormik({
    initialValues: {
      id: props?.userId?.id,
      firstName: props?.userId?.firstName,
      lastName: props?.userId?.lastName,
      email: props?.userId?.email,
      phone: props?.userId?.phone || '-',
      roleIds: props?.userId?.roles,
      companyId: '',
      aadUserId: '',
      userStatus: props?.userId?.userStatus || 1,
    },
    validationSchema: EditUserFormValidation,
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      data.roleIds = props?.userId?.roles.map((rol) => rol.roleId)
      masterUserPutApi(
        data,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          toast.showToast(response.data.message, 'success')
          setVisible(true)
          setSubmitLoader(false)
          props.gotoDrawer('view')
          props.toggleDrawer('right', true, 'view', props.userId, true)
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast( normalizeApiResponse(error)?.message, 'error')
        })
    },
  })

  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Box role="presentation">
        <List>
          <div style={{ padding: '0px 30px' }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container columnSpacing={4} rowSpacing={3}>
                <Grid item md={6} sm={6} xs={6}>
                  {requiredLabel('FirstName')}
                  <TextField
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                  />
                  <div style={{ color: 'red', position: 'absolute' }}>
                    {formik.errors.firstName ? formik.errors.firstName : null}
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  {requiredLabel('LastName')}
                  <TextField
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                  />
                  <div style={{ color: 'red', position: 'absolute' }}>
                    {formik.errors.lastName ? formik.errors.lastName : null}
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  {requiredLabel('Email')}
                  <TextField
                    id="email"
                    name="email"
                    disabled={true}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                  />
                  <div style={{ color: 'red', position: 'absolute' }}>
                    {formik.errors.email ? formik.errors.email : null}
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  {requiredLabel('Phone Number')}
                  <TextField
                    id="phone"
                    name="phone"
                    type="number"
                    onChange={(e) => {
                      e.target.value?.length <= 10 &&
                        formik.setFieldValue('phone', e.target.value)
                    }}
                    value={formik.values.phone}
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                  />
                  <div style={{ color: 'red', position: 'absolute' }}>
                    {formik.errors.phone ? formik.errors.phone : null}
                  </div>
                </Grid>
              </Grid>
              <Box
                style={{
                  position: 'fixed',
                  bottom: '10px',
                  background: '#fff',
                  display: 'flex',
                }}
              >
                <Button
                  disabled={!(formik.isValid || formik.dirty)}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="large"
                >
                  Update User Details
                </Button>
                <div style={{ marginLeft: '20px' }}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      props.toggleDrawer('right', false, '', '', false)
                    }
                    disableElevation
                    size="large"
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            </form>
          </div>
        </List>
      </Box>
    </>
  )
}

export default EditUserDetails
