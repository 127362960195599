import { useEffect, useMemo, useRef, useState } from 'react'
// mui
import { Grid, Box, Container, Paper, IconButton, Tooltip } from '@mui/material'
import AdminCred from './components/AdminCred'
import SBCPrefix from './components/SbcPrefix'
import TenantInfo from './components/TenantInfo'
import jwt_decode from 'jwt-decode'
import { getCustomerConfiguration } from '../../../api/get/getApi'
import { useSearchParams } from 'react-router-dom'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import GppGoodIcon from '@mui/icons-material/GppGood'
import ConfirmationModal from '../../../common/components/custom/modal/modal'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import SIPTrunkConfig from './components/SIPTrunkConfig'
import { checkPermission, normalizeApiResponse } from '../../../helpers/HelperFunctions'
import { PERMISSIONS, PRODUCT_CODES } from '../../../helpers/Constants'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'

function ConfigurationPage(props) {
  const { dispatch, state: commonState } = useCommonRedux();
  const { toast } = useToastContext();
  const [loader, setLoader] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hideEdit, setHideEdit] = useState(false)
  const [configData, setConfigData] = useState()
  const [searchParams] = useSearchParams()
  const [copySuccess, setCopySuccess] = useState('Copy Url')
  const textAreaRef = useRef(null)
  const getCustomerConfigApi = (id) => {
    if (id) {
      setLoader(true)
      getCustomerConfiguration(id)
        .then((response) => {
          setLoader(false)
          if (response.data.result) {
            setConfigData(response.data.result)
            // navigator.clipboard.writeText();
            setCopySuccess('Copy Url')
          }
        })
        .catch((error) => {
          setLoader(false)
          toast.showToast(normalizeApiResponse(error)?.message, 'error')
        })
    }
  }
  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getCustomerConfigApi(companyId)
    }
  }

  const refreshData = (message) => {
    if (message) {
      toast.showToast(message?.data?.message, 'success')
      getDecodeValue()
    }
  }
  const handleGrantPermission = () => {
    if (configData.consentUrl) {
      window.open(configData.consentUrl, '_blank')
    } else {
      setIsModalOpen(true)
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }
  const handleCopy = (e) => {
    navigator.clipboard.writeText(configData.consentUrl)
    setCopySuccess('Copied')
  }
  useEffect(() => {
    getDecodeValue()
  }, []);

  const hasSIPTrunking = useMemo(() => {
    return commonState.currentCompanyDetails?.products.find(x => x.productCode === PRODUCT_CODES.sipTrunking);
  }, [commonState.currentCompanyDetails])

  return (
    <div className="Dash_main_container">
      <Container maxWidth="xl">
        <Box>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item md={6} sm={6} xs={6}>
              <Paper
                elevation={6}
                style={{ minHeight: '500px' }}
                className="paper1"
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 0 0 20px',
                  }}
                >
                  <div style={{ fontSize: '16px', fontWeight: '600' }}>
                    Configuration
                  </div>
                  {checkPermission(
                    [
                      PERMISSIONS.masterSettingUpdate,
                      PERMISSIONS.serviceproviderSettingUpdate,
                      PERMISSIONS.resellerSettingUpdate,
                      PERMISSIONS.customerSettingUpdate,
                    ],
                    commonState.currentCompanyDetails
                  ) ? (
                    <div>
                      <Tooltip title="Grant Permission">
                        <IconButton onClick={handleGrantPermission}>
                          <GppGoodIcon
                            color={
                              configData?.isAppConsented ? 'success' : 'default'
                            }
                          ></GppGoodIcon>
                        </IconButton>
                      </Tooltip>
                      {configData?.consentUrl ? (
                        <Tooltip title={copySuccess}>
                          <IconButton onClick={handleCopy}>
                            <ContentCopyIcon
                              style={{ fontSize: '18px' }}
                            ></ContentCopyIcon>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {loader ? (
                  <SkeletonTableLoader></SkeletonTableLoader>
                ) : (
                  <>
                    <SBCPrefix
                      configData={configData}
                      refreshData={refreshData}
                      hideEdit={hideEdit}
                      setHideEdit={setHideEdit}
                    ></SBCPrefix>
                    <AdminCred
                      configData={configData}
                      refreshData={refreshData}
                      hideEdit={hideEdit}
                      setHideEdit={setHideEdit}
                    ></AdminCred>
                    <TenantInfo
                      configData={configData}
                      refreshData={refreshData}
                      hideEdit={hideEdit}
                      setHideEdit={setHideEdit}
                    ></TenantInfo>
                    {hasSIPTrunking && <SIPTrunkConfig
                      configData={configData}
                      refreshData={refreshData}
                      hideEdit={hideEdit}
                      setHideEdit={setHideEdit}
                    />}
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ConfirmationModal
        title="Info"
        content={`Please set your Azure AD Tenant Id to grant admin consent!`}
        isOpen={isModalOpen}
        hiddenConform={true}
        handleClose={handleClose}
      />
    </div>
  )
}
export default ConfigurationPage
