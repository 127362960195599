import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  MenuItem,
  Select,
  Tab,
  CircularProgress,
  Container,
  Tooltip,
} from '@mui/material'
import * as React from 'react'
import { masterDetailsPutApi } from '../../../../api/master/masterAPI'
import { useEffect } from 'react'
import { getStates } from '../../../../api/get/getApi'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { useFormik } from 'formik'
import { useLocation, useSearchParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useState } from 'react'

// mui icons
import BackupIcon from '@mui/icons-material/Backup'

// css
import '../../../auth/signup/components/appearance/appearance.css'

import { uploadLogo } from '../../../../api/post/postApi'
import {
  setCurrentCompanyDetails,
  setLogoURL,
  setSkeletonLoading,
  setStatesList,
} from '../../../../store/slice/commonSlice'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import {
  checkPermission,
  getCompanyLevel,
  normalizeApiResponse,
  sanitizeExceptAlphabets,
  SanitizeSpecialCharacters,
} from '../../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../../helpers/Constants'
import { CompanyProfileValidations } from '../../../../validations/SettingsValidation'
import { useToastContext } from '../../../../App'
import { useSettingsRedux } from '../../Settings'

export function MasterProfile(props) {
  const { dispatch, state } = useCommonRedux()
  const { toast } = useToastContext()
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: '',
      name: '',
      companyId: '',
      country: '',
      state: '',
      city: '',
      street: '',
      streetNumber: '',
      phone: '',
      fax: '',
      domain: '',
      zipCode: '',
      parentName: '',
      companyStatus: 1,
    },
    validationSchema: CompanyProfileValidations,
    onSubmit: (data) => {
      setSubmitLoader(true)
      data.companyId = state.companyId
      masterDetailsPutApi(
        data,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          setSubmitLoader(false)
          setShowEdit(false)
          toast.showToast(response.data.message, 'success')
          getDecodeValue()
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast(normalizeApiResponse(error)?.message, 'error')
        })
    },
  })

  useEffect(() => {
    updateFormDetails()
  }, [state.currentCompanyDetails])

  const updateFormDetails = async () => {
    const details = state.currentCompanyDetails
    formik.setFieldValue('id', details?.id)
    formik.setFieldValue('name', details?.name)
    formik.setFieldValue('companyId', details?.companyId)
    formik.setFieldValue('country', details?.country)
    formik.setFieldValue('state', details?.state)
    formik.setFieldValue('city', details?.city)
    formik.setFieldValue('street', details?.street)
    formik.setFieldValue('streetNumber', details?.streetNumber)
    formik.setFieldValue('phone', details?.phone)
    formik.setFieldValue('fax', details?.fax)
    formik.setFieldValue('domain', details?.domain)
    formik.setFieldValue('parentName', details?.parentName)
    formik.setFieldValue('zipCode', details?.zipCode)
  }

  const showEditButton = (isEdit) => {
    !isEdit && updateFormDetails()
    setShowEdit(isEdit)
  }

  const handleChange = (event) => {
    formik.setFieldValue('country', event.target.value)
  }

  const updateStatesList = async () => {
    const statesResponse = await getStates(formik.values.country)
    dispatch(setStatesList(statesResponse.data.result))
  }

  const [value, setValue] = React.useState('1')

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleNumberInputChange = (event, field) => {
    const inputValue = event.target.value
    const numericValue = inputValue.replace(/\D/g, '') // Remove non-numeric characters

    // Update the form field value with the numeric value
    formik.setFieldValue(field, numericValue)
  }

  const errorMessage = (value) => {
    return (
      <div
        style={{
          color: 'red',
          position: 'absolute',
          fontWeight: 500,
        }}
      >
        {showEdit && !formik.values[value] && formik.errors[value]}
      </div>
    )
  }

  //required label

  const requiredLabel = (label, isRequired = true) => {
    return (
      <label className="stepper-label-style">
        {label}{' '}
        {isRequired && (
          <>
            &nbsp; <span style={{ color: 'red' }}>*</span>
          </>
        )}
      </label>
    )
  }

  return (
    <>
      <Paper elevation={6} style={{ minHeight: '530px' }} className="paper1">
        <Box>
          <div style={{ fontSize: '16px', fontWeight: '600' }}>
            <TabContext value={value}>
              {/* <div style={{ display: "flex", justifyContent: 'space-between', padding: '0 0 0 20px' }}> */}
              <TabList
                onChange={handleTabChange}
                aria-label="Master Page Tab"
                variant="scrollable"
                allowScrollButtonsMobile
                indicatorColor="primary"
                TabIndicatorProps={{
                  title: 'indicator',
                  sx: {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    height: '5px',
                  },
                }}
              >
                <Tab label="Profile" value="1" />
                <Tab label="Appearance" value="2" />
                <Box sx={{ flexGrow: 1 }}></Box>
                {checkPermission(
                  [
                    PERMISSIONS.serviceproviderCompanyUpdate,
                    PERMISSIONS.resellerCompanyUpdate,
                    PERMISSIONS.customerCompanyUpdate,
                  ],
                  state.currentCompanyDetails
                ) || location.pathname.slice(1) == 'master' ? (
                  !showEdit && value == 1 ? (
                    <div
                      style={{
                        padding: '0px 25px 0 0',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        onClick={() => showEditButton(true)}
                        aria-label="delete"
                        style={{
                          float: 'right',
                          borderRadius: 0,
                          fontSize: '15px',
                          marginTop: '-3px',
                        }}
                      >
                        <EditIcon
                          style={{
                            fontSize: '12px',
                            verticalAlign: 'middle',
                            margin: '0 0px 0 10px',
                          }}
                        />
                        Edit
                      </IconButton>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </TabList>
              {/* </div> */}
              <TabPanel value="1">
                <Box>
                  <form onSubmit={formik.handleSubmit}>
                    <Box className="stepper-input-label-margin">
                      {requiredLabel('Name')}
                      <TextField
                        disabled={!showEdit}
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values?.name}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      {errorMessage('name')}
                    </Box>
                    <Box sx={{ m: '0px 20px 0px 20px' }} className="">
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={6}>
                          {requiredLabel('Address - Line 1')}
                          <TextField
                            disabled={!showEdit}
                            name="streetNumber"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'streetNumber',
                                SanitizeSpecialCharacters(e.target.value)
                              )
                            }}
                            value={formik.values?.streetNumber}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          {errorMessage('streetNumber')}
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <label className="stepper-label-style">
                            {requiredLabel(
                              'Address - Line 2 (Optional)',
                              false
                            )}
                          </label>
                          <TextField
                            disabled={!showEdit}
                            name="street"
                            onChange={formik.handleChange}
                            value={formik.values?.street}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ m: '0px 20px 0px 20px' }} className="">
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={6}>
                          {requiredLabel('City')}
                          <TextField
                            disabled={!showEdit}
                            name="city"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'city',
                                sanitizeExceptAlphabets(e.target.value)
                              )
                            }}
                            value={formik.values?.city}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          {errorMessage('city')}
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                          {requiredLabel('State')}
                          <Select
                            style={{ width: '100%' }}
                            size="small"
                            name="state"
                            disabled={!showEdit}
                            onChange={(e) => {
                              formik.setFieldValue('state', e.target.value)
                            }}
                            value={formik.values?.state}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            {state.statesList?.map((list, index) => {
                              return (
                                <MenuItem key={index} value={list.code}>
                                  {list.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                          {errorMessage('state')}
                        </Grid>
                        <Grid item md={2} sm={2} xs={2}>
                          {requiredLabel('Zip')}
                          <TextField
                            disabled={!showEdit}
                            name="zipCode"
                            type="number"
                            onChange={(e) =>
                              e.target.value <= 6 &&
                              formik.setFieldValue('zipCode', e.target.value)
                            }
                            value={formik.values?.zipCode}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          {errorMessage('zipCode')}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="stepper-input-label-margin">
                      {requiredLabel('Country')}
                      <Select
                        disabled={!showEdit}
                        style={{ width: '100%' }}
                        size="small"
                        name="country"
                        value={formik.values?.country}
                        onChange={handleChange}
                        onBlur={updateStatesList}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {state.countriesList?.map((list, index) => {
                          return (
                            <MenuItem key={index} value={list.code}>
                              {list.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                      {errorMessage('country')}
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <Grid container spacing={2}>
                        <Grid item md={8} sm={8} xs={12}>
                          {requiredLabel('Phone Number')}
                          <TextField
                            disabled={!showEdit}
                            name="phone"
                            onChange={(e) =>
                              handleNumberInputChange(e, 'phone')
                            }
                            InputProps={{
                              inputProps: {
                                minLength: 10,
                                maxLength: 10,
                              },
                            }}
                            value={formik.values?.phone}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          {errorMessage('phone')}
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                          <label className="stepper-label-style">Fax</label>
                          <TextField
                            disabled={!showEdit}
                            name="fax"
                            onChange={(e) => handleNumberInputChange(e, 'fax')}
                            InputProps={{
                              inputProps: {
                                minLength: 10,
                                maxLength: 10,
                              },
                            }}
                            value={formik.values?.fax}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                      {showEdit ? (
                        <Grid
                          style={{
                            paddingTop: '10px',
                            paddingBottom: '5px',
                            background: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          className="stepper-input-label-margin"
                        >
                          {checkPermission(
                            [
                              PERMISSIONS.serviceproviderCompanyUpdate,
                              PERMISSIONS.resellerCompanyUpdate,
                              PERMISSIONS.customerCompanyUpdate,
                            ],
                            state.currentCompanyDetails
                          ) || location.pathname.slice(1) == 'master' ? (
                            <Button
                              disabled={!(formik.isValid && formik.dirty)}
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="large"
                              style={{ margin: '0 5px' }}
                            >
                              Update
                            </Button>
                          ) : (
                            ''
                          )}
                          <Button
                            onClick={() => showEditButton(false)}
                            type="button"
                            variant="contained"
                            color="secondary"
                            size="large"
                            style={{ margin: '0 5px' }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Box>
                  </form>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <MasterProfileAppearance />
              </TabPanel>
            </TabContext>
          </div>
        </Box>
      </Paper>
    </>
  )
}

export default MasterProfile

function MasterProfileAppearance(props) {
  const { dispatch, state } = useCommonRedux()
  const { settingsState } = useSettingsRedux()
  const { toast } = useToastContext()

  const [uploadlogo, setuploadlogo] = useState(null)

  const logouploadhandler = (e) => {
    let file_size = e.target.files[0].size
    if (file_size < 2097152) {
      setuploadlogo(e.target.files[0])
      var url = URL.createObjectURL(e.target.files[0])
      dispatch(setLogoURL(url))
    } else {
      setuploadlogo(null)
      toast.showToast('Image should be less than 2MB only', 'error')
    }
  }

  var logoformdata = new FormData()
  logoformdata.append('CompanyId', state.companyId)
  logoformdata.append('Logo', uploadlogo)

  const uploadlogofunction = async () => {
    if (uploadlogo && uploadlogo.size < 2097152) {
      dispatch(setSkeletonLoading(true))
      try {
        const data = await uploadLogo(logoformdata, props?.config)
        dispatch(setLogoURL(data.data.result.logoUrl))
        const updatedDetailsWithLogo = {
          ...state.currentCompanyDetails,
          logoUrl: data.data.result.logoUrl,
        }
        dispatch(setCurrentCompanyDetails(updatedDetailsWithLogo))
        toast.showToast(
          data.data.statusCode === 200
            ? 'Logo uploaded successfully'
            : data.data.message
        )
        setuploadlogo(null)
        dispatch(setSkeletonLoading(false))
      } catch (err) {
        if (err) {
          dispatch(setSkeletonLoading(false))
          toast.showToast('Logo file supports only JPG, JPEG and PNG', 'error')
        }
      }
    } else {
      toast.showToast('Image should be less than 2MB only', 'error')
    }
  }
  const revertFunction = () => {
    dispatch(setLogoURL(state.currentCompanyDetails.logoUrl))
  }

  useEffect(() => {
    dispatch(setLogoURL(state.currentCompanyDetails?.logoUrl))
  }, [state.currentCompanyDetails.logoUrl])

  const changelogo = () => {
    dispatch(setLogoURL(''))
  }

  return (
    <Container>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Box className="stepper-title-containers">
            <h3>Appearance</h3>
            <Box className="stepper-main-contianer1 stepper-border-style stepper-box-shadow-style">
              <Box className="upload-btn-container-style">
                <div className="stepper-label-style">Logo</div>
                &emsp;
                <Button
                  disableElevation
                  disabled={
                    !(
                      checkPermission(
                        [
                          PERMISSIONS.serviceproviderCompanyUpdate,
                          PERMISSIONS.resellerCompanyUpdate,
                          PERMISSIONS.customerCompanyUpdate,
                        ],
                        state.currentCompanyDetails
                      ) || location.pathname.slice(1) == 'master'
                    )
                  }
                  sx={{
                    width: '90px',
                    height: '90px',
                    margin: '10px -20px',
                    borderRadius: '50%',
                    border: '2px dashed #4682b4',
                    backgroundColor: 'transparent',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  variant="contained"
                  component="label"
                >
                  {state.isSkeletonLoading ? (
                    <CircularProgress size={40} />
                  ) : state.logoURL == '' ? (
                    <>
                      <Tooltip title="upload icon">
                        <BackupIcon sx={{ width: '200px' }} />
                      </Tooltip>
                      <input
                        onChange={(e) => logouploadhandler(e)}
                        type="file"
                        accept="image/*"
                        inputProps={{ accept: 'image/*' }}
                        hidden
                      />{' '}
                      {/* <input
                        onChange={(e) => logouploadhandler(e)}
                        type="file"
                        hidden
                      />{' '} */}
                    </>
                  ) : (
                    <img
                      src={state.logoURL}
                      width="100%"
                      height="100px"
                      onClick={changelogo}
                      style={{ objectFit: 'contain', borderRadius: '50%' }}
                    ></img>
                  )}
                </Button>
                &emsp;
                <div className="input-info-label">
                  File size must be under 2 mb (Image Format: PNG or JPEG or
                  JPG)
                  <br />
                </div>
              </Box>

              {checkPermission(
                [
                  PERMISSIONS.serviceproviderCompanyUpdate,
                  PERMISSIONS.resellerCompanyUpdate,
                  PERMISSIONS.customerCompanyUpdate,
                ],
                state.currentCompanyDetails
              ) || location.pathname.slice(1) == 'master' ? (
                <Box className="Appearance-btn-container-style">
                  <Box className="Appearance-btn-container-style-1">
                    <Button
                      disabled={!uploadlogo}
                      fullWidth
                      variant="contained"
                      size="large"
                      onClick={uploadlogofunction}
                    >
                      confirm
                    </Button>
                  </Box>
                  {!uploadlogo && !state.logoURL ? (
                    <Box className="Appearance-btn-container-style-1">
                      <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        onClick={revertFunction}
                      >
                        revert
                      </Button>
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pl: '20px', color: 'red' }}>{props.logohelpertext}</Box>
    </Container>
  )
}
