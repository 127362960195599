import { Box, Divider, Typography, Paper } from '@mui/material'
import { styled } from '@mui/system'

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  color: 'gray',
  fontWeight: '100',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: '15px!important',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem!important',
    },
  },
}))

const StyledBody = styled('div')(({ theme }) => ({
  fontSize: '16px',
  color: 'gray',
  fontWeight: '100',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: '15px!important',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem!important',
    },
  },
}))

export default function CustomerLastBill() {
  return (
    <div>
      <Paper elevation={6} style={{ height: '140px', padding: '10px' }}>
        <Box sx={{ flexGrow: 1, width: '100%' }}>
          <Typography variant="h3">Last Bill: $35,000</Typography>
          <StyledSubtitle>
            <Box sx={{ width: '100%', display: 'flex' }}>
              <Box>
                <div>Bill Date</div>
                <StyledBody>
                  <div>15 May 2021</div>
                </StyledBody>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginRight: '20px', marginLeft: '20px' }}
              />
              <Box>
                <div>Payment Status</div>
                <StyledBody>
                  <div>OverDue</div>
                </StyledBody>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginRight: '20px', marginLeft: '20px' }}
              />
              <Box>
                <div>Due Date</div>
                <StyledBody>
                  <div> 15 May 2022</div>
                </StyledBody>
              </Box>
            </Box>
          </StyledSubtitle>
        </Box>
      </Paper>
    </div>
  )
}
