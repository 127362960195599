// CommonReduxContext.js
import React, { createContext, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { commonSliceState } from '../slice/commonSlice';

const CommonReduxContext = createContext();

export const CommonReduxProvider = ({ children }) => {
    const dispatch = useDispatch();
    const state = useSelector(commonSliceState); // Adjust this selector as needed

    return (
        <CommonReduxContext.Provider value={{ dispatch, state }}>
            {children}
        </CommonReduxContext.Provider>
    );
};

export const useCommonRedux = () => useContext(CommonReduxContext);
