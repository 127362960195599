import React, { useState } from 'react'
import { Tooltip, IconButton, Box, Drawer, useTheme } from '@mui/material'
import { Help as HelpIcon } from '@mui/icons-material'
import DrawerHeader from '../../drawer/DrawerHeader'

const HelpMenu = () => {
  const drawerWidth = 300
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const helpList = () => (
    <div>
      <DrawerHeader header="Help & Support" onClick={toggleDrawer(false)} />
      <div style={{ padding: '20px' }}>
        <div style={{ color: '#232333' }}>Have a question?</div>
        <div style={{ color: '#232333' }}>Contact Altigen Support:</div>
        <ul style={{ marginLeft: '-25px', marginTop: '10px' }}>
          <li>
            Online:{' '}
            <a href="https://altigen.freshservice.com" target="_blank">
              Support Portal
            </a>
          </li>
          <li>
            Email:{' '}
            <a href="mailto:it@altigen.freshservice.com?subject = Feedback&body = Support">
              Send Feedback
            </a>
          </li>
        </ul>
      </div>
    </div>
  )

  return (
    <Box sx={{ display: 'inline', justifyContent: 'end' }}>
      <Tooltip title="Help" arrow>
        <IconButton
          sx={{
            ml: 2,
            display: 'inline-flex',
            textAlign: 'left',
            color: '#2E384D',
            marginTop: '14px',
          }}
          onClick={toggleDrawer(true)}
        >
          <HelpIcon sx={{ color: '#2E384D' }} />
        </IconButton>
      </Tooltip>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {helpList()}
      </Drawer>
    </Box>
  )
}

export default HelpMenu
