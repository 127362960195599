import React from 'react'
import ReactDOM from 'react-dom/client'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './helpers/AuthConfig'

// import './bootstrap.css'
import './index.css'
//import reportWebVitals from './reportWebVitals'
import ThemedApp from './common/styles/theme'

const msalInstance = new PublicClientApplication(msalConfig)
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <MsalProvider instance={msalInstance}>
    <ThemedApp />
  </MsalProvider>
)
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
