import { useEffect, useState } from 'react'

import {
  Box,
  Container,
  Grid,
  FormControl,
  MenuItem,
  FormHelperText,
  Stack,
  Button,
} from '@mui/material'

import Select from '@mui/material/Select'
import SaveAsIcon from '@mui/icons-material/SaveAs'

import '../account-details/Accountdetails.css'

// api
import {
  getCompanyNameStepper,
  getCountries,
  getStates,
} from '../../../../../api/get/getApi'
import nextbtn from '../../../../../assets/icons/Next Button.svg'
import backbtn from '../../../../../assets/icons/Back Button.svg'
import '../steppers.css'

import { Formik, Form } from 'formik'

// redux hooks
import { useSelector, useDispatch } from 'react-redux'

// redux state and actions
import {
  formDetailsState,
  setCompanyDetailsAction,
  StepForward,
  StepBackward,
  setService,
  setIsTermsAndConditionsAccepted,
  setTermsAndConditionsBase64String,
  setbillingInfo,
} from '../../../../../store/slice/stepperSlice'
import { companyDetailsYup } from '../../../../../validations/SignupValidation'
import ReusableTextfield from '../../../../../common/components/textField/textField'
import { styled } from '@mui/system'
import {
  sanitizeExceptAlphabets,
  SanitizeSpecialCharacters,
} from '../../../../../helpers/HelperFunctions'

const StyledLabel = styled('label')(({ theme }) => ({
  color: theme.typography.Label.color,
  fontSize: theme.typography.Label.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'left',
  fontStyle: 'normal',
  letterSpacing: '-0.1px',
  marginBottom: '4px',
  marginTop: '4px',
}))

function CompanyDetails({ companydraft }) {
  const { companyDetail, directCustomer, companyLevel, billing } =
    useSelector(formDetailsState)

  useEffect(() => {
    getcountrylist()
  }, [])

  const getcompanyid = localStorage.getItem('companyid')
  useEffect(() => {
    const localToken = localStorage.getItem('sd_cp.jwt:tkn')
    const config = { headers: { Authorization: `Bearer ${localToken}` } }
    getcompanyNameinStepper(getcompanyid, config)
  }, [])
  const getcompanyNameinStepper = async (getcompanyid, config) => {
    const data = await getCompanyNameStepper(getcompanyid, config)
    const {
      data: { result },
    } = data

    if (result) {
      dispatch(setService(result?.service))
      dispatch(
        setIsTermsAndConditionsAccepted(result?.isTermsAndConditionsAccepted)
      )
      dispatch(
        setTermsAndConditionsBase64String(result?.termsAndConditionsFileData)
      )
    }
    if (companyDetail?.country) {
      getStatesbyCountryCode()
    }
  }

  const [countrylist, setcountrylist] = useState([])
  const [statelist, setstatelist] = useState([])

  const getcountrylist = async () => {
    const data = await getCountries()
    setcountrylist(data.data.result)
    if (!companyDetail?.country) {
      dispatch(
        setCompanyDetailsAction({
          country: 'USA',
          companyName: companyDetail.companyName,
        })
      )
      getcountrycode('', 'USA')
    } else {
      getcountrycode('', 'USA')
    }
  }

  const getStatesbyCountryCode = async () => {
    const data = await getStates(companyDetail?.country)
    setstatelist(data.data.result)
  }

  const getcountrycode = async (e, code) => {
    const data = await getStates(code)
    setstatelist(data.data.result)
  }

  const dispatch = useDispatch()
  const handleNumberInputChange = (event, field, setFieldValue) => {
    const inputValue = event.target.value
    const numericValue = inputValue.replace(/\D/g, '') // Remove non-numeric characters

    // Update the form field value with the numeric value
    setFieldValue(field, numericValue)
  }
  return (
    <Container>
      <Formik
        initialValues={{
          companyName: companyDetail.companyName,
          street: companyDetail.street,
          streetNumber: companyDetail.streetNumber,
          country: companyDetail.country,
          city: companyDetail.city,
          state: companyDetail.state,
          zip: companyDetail.zip,
          phone: companyDetail.phone,
          fax: companyDetail.fax,
        }}
        validationSchema={companyDetailsYup}
        onSubmit={(values) => {
          if (values) {
            // if (companyLevel == COMPANY_LEVELS.customer && directCustomer === false) dispatch(StepForward(4))
            dispatch(StepForward())
            dispatch(setCompanyDetailsAction(values))
            dispatch(
              setbillingInfo({
                ...billing,
                companyName: values.companyName,
                line1: values.streetNumber,
                line2: values.street || '',
                country: values.country,
                city: values.city,
                state: values.state,
                zip: values.zip,
              })
            )
          }
        }}
        enableReinitialize={true}
      >
        {({
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          dirty,
        }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item md={24} sm={24} xs={24}>
                <Box className="stepper-containers">
                  <h3>Company Details</h3>
                  <Box className="stepper-border-style stepper-main-contianer1">
                    <Box className="stepper-input-label-margin">
                      <ReusableTextfield
                        disabled
                        name="companyName"
                        size="small"
                        id="Company Name"
                        required
                      />
                    </Box>
                    <Box
                      sx={{ m: '0px 20px 0px 20px' }}
                      className="stepper-input-label-margin"
                    >
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={6}>
                          <FormControl fullWidth>
                            <ReusableTextfield
                              name="streetNumber"
                              id="Address - Line 1"
                              size="small"
                              required
                              onChange={(e) => {
                                setFieldValue(
                                  'streetNumber',
                                  SanitizeSpecialCharacters(e.target.value)
                                )
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <FormControl fullWidth>
                            <ReusableTextfield
                              name="street"
                              id="Address - Line 2(Optional)"
                              size="small"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{ m: '0px 20px 0px 20px' }}
                      className="stepper-input-label-margin"
                    >
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={6}>
                          <FormControl fullWidth>
                            <ReusableTextfield
                              name="city"
                              size="small"
                              id="City"
                              required
                              onChange={(e) => {
                                setFieldValue(
                                  'city',
                                  sanitizeExceptAlphabets(e.target.value)
                                )
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <StyledLabel>
                            <label>
                              State &nbsp;{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </label>
                          </StyledLabel>
                          <FormControl
                            sx={{ margin: '4px 0' }}
                            fullWidth
                            error={touched.state && errors.state}
                            size="small"
                          >
                            <Select
                              name="state"
                              error={touched.state && errors.state}
                              sx={{ height: '40px' }}
                              onBlur={handleBlur}
                              defaultValue={'' || null}
                              value={values.country && values.state}
                              onChange={handleChange}
                            >
                              {values.country &&
                                statelist.map((data) => (
                                  <MenuItem value={data.code}>
                                    {data.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                              {errors.state && touched.state && errors.state}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <Grid container spacing={2}>
                        <Grid item md={8} sm={8} xs={8}>
                          <FormControl
                            id="country"
                            fullWidth
                            error={touched.country && errors.country}
                            size="small"
                          >
                            <StyledLabel>
                              <label>
                                Country &nbsp;{' '}
                                <span style={{ color: 'red' }}>*</span>
                              </label>
                            </StyledLabel>
                            <Select
                              name="country"
                              id="country"
                              required
                              defaultValue={'' || null}
                              onChange={handleChange}
                              value={values.country}
                              onBlur={handleBlur}
                            >
                              {countrylist.map((data) => (
                                <MenuItem
                                  value={data.code}
                                  onClick={(e) => getcountrycode(e, data.code)}
                                >
                                  {data.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {errors.country &&
                                touched.country &&
                                errors.country}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                          <FormControl fullWidth>
                            <ReusableTextfield
                              name="zip"
                              id="Zip"
                              size="small"
                              required
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <Grid container spacing={2}>
                        <Grid item md={8} sm={8} xs={12}>
                          <ReusableTextfield
                            name="phone"
                            id="Phone Number"
                            size="small"
                            InputProps={{
                              inputProps: {
                                minLength: 10,
                                maxLength: 10,
                              },
                            }}
                            value={values.phone}
                            onChange={(e) =>
                              handleNumberInputChange(e, 'phone', setFieldValue)
                            }
                            required
                          />
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                          <ReusableTextfield
                            name="fax"
                            id="Fax"
                            InputProps={{
                              inputProps: {
                                minLength: 10,
                                maxLength: 10,
                              },
                            }}
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
                <Grid container justifyContent="space-between">
                  <Grid item md={4} sm={8} xs={8}>
                    <Stack direction="row" justifyContent="flex-start">
                      <img
                        src={backbtn}
                        className="btn-hover-effect"
                        height="92px"
                        onClick={() => {
                          dispatch(setCompanyDetailsAction(values))
                          dispatch(StepBackward())
                        }}
                        alt="next button"
                      />
                      <img
                        src={nextbtn}
                        className="btn-hover-effect"
                        height="92px"
                        onClick={handleSubmit}
                        alt="next button"
                      />
                    </Stack>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={6}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      height="92px"
                      sx={{ p: '24px' }}
                      spacing={2}
                    >
                      <Button
                        variant="contained"
                        disabled={
                          !dirty ||
                          (values.streetNumber === '' &&
                            initialValues.streetNumber !== '') ||
                          (values.state === '' && initialValues.state !== '') ||
                          (values.city === '' && initialValues.city !== '') ||
                          (values.phone === '' && initialValues.phone !== '') ||
                          (values.zip === '' && initialValues.zip !== '') ||
                          (values.fax === '' && initialValues.fax !== '')
                        }
                        onClick={() => {
                          dispatch(setCompanyDetailsAction(values))
                          companydraft(true, values)
                        }}
                      >
                        <SaveAsIcon /> &nbsp; save for later
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default CompanyDetails
