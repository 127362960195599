import React from 'react'
import { Box, Grid, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

const CardHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontSize: '1rem',
  fontWeight: 'bold',
  textAlign: 'start',
  padding: 10,
}))

const CardBody = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: `0.5px solid ${theme.palette.border.main}`,
  borderRadius: '0 0 10px 10px',
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2)',
  padding: 10,
}))

const TrunkConfigCard = ({ title, tooltip, content, ...props }) => {
  const wrapToolTip = (children) => {
    if (tooltip) return <Tooltip title={tooltip} placement="right">
      <label>{children}</label>
    </Tooltip>
    return children
  }
  return (
    <Grid container {...props} sx={{ padding: '10px 0' }}>
      <Grid item xs={12}>
        <CardHeader>
          {wrapToolTip(<label>{title}</label>)}
        </CardHeader>
        <CardBody>{content}</CardBody>
      </Grid>
    </Grid>
  )
}

export default TrunkConfigCard
