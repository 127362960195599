import { Button, Typography } from '@mui/material'
import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import unauthorised from '../../../../assets/403.svg'
import { history } from '../../../../common/components/router/HistoryRouter'

function UnAuthorised() {
  useEffect(() => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    if (!decodeToken) {
      history.replace('/login')
    }
  }, [])
  return (
    <Stack justifyContent={'center'} alignItems="center" height="100vh">
      <img src={unauthorised} height={'400'} width={'500'} />

      <Typography>You are not authorized to view this page</Typography>
      <Button
        onClick={() => {
          console.log('login')
          history.replace('/login')
        }}
      >
        Login
      </Button>
    </Stack>
  )
}

export default UnAuthorised
