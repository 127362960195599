import { createSlice } from '@reduxjs/toolkit'
import { PAGES } from '../../helpers/Constants'

//**INITIAL STATE */
const initialState = {
  page: PAGES.Home,
  isSkeletonLoading: false,
  isLoading: false,
  routingLevel: [],
  currentPath: '',
  countriesList: null,
  statesList: null,
  currentCompanyDetails: {},
  currentCompanyLevel: '',
  permissionList: null,

  //general details
  isMaster: false,
  companyId: '',
  allProducts: null,
  selectedProducts: null,
  //header section
  isProfileSettingsOpen: false,
  isChangePasswordOpen: false,
  profileSettings: {},
  loggedInUser: {},
  serviceProviderId: '',
  resellerId: '',
  customerId: '',
  directCustomerId: '',
  logoURL: '',

  //Settings Section
  isSettingsOpen: false,
  isDirectCustomer: false,
}

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSkeletonLoading: (state, { payload }) => {
      state.isSkeletonLoading = payload
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setRoutingLevel: (state, { payload }) => {
      state.routingLevel = payload
    },
    setIsMaster: (state, { payload }) => {
      state.isMaster = payload
    },
    setCurrentCompanyDetails: (state, { payload }) => {
      state.currentCompanyDetails = payload
    },
    setCurrentCompanyLevel: (state, { payload }) => {
      state.currentCompanyLevel = payload
    },
    setCurrentPath: (state, { payload }) => {
      state.currentPath = payload
    },
    setCountriesList: (state, { payload }) => {
      state.countriesList = payload
    },
    setStatesList: (state, { payload }) => {
      state.statesList = payload
    },
    setPermissionList: (state, { payload }) => {
      state.permissionList = payload
    },
    setAllProducts: (state, { payload }) => {
      state.allProducts = payload
    },
    setSelectedProducts: (state, { payload }) => {
      state.selectedProducts = payload
    },
    setCompanyId: (state, { payload }) => {
      state.companyId = payload
    },
    setIsProfileSettingsOpen: (state, { payload }) => {
      state.isProfileSettingsOpen = payload
    },
    setIsChangePasswordOpen: (state, { payload }) => {
      state.isChangePasswordOpen = payload
    },
    setProfileSettings: (state, { payload }) => {
      state.profileSettings = payload
    },
    setIsSettingsOpen: (state, { payload }) => {
      state.isSettingsOpen = payload
    },
    setLoggedInUser: (state, { payload }) => {
      state.loggedInUser = payload
    },
    setServiceProviderId: (state, { payload }) => {
      state.serviceProviderId = payload
    },
    setResellerId: (state, { payload }) => {
      state.resellerId = payload
    },
    setCustomerId: (state, { payload }) => {
      state.customerId = payload
    },
    setDirectCustomerId: (state, { payload }) => {
      state.directCustomerId = payload
    },
    setLogoURL: (state, { payload }) => {
      state.logoURL = payload
    },
    setIsDirectCustomer: (state, { payload }) => {
      state.isDirectCustomer = payload
    },
  },
})

// export state as function
export const commonSliceState = (state) => state.commonSlice

// export actions
export const {
  setPage,
  setSkeletonLoading,
  setIsLoading,
  setRoutingLevel,
  setIsMaster,
  setCurrentCompanyDetails,
  setCurrentCompanyLevel,
  setCurrentPath,
  setCountriesList,
  setStatesList,
  setPermissionList,
  setAllProducts,
  setSelectedProducts,
  setCompanyId,
  setIsProfileSettingsOpen,
  setIsChangePasswordOpen,
  setProfileSettings,
  setIsSettingsOpen,
  setLoggedInUser,
  setServiceProviderId,
  setResellerId,
  setCustomerId,
  setDirectCustomerId,
  setLogoURL,
  setIsDirectCustomer,
} = commonSlice.actions

export default commonSlice.reducer
