import {
  Grid,
  Avatar,
  Stack,
  Tooltip,
  div,
  ListItem,
  ListItemText,
  Paper,
  List,
  ListItemAvatar,
  IconButton,
} from '@mui/material'

import sipLogo from '../../../assets/sip-logo.png'
import maxucLogo from '../../../assets/maxuc-logo.png'
import drLogo from '../../../assets/dr-logo.png'

import './cards.css'
import AddIcon from '@mui/icons-material/Add'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import {
  checkPermission,
  getAvailableIconStatus,
  getMaxUCOrderIconStatus,
  stringAvatar,
} from '../../../helpers/HelperFunctions'
import { ORDER_STATUS_ENUM, PERMISSIONS, PRODUCT_NAMES, SERVICE_PRODUCT_CODES } from '../../../helpers/Constants'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'
import { styled } from '@mui/system'

const StyledCaption = styled('span')(() => ({
  marginTop: '20px',
  fontWeight: 100,
  fontSize: '14px',
  lineHeight: 1.66,
  letterSpacing: 0.044,
  color: '#008000',
  paddingBottom: '5px',
}))

export default function ServiceProductCard(props) {
  const { dispatch, state: commonState } = useCommonRedux()

  const productPermissionCreate = [
    PERMISSIONS.masterProductCreate,
    PERMISSIONS.serviceproviderProductCreate,
    PERMISSIONS.resellerProductCreate,
    PERMISSIONS.customerProductCreate,
  ]
  const productPermissionUpdate = [
    PERMISSIONS.masterProductUpdate,
    PERMISSIONS.serviceproviderProductUpdate,
    PERMISSIONS.resellerProductUpdate,
    PERMISSIONS.customerProductUpdate,
  ]

  return (
    <>
      <Grid container spacing={2}>
        {props.data?.map((item, key) => (
          <Grid key={`Service_Grid_${key}`} item xs={12} sm={6} md={4} xl={3}>
            <Paper
              className={
                item.orderStatus == ORDER_STATUS_ENUM.Completed ||
                  !item.orderStatus
                  ? 'cardactive'
                  : 'cardinactive'
              }
              sx={{ backgroundColor: '#fff' }}
              elevation={8}
            >
              <div>
                <Stack
                  sx={{ p: '10px 20px', margin: 0 }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={0}
                >
                  <List style={{ width: '100%' }}>
                    <ListItem
                      disablePadding
                      secondaryAction={
                        <>
                          {props.ordered ? (
                            checkPermission(
                              [
                                ...productPermissionCreate,
                                ...productPermissionUpdate,
                              ],
                              commonState.currentCompanyDetails
                            ) ? (
                              <Tooltip title="Open Service">
                                <IconButton
                                  onClick={() =>
                                    props.goToServiceFunction(item,false)
                                  }
                                  edge="end"
                                  aria-label="delete"
                                >
                                  <PlayCircleIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ''
                            )
                          ) : checkPermission(
                            productPermissionCreate,
                            commonState.currentCompanyDetails
                          ) ? (
                            <Tooltip title="Create New Service">
                              <IconButton
                                onClick={() => props.goToServiceFunction(item,true)}
                                edge="end"
                                aria-label="delete"
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </>
                      }
                    >
                      <ListItemAvatar>
                        {item.productCode === SERVICE_PRODUCT_CODES.SipTrunking ? (
                          <Avatar
                            sx={{
                              borderRadius: 0,
                              width: 65,
                              height: 65,
                              margin: '0 15px 0 0',
                            }}
                            src={sipLogo}
                          ></Avatar>
                        ) : item.productCode === SERVICE_PRODUCT_CODES.DirectRouting ? (
                          <Avatar
                            sx={{
                              borderRadius: 0,
                              width: 70,
                              height: 65,
                              justifyContent: 'center',
                              margin: '0 15px 0 0',
                            }}
                            src={drLogo}
                          ></Avatar>
                        ) : item.productCode === SERVICE_PRODUCT_CODES.MaxCloudCoreView ? (
                          <Avatar
                            sx={{
                              borderRadius: 0,
                              width: 65,
                              height: 65,
                              margin: '0 15px 0 0',
                            }}
                            src={maxucLogo}
                          ></Avatar>
                        ) : (
                          <Avatar
                            sx={{
                              borderRadius: 0,
                              width: 65,
                              height: 65,
                              margin: '0 15px 0 0',
                            }}
                            {...stringAvatar(item.productCode == SERVICE_PRODUCT_CODES.DirectRouting ? PRODUCT_NAMES.TPS : item.name)}
                          ></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.productCode == SERVICE_PRODUCT_CODES.DirectRouting ? PRODUCT_NAMES.TPS : item.name}
                        secondary={
                          <StyledCaption>
                            <span>
                              {item?.orderStatus !== undefined &&
                                item?.orderStatus !== null
                                ? getMaxUCOrderIconStatus(item?.orderStatus)
                                : getAvailableIconStatus()}
                            </span>
                          </StyledCaption>
                        }
                      />
                    </ListItem>
                  </List>
                </Stack>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
