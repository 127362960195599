import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  MenuItem,
  Select,
} from '@mui/material'
import * as React from 'react'
import {
  masterAddressAllApi,
  masterPostAddressApi,
} from '../../../../api/master/masterAPI'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { useFormik } from 'formik'
import LoadingSection from '../../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  getStates,
  getCountries,
  companyDraftStateListApi,
} from '../../../../api/get/getApi'
import {
  setBillingAddress,
  settingsSliceState,
} from '../../../../store/slice/settingsSlice'
import { useSelector } from 'react-redux'
import { ContactSkeleton } from '../../../../common/components/loaders/skeletons/SettingsSkeleton'
import { setSkeletonLoading } from '../../../../store/slice/commonSlice'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import { checkPermission, getCompanyLevel, normalizeApiResponse, sanitizeExceptAlphabets, SanitizeSpecialCharacters } from '../../../../helpers/HelperFunctions';
import { PERMISSIONS } from '../../../../helpers/Constants'
import { ContactBillingFormValidation } from '../../../../validations/SettingsValidation'
import { useToastContext } from '../../../../App'

export function MasterBillings(props) {
  const { dispatch, state } = useCommonRedux()
  const { toast } = useToastContext()
  const [responseDetails, setResponseDetails] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [countries, setCountries] = React.useState([])
  const [touched, setTouched] = React.useState(true)
  const [states, setStates] = React.useState([])
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const settingsState = useSelector(settingsSliceState)
  const currentLevel = getCompanyLevel(
    searchParams.get('company_id'),
    location.pathname.slice(1)
  )
  const formik = useFormik({
    initialValues: {
      companyId: '',
      companyName: ' ',
      addressType: 1,
      country: '',
      state: '',
      city: '',
      line1: '',
      line2: '',
      addressId: '',
      zipCode: '',
    },
    initialTouched: false,
    validationSchema: ContactBillingFormValidation,
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      delete data.addressId
      data.companyName = data.companyName.trim()
      masterPostAddressApi(
        data,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          setSubmitLoader(false)
          setShowEdit(false)
          getDecodeValue()
          toast.showToast(response?.data?.message, 'success')
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast( normalizeApiResponse(error)?.message, 'error')
        })
    },
  })

  const updateFormDetails = (contact) => {
    formik.setFieldValue('companyId', contact?.companyId)
    formik.setFieldValue('companyName', contact?.companyName)
    formik.setFieldValue('addressType', contact?.addressType)
    formik.setFieldValue('country', contact?.country)
    formik.setFieldValue('state', contact?.state)
    formik.setFieldValue('city', contact?.city)
    formik.setFieldValue('line1', contact?.line1)
    formik.setFieldValue('line2', contact?.line2)
    formik.setFieldValue('addressId', contact?.addressId)
    formik.setFieldValue('zipCode', contact?.zipCode)

    if (contact?.country) {
      getStateListApi({ countryCode: contact?.country })
    }
  }

  const getMasterAddressAllApi = async (id) => {
    if (id) {
      try {
        dispatch(setSkeletonLoading(true))
        if (!settingsState.billingAddress) {
          const response = await masterAddressAllApi(
            id,
            currentLevel,
            location.pathname.slice(1)
          )
          if (response.data.result) {
            setTouched(true)
            dispatch(setBillingAddress(response.data.result))
            setResponseDetails(response.data.result)
            const contactObj = response.data.result.find(
              (filt) => filt.addressType == 1
            )
            contactObj?.country && (await getStates(contactObj?.country))
            updateFormDetails(contactObj)
          }
          dispatch(setSkeletonLoading(false))
        } else {
          setTouched(true)
          setResponseDetails(settingsState.billingAddress)
          const contactObj = settingsState.billingAddress?.find(
            (filt) => filt.addressType == 1
          )
          contactObj?.country && (await getStates(contactObj?.country))
          updateFormDetails(contactObj)
          dispatch(setSkeletonLoading(false))
        }
      } catch (error) {
        dispatch(setSkeletonLoading(false))
        toast.showToast(normalizeApiResponse(error)?.message, 'error')
      }
    }
  }
  const getCountriesListApi = (id) => {
    getCountries(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setCountries(response.data.result)
      })
      .catch((error) => {
        setLoader(false)
        toast.showToast(normalizeApiResponse(error)?.message, 'error')
      })
  }
  const getStateListApi = (countryObj) => {
    companyDraftStateListApi(
      countryObj,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setStates(response.data.result)
      })
      .catch((error) => {
        setLoader(false)
        toast.showToast(normalizeApiResponse(error)?.message, 'error')
      })
  }
  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getMasterAddressAllApi(companyId)
      getCountriesListApi()
    }
  }

  const showEditButton = (e) => {
    if (!e) {
      const contactObj = settingsState.billingAddress?.find(
        (filt) => filt.addressType == 1
      )
      if (contactObj) {
        if (contactObj?.country)
          getStateListApi({ countryCode: contactObj?.country })
        formik.setFieldValue('companyId', contactObj?.companyId)
        formik.setFieldValue('companyName', contactObj?.companyName)
        formik.setFieldValue('addressType', contactObj?.addressType)
        formik.setFieldValue('country', contactObj?.country)
        formik.setFieldValue('state', contactObj?.state)
        formik.setFieldValue('city', contactObj?.city)
        formik.setFieldValue('line1', contactObj?.line1)
        formik.setFieldValue('line2', contactObj?.line2)
        formik.setFieldValue('addressId', contactObj?.addressId)
        formik.setFieldValue('zipCode', contactObj?.zipCode)
      } else {
        formik.resetForm()
      }
    }
    setShowEdit(e)
  }
  useEffect(() => {
    getDecodeValue()
    formik.errors = {}
  }, [])
  useEffect(() => {
    formik.setTouched({}, touched)
  }, [touched, formik?.errors])

  const handleCountryChange = (event) => {
    getStateListApi({ countryCode: event.target.value })
    formik.setFieldValue('country', event.target.value)
  }

  if (props?.isShowBillingAddress) {
    return (
      <>
        {submitLoader ? <LoadingSection></LoadingSection> : ''}
        <Paper elevation={6} style={{ minHeight: '500px' }} className="paper1">
          {!settingsState.billingAddress ? (
            <ContactSkeleton />
          ) : (
            <Box>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 0 0 20px',
                }}
              >
                <div style={{ fontSize: '16px', fontWeight: '600' }}>
                  Billing Address
                </div>
                {checkPermission(
                  [
                    PERMISSIONS.serviceproviderCompanyUpdate,
                    PERMISSIONS.resellerCompanyUpdate,
                    PERMISSIONS.customerCompanyUpdate,
                  ],
                  state.currentCompanyDetails
                ) || location.pathname.slice(1) == 'master' ? (
                  !showEdit ? (
                    <div
                      style={{
                        padding: '0 25px 0 0',
                      }}
                    >
                      <IconButton
                        onClick={() => showEditButton(true)}
                        aria-label="delete"
                        style={{
                          float: 'right',
                          borderRadius: 0,
                          fontSize: '15px',
                          marginTop: '-3px',
                        }}
                      >
                        <EditIcon
                          style={{
                            fontSize: '12px',
                            verticalAlign: 'middle',
                            margin: '0 0px 0 10px',
                          }}
                        />
                        Edit
                      </IconButton>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
              <Box>
                <form onSubmit={formik.handleSubmit}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="stepper-input-label-margin">
                      <label className="stepper-label-style">
                        Company name &nbsp;{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <TextField
                        disabled={!showEdit}
                        id="companyName"
                        name="companyName"
                        onChange={formik.handleChange}
                        value={formik.values?.companyName || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <div style={{ color: 'red', position: 'absolute' }}>
                        {formik.errors?.companyName
                          ? formik.errors.companyName
                          : null}
                      </div>
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={6}>
                          <label className="stepper-label-style">
                            Country &nbsp;{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Select
                            disabled={!showEdit}
                            style={{ width: '100%' }}
                            size="small"
                            name="country"
                            value={formik.values?.country}
                            onChange={handleCountryChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            {countries.map((list, index) => {
                              return (
                                <MenuItem key={index} value={list.code}>
                                  {list.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <label className="stepper-label-style">
                            State &nbsp; <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Select
                            style={{ width: '100%' }}
                            size="small"
                            name="state"
                            disabled={!showEdit}
                            onChange={(e) => {
                              formik.setFieldValue('state', e.target.value)
                            }}
                            value={formik.values?.state}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            {states.map((list, index) => {
                              return (
                                <MenuItem key={index} value={list.code}>
                                  {list.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={6}>
                          <label className="stepper-label-style">
                            City &nbsp; <span style={{ color: 'red' }}>*</span>
                          </label>
                          <TextField
                            disabled={!showEdit}
                            id="city"
                            name="city"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'city',
                                sanitizeExceptAlphabets(e.target.value)
                              )
                            }}
                            value={formik.values.city || ''}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <label className="stepper-label-style">
                            Zip &nbsp; <span style={{ color: 'red' }}>*</span>
                          </label>
                          <TextField
                            disabled={!showEdit}
                            id="zipCode"
                            name="zipCode"
                            onChange={formik.handleChange}
                            type="number"
                            value={formik.values.zipCode || ''}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <label className="stepper-label-style">
                        Address Line 1{" "}<span style={{ color: 'red' }}>*</span>
                      </label>
                      <TextField
                        disabled={!showEdit}
                        id="line1"
                        name="line1"
                        onChange={(e) => {
                          formik.setFieldValue(
                            'line1',
                            SanitizeSpecialCharacters(e.target.value)
                          )
                        }}
                        value={formik.values.line1 || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                    <Box className="stepper-input-label-margin">
                      <label className="stepper-label-style">
                        'Address Line 2 (Optional)
                      </label>
                      <TextField
                        disabled={!showEdit}
                        id="line2"
                        name="line2"
                        onChange={formik.handleChange}
                        value={formik.values.line2 || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                  </Grid>
                  {showEdit ? (
                    <Box
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        background: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      className="stepper-input-label-margin"
                    >
                      {checkPermission(
                        [
                          PERMISSIONS.serviceproviderCompanyUpdate,
                          PERMISSIONS.resellerCompanyUpdate,
                          PERMISSIONS.customerCompanyUpdate,
                        ],
                        state.currentCompanyDetails
                      ) || location.pathname.slice(1) == 'master' ? (
                        <Button
                          disabled={!(formik.isValid && formik.dirty)}
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          style={{ margin: '0 5px' }}
                        >
                          Update
                        </Button>
                      ) : (
                        ''
                      )}
                      <Button
                        onClick={() => showEditButton(false)}
                        type="button"
                        variant="contained"
                        color="secondary"
                        size="large"
                        style={{ margin: '0 5px' }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    ''
                  )}
                </form>
              </Box>
            </Box>
          )}
        </Paper>
      </>
    )
  }
}
export default MasterBillings
