import axios from 'axios'
import { history } from '../../common/components/router/HistoryRouter'
import { ENV_URLS, ERROR_STATUS_CODES } from '../../helpers/Constants'

// Base Axios API Client
export const createApiInstance = ({
  baseURL,
  headers,
  patch = false,
  download = false,
}) => {
  const api = axios.create({
    baseURL,
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem(
        ENV_URLS.TOKEN_IDENTIFIER
      )}`,
      Accept: 'application/json',
      'Content-Type': patch
        ? 'application/json-patch+json'
        : 'application/json',
    },
    responseType: download ? 'blob' : 'json',
  })

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { status } = error.response
        // if (status === 401) {
        //   localStorage.clear()
        //   history.replace('/401')
        // }

        switch (status) {
          case ERROR_STATUS_CODES.BAD_REQUEST:
            break
          case ERROR_STATUS_CODES.UNAUTHORIZED:
            localStorage.clear()
            history.replace('/login')
            break
          case ERROR_STATUS_CODES.FORBIDDEN:
            break
          case ERROR_STATUS_CODES.NOT_FOUND:
            break
          case ERROR_STATUS_CODES.INTERNAL_SERVER_ERROR:
            break

          default:
            break
        }
      }
      return Promise.reject(error)
    }
  )

  return api
}
