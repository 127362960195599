import { Paper, Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  color: 'gray',
  fontWeight: '100',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: '15px!important',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem!important',
    },
  },
}))

export default function DashboardCard({ amount, subtitle }) {
  return (
    <Paper elevation={6}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="140px"
        width="max-content"
        paddingLeft="5%"
      >
        <Typography variant="h1" component="h5" color='primary' fontWeight='bold'>
          {amount}
        </Typography>
        <Box display="flex" alignItems="center">
          <StyledSubtitle variant="body1" color="textSecondary">
            {subtitle}
          </StyledSubtitle>
        </Box>
      </Box>
    </Paper>
  )
}
