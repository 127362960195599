import React, { useEffect, useState } from 'react'

// jwt
import jwt_decode from 'jwt-decode'

// react router
import { useNavigate } from 'react-router-dom'
import SignupStepper from '../components/Steppers'
import { COMPANY_STATUS } from '../../../../helpers/Constants'

export default function StepperForm() {
  const navigate = useNavigate()

  const validatetoken = localStorage.getItem('sd_cp.jwt:tkn')
  const config = { headers: { Authorization: `Bearer ${validatetoken}` } }

  useEffect(() => {
    if (localStorage.getItem('sd_cp.jwt:tkn')) {
      if (validatetoken) {
        const decodedtoken = jwt_decode(validatetoken)
        proceedStepper(decodedtoken)
      } else {
        navigate('/login')
      }
    } else {
      navigate('/login')
    }
  }, [])

  const [openstepper, setopenstepper] = useState(true)

  const proceedStepper = (decodedtoken) => {
    if (
      decodedtoken.company_status == COMPANY_STATUS.Draft ||
      decodedtoken.company_status == COMPANY_STATUS.Rejected
    ) {
      localStorage.setItem('companyid', decodedtoken.company_id)
      setopenstepper(true)
    } else {
      setopenstepper(false)
    }
  }

  return (
    <>
      {/**YESH updated SignUpStepper to SignupStepperNew - Revert */}
      {openstepper ? <SignupStepper config={config} /> : null}
    </>
  )
}
