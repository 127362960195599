import * as yup from 'yup'

export const ProfileSettingsValidationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required'),
  phoneNumber: yup
    .string()
    .min(10)
    .max(10)
    .typeError('Phone number is invalid')
    .required('Phone number is required'),
})

export const ChangePasswordValidationSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Lowercase, One Number and One Special Case Character'
    )
    .test(
      'not-same-as-current',
      'New password should not same as the old password',
      function (value) {
        return value !== this.parent.currentPassword
      }
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Lowercase, One Number and One Special Case Character'
    )
    .test(
      'should-match-new-password',
      'Confirm password should match the new password',
      function (value) {
        return value == this.parent.newPassword
      }
    ),
  oldPassword: yup.string(),
})

export const CompanyProfileValidations = yup.object().shape({
  name: yup.string().required('Name is required'),
  street: yup.string().notRequired(),
  streetNumber: yup.string().required('Address line-1 is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup
    .string()
    .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits')
    .required('Zip code is required'),
  country: yup.string().required('Country is required'),
  phone: yup.number().required('Phone is required'),
})

export const ContactFormValidation = yup.object().shape({
  firstName: yup.string().required('Enter First Name'),
  lastName: yup.string().required('Enter Last Name'),
  email: yup.string().email('Invalid email.').required('Email Required'),
  phone: yup
    .string()
    .min(10, 'Phone number should be 10 digit')
    .max(10, 'Phone number should be 10 digit')
    .required('Phone number Required'),
  mobile: yup
    .string()
    .nullable()
    .min(10, 'Mobile number should be 10 digit')
    .max(10, 'Mobile number should be 10 digit'),
})

export const ContactBillingFormValidation = yup.object().shape({
  companyName: yup.string().required('Enter Company Name'),
})
