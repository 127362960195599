import {
  gridPageCountSelector,
  gridPageSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import { Box, MenuItem, Pagination, Select, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

const StyledPagination = styled(Pagination)(({ theme }) => ({
  // Add your custom styles here
  '& .MuiPaginationItem-root': {
    backgroundColor: 'rgba(255, 255, 255, 1)', //#D6E7FF', // Change this to your desired default background color
    borderRadius: '6px',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#000000', // Change this to your desired default text color
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 1)', //`${theme.palette.primary.main} !important`, // Change this to your desired color
      border: '2px solid rgba(50, 104, 185, 1)',
      color: '#000000', //`${theme.palette.primary.contrastText} !important`, // This changes the text color
      '&:hover': {
        backgroundColor: '#6B95EC', // Change this to your desired hover color
      },
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#6B95EC', //'#BBD8FF', // Change this to your desired hover background color
    },
  },
}))

export default function ThemePagination() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)
  const rowCount = useGridSelector(apiRef, gridRowCountSelector)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const start = page * rowsPerPage + 1
  const end = Math.min((page + 1) * rowsPerPage, rowCount)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    apiRef.current.setPageSize(event.target.value)
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      sx={{ px: 5 }}
    >
      <Box className="flex">
        <Typography variant="caption" fontSize={14}>
          Companies per page: &nbsp;{' '}
        </Typography>
        <Select
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          displayEmpty
          size="small"
          variant="standard"
          inputProps={{ 'aria-label': 'Companies per page' }}
        >
          {[5, 10, 20, 50, 100].map((rows) => (
            <MenuItem key={rows} value={rows}>
              {rows}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <StyledPagination
        color="primary"
        count={pageCount}
        shape="circular"
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Box>
  )
}
